const DEFINES = {
  SLACH: "/",
  PATH: "/legalstructures",
  EVENTS: "/events",
  VIEW: "/view",
};

export default class LegalStructureEventsApi {
  constructor(httpclient) {
    this.httpclient = httpclient;
  }

  /**retourne la liste des événements de la structure juridique */
  async getView(legalStructureId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
        DEFINES.SLACH +
        legalStructureId +
        DEFINES.EVENTS +
        DEFINES.VIEW
    );
    return this.httpclient.get(url);
  }

  /** Ajouter un événement à une structure juridique */
  async addEvent(legalStructureId, eventTypeId, date, docLink, description) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH + DEFINES.SLACH + legalStructureId + DEFINES.EVENTS
    );

    let payload = {
      legalStructureId,
      eventTypeId,
      date,
      docLink,
      description,
    };

    return this.httpclient.post(url, payload);
  }

  /**Supprimer un événement sur la structure juridique.*/
  async removeEvent(legalStructureId, eventId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
        DEFINES.SLACH +
        legalStructureId +
        DEFINES.EVENTS +
        DEFINES.SLACH +
        eventId
    );

    return this.httpclient.delete(url, {});
  }

  async updateEvent(
    legalStructureId,
    eventId,
    eventTypeId,
    date,
    docLink,
    description
  ) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
        DEFINES.SLACH +
        legalStructureId +
        DEFINES.EVENTS +
        DEFINES.SLACH +
        eventId
    );

    let payload = {
      id: eventId,
      legalStructureId,
      eventTypeId,
      date,
      docLink,
      description,
    };

    // console.error("payload:" + JSON.stringify(payload, null, 4));

    return this.httpclient.put(url, payload);
  }
}
