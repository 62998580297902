import DevHttpClient from "./dev_http_client.js";

/** Construction du client Http de l'api du backend */
export function initHttpClient(apiKey, tokenFunction) {
  //on instancie les clients http
  let httpclient = new DevHttpClient();
  httpclient.getToken = tokenFunction;
  httpclient.api_key = apiKey;

  return httpclient;
}

/** Instanciation des apis du backend */
export function initApi() {
  return {
    // mobilityZone: new MobilityZoneApi(httpClient),
  };
}
