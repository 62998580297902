import Vue from "vue";
import VueRouter from "vue-router";

import * as loginService from "@/service/login_service.js";
import * as loginStoreService from "@/service/login_store_service.js";
import * as roleService from "../service/role_service.js";
// import * as logger from "@/tools/logger.js";

//import * as ssoService from "@/service/sso_service.js";

/** la définition des routes (path, name, etc) */
import { defines } from "./defines.js";

Vue.use(VueRouter);

//Search Needed
// let checkIfUSerIsLogged = async function(next) {
//   // Récupération de l'état de connexion de l'utilisateur.
//   logger.debug("check if user is logged");
//   let isConnected = await loginService.isConnected();
//   logger.debug("check if user is logged", isConnected);

//   if (isConnected) {
//     next();
//   } else {
//     next(defines.connexion.path);
//   }
// };

let beforeEnterRoute = function () {
  return async function (to, from, next) {
    // Sauvegarde la route demandée pour que l'utilisateur puisse revenir dessus après relogin
    loginStoreService.setExpectedRoute(to.path);

    // Récupération de l'état de chargement de la connexion utilisateur
    let connectionLoaded = loginStoreService.getConnectionLoaded();

    // La connexion n'as pas été chargée, donc on redirige vers la page de chargement de la connexion
    if (!connectionLoaded) {
      next(defines.loadingConnection.path);
    } else {
      // Demande à l'iframe la récupération de la connexion
      await loginService.getConnexion();

      // Récupération des autorisation de la route
      let routeProps = to.matched.find((route) => route.props);

      // Toutes les routes contiennent un "props"
      if (routeProps.props.default || routeProps.props.roles) {
        let roleOk = null;

        // Vérification des droits de l'utilisateur en fonction de props qui est passé
        if (routeProps.props.default) {
          roleOk = roleService.hasRoles(routeProps.props.default.roles);
        } else if (routeProps.props.roles) {
          roleOk = roleService.hasRoles(routeProps.props.roles);
        }

        // L'utilisateur a le rôle pour ouvrir la page
        if (roleOk) {
          // lance l'analyse de connexion en mode asynchrone
          analyseConnexion(next);
          // Passe à la page suivante
          next();
        } else {
          // Pas de roles pour la page, on redirige vers la page d'erreur.
          next(defines.permission.path);
        }
        // Pas d'objet props
      } else {
        next();
      }
    }
  };
};

/**
 * Méthode d'analyse de connexion :
 * permet de savoir si l'utilisateur est connecté,
 * dans le cas contraire, switche la page en cours pour la page de login
 */
let analyseConnexion = async function (next) {
  // Attends que l'iframe ai répondu
  let connected = await loginService.waitConnection();

  // Dans le cas où l'utilisateur s'est déconnecté depuis un autre site,
  // on bascule sur la page de login.
  if (!connected) {
    next(defines.connexion.path);
  }
};

// Tableau des routes de l'application
const routes = [
  // la page par défaut
  {
    path: "*",
    name: "*",
    component: () => import("../views/home/Home.vue"),
    // beforeEnter: (to, from, next) => {
    //   checkIfUSerIsLogged(next);
    // },
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.home.path,
    name: defines.home.name,
    component: () => import("../views/home/Home.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.confAppsHome.path,
    name: defines.confAppsHome.name,
    props: { roles: defines.confAppsHome.roles },
    component: () => import("../views/confApps/confAppsHome.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.confAcs.path,
    name: defines.confAcs.name,
    props: { roles: defines.confAcs.roles },
    component: () => import("../views/acs/acsHome.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.confPractitioners.path,
    name: defines.confPractitioners.name,
    props: { roles: defines.confPractitioners.roles },
    component: () => import("../views/practitioners/practitionersHome.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.connexion.path,
    name: defines.connexion.name,
    component: () => import("../views/Login.vue"),
    beforeRouteLeave(to, from, next) {
      loginStoreService.clearExpectedRoute();
      next();
    },
  },
  {
    path: defines.loadingConnection.path,
    name: defines.loadingConnection.name,
    component: () => import("../views/LoadingConnection.vue"),
  },

  //////////////////////// CONF APPS ///////////////////////////////
  {
    path: defines.confAppViewEditDG.view.path,
    name: defines.confAppViewEditDG.view.name,
    props: { roles: defines.confAppViewEditDG.view.roles },
    component: () =>
      import("../views/confApps/confAppViewEditDG/confAppViewEditDG.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.confAppWorksContract.view.path,
    name: defines.confAppWorksContract.view.name,
    props: { roles: defines.confAppWorksContract.view.roles },
    component: () =>
      import("../views/confApps/confAppWorksContract/confAppWorksContract.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.confAvecEstablishment.view.path,
    name: defines.confAvecEstablishment.view.name,
    props: { roles: defines.confAvecEstablishment.view.roles },
    component: () => import("../views/confApps/confAvec/establishment.vue"),
    beforeEnter: beforeEnterRoute(),
  },

  {
    path: defines.confAvecSuperEstablishment.view.path,
    name: defines.confAvecSuperEstablishment.view.name,
    props: { roles: defines.confAvecSuperEstablishment.view.roles },
    component: () =>
      import("../views/confApps/confAvec/superestablishment.vue"),
    beforeEnter: beforeEnterRoute(),
  },

  {
    path: defines.confAvecSubactivity.view.path,
    name: defines.confAvecSubactivity.view.name,
    props: { roles: defines.confAvecSubactivity.view.roles },
    component: () => import("../views/confApps/confAvec/subactivity.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.confEstablishmentBusinessApp.table.path,
    name: defines.confEstablishmentBusinessApp.table.name,
    props: { roles: defines.confEstablishmentBusinessApp.table.roles },
    component: () =>
      import(
        "../views/confApps/confEstablishmentBusinessApp/confEstablishmentBusinessApp.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.confAvecIndicators.view.path,
    name: defines.confAvecIndicators.view.name,
    props: { roles: defines.confAvecIndicators.view.roles },
    component: () =>
      import("../views/confApps/confAppIndicators/confAppIndicators.vue"),
    beforeEnter: beforeEnterRoute(),
  },

  ///////////////////// CONF AVEC INDICATORS (LABEL ACTIVITES) ////////////////////////
  {
    path: defines.confAvecKeyLabelsActivities.table.path,
    name: defines.confAvecKeyLabelsActivities.table.name,
    props: { roles: defines.confAvecKeyLabelsActivities.table.roles },
    component: () =>
      import(
        "../views/confApps/confAppIndicators/TableConfAvecKeysLabelsActivities.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.confAvecKeyLabelsActivities.view.path,
    name: defines.confAvecKeyLabelsActivities.view.name,
    props: { roles: defines.confAvecKeyLabelsActivities.view.roles },
    component: () =>
      import(
        "../views/confApps/confAppIndicators/DetailConfAvecKeysLabelsActivities.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.confAvecKeyLabelsActivities.add.path,
    name: defines.confAvecKeyLabelsActivities.add.name,
    props: { roles: defines.confAvecKeyLabelsActivities.add.roles },
    component: () =>
      import(
        "../views/confApps/confAppIndicators/AddConfAvecKeysLabelsActivities.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.confAvecKeyLabelsActivities.edit.path,
    name: defines.confAvecKeyLabelsActivities.edit.name,
    props: { roles: defines.confAvecKeyLabelsActivities.edit.roles },
    component: () =>
      import(
        "../views/confApps/confAppIndicators/EditConfAvecKeysLabelsActivities.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  ///////////////////////////////////////////////////////////////////////////////////////

  {
    path: defines.permission.path,
    name: defines.permission.name,
    component: () => import("../views/_errors/permissions_error.vue"),
    beforeEnter: beforeEnterRoute(),
  },

  //////////////////////// ACTIONNAIRES ///////////////////////////////
  {
    path: defines.stockholders.table.path,
    name: defines.stockholders.table.name,
    props: { roles: defines.stockholders.table.roles },
    component: () =>
      import("../views/legalStructures/stockholders/TableStockholder.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.stockholders.view.path,
    name: defines.stockholders.view.name,
    props: { roles: defines.stockholders.view.roles },
    component: () =>
      import("../views/legalStructures/stockholders/DetailStockholder.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.stockholders.add.path,
    name: defines.stockholders.add.name,
    props: { roles: defines.stockholders.add.roles },
    component: () =>
      import("../views/legalStructures/stockholders/AddStockholder.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.stockholders.edit.path,
    name: defines.stockholders.edit.name,
    props: { roles: defines.stockholders.edit.roles },
    component: () =>
      import("../views/legalStructures/stockholders/EditStockholder.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// ACRONYMES ///////////////////////////////
  {
    path: defines.acronyms.table.path,
    name: defines.acronyms.table.name,
    props: { roles: defines.acronyms.table.roles },
    component: () => import("../views/acronyms/TableAcronyms.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.acronyms.view.path,
    name: defines.acronyms.view.name,
    props: { roles: defines.acronyms.view.roles },
    component: () => import("../views/acronyms/DetailAcronym.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.acronyms.add.path,
    name: defines.acronyms.add.name,
    props: { roles: defines.acronyms.add.roles },
    component: () => import("../views/acronyms/AddAcronym.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.acronyms.edit.path,
    name: defines.acronyms.edit.name,
    props: { roles: defines.acronyms.edit.roles },
    component: () => import("../views/acronyms/EditAcronym.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// ACTIVITES ///////////////////////////////
  {
    path: defines.activities.table.path,
    name: defines.activities.table.name,
    props: { roles: defines.activities.table.roles },
    component: () => import("../views/activities/TableActivities.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.activities.view.path,
    name: defines.activities.view.name,
    props: { roles: defines.activities.view.roles },
    component: () => import("../views/activities/DetailActivity.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.activities.add.path,
    name: defines.activities.add.name,
    props: { roles: defines.activities.add.roles },
    component: () => import("../views/activities/AddActivity.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.activities.edit.path,
    name: defines.activities.edit.name,
    props: { roles: defines.activities.edit.roles },
    component: () => import("../views/activities/EditActivity.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////// SUPER ACTIVITES ///////////////////////////
  {
    path: defines.superActivities.table.path,
    name: defines.superActivities.table.name,
    props: { roles: defines.superActivities.table.roles },
    component: () =>
      import("../views/superActivities/TableSuperActivities.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.superActivities.view.path,
    name: defines.superActivities.view.name,
    props: { roles: defines.superActivities.view.roles },
    component: () =>
      import("../views/superActivities/DetailSuperActivities.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.superActivities.add.path,
    name: defines.superActivities.add.name,
    props: { roles: defines.superActivities.add.roles },
    component: () => import("../views/superActivities/AddSuperActivities.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.superActivities.edit.path,
    name: defines.superActivities.edit.name,
    props: { roles: defines.superActivities.edit.roles },
    component: () => import("../views/superActivities/EditSuperActivities.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// CIVILITES ///////////////////////////////
  {
    path: defines.civilities.table.path,
    name: defines.civilities.table.name,
    props: { roles: defines.civilities.table.roles },
    component: () => import("../views/civilities/TableCivilities.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.civilities.view.path,
    name: defines.civilities.view.name,
    props: { roles: defines.civilities.view.roles },
    component: () => import("../views/civilities/DetailCivility.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.civilities.edit.path,
    name: defines.civilities.edit.name,
    props: { roles: defines.civilities.edit.roles },
    component: () => import("../views/civilities/EditCivility.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.civilities.add.path,
    name: defines.civilities.add.name,
    props: { roles: defines.civilities.add.roles },
    component: () => import("../views/civilities/AddCivility.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// CONTACTS CONTRAT EXTERNE ///////////////////////////////
  {
    path: defines.externalContractContact.table.path,
    name: defines.externalContractContact.table.name,
    props: { roles: defines.externalContractContact.table.roles },
    component: () =>
      import(
        "../views/externalContractContact/TableExternalContractContacts.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.externalContractContact.view.path,
    name: defines.externalContractContact.view.name,
    props: { roles: defines.externalContractContact.view.roles },
    component: () =>
      import(
        "../views/externalContractContact/DetailsExternalContractContact.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.externalContractContact.edit.path,
    name: defines.externalContractContact.edit.name,
    props: { roles: defines.externalContractContact.edit.roles },
    component: () =>
      import(
        "../views/externalContractContact/EditExternalContractContact.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.externalContractContact.add.path,
    name: defines.externalContractContact.add.name,
    props: { roles: defines.externalContractContact.add.roles },
    component: () =>
      import("../views/externalContractContact/AddExternalContractContact.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// CONTEXTE NOTES DE FRAIS ///////////////////////////////
  {
    path: defines.expenseReportContext.table.path,
    name: defines.expenseReportContext.table.name,
    props: { roles: defines.expenseReportContext.table.roles },
    component: () =>
      import("../views/expensereportcontext/TableExpenseReportContext.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.expenseReportContext.view.path,
    name: defines.expenseReportContext.view.name,
    props: { roles: defines.expenseReportContext.view.roles },
    component: () =>
      import("../views/expensereportcontext/DetailExpenseReportContext.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.expenseReportContext.edit.path,
    name: defines.expenseReportContext.edit.name,
    props: { roles: defines.expenseReportContext.edit.roles },
    component: () =>
      import("../views/expensereportcontext/EditExpenseReportContext.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.expenseReportContext.add.path,
    name: defines.expenseReportContext.add.name,
    props: { roles: defines.expenseReportContext.add.roles },
    component: () =>
      import("../views/expensereportcontext/AddExpenseReportContext.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// CONVENTION COLLECTIVE ///////////////////////////////
  {
    path: defines.collectiveAgreement.table.path,
    name: defines.collectiveAgreement.table.name,
    props: { roles: defines.collectiveAgreement.table.roles },
    component: () =>
      import("../views/collectiveAgreement/TableCollectiveAgreements.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.collectiveAgreement.view.path,
    name: defines.collectiveAgreement.view.name,
    props: { roles: defines.collectiveAgreement.view.roles },
    component: () =>
      import("../views/collectiveAgreement/DetailCollectiveAgreement.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.collectiveAgreement.edit.path,
    name: defines.collectiveAgreement.edit.name,
    props: { roles: defines.collectiveAgreement.edit.roles },
    component: () =>
      import("../views/collectiveAgreement/EditCollectiveAgreement.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.collectiveAgreement.add.path,
    name: defines.collectiveAgreement.add.name,
    props: { roles: defines.collectiveAgreement.add.roles },
    component: () =>
      import("../views/collectiveAgreement/AddCollectiveAgreement.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// ETABLISSEMENTS ///////////////////////////////
  {
    path: defines.etablishments.edit.path,
    name: defines.etablishments.edit.name,
    props: { roles: defines.etablishments.edit.roles },
    component: () =>
      import(
        "../views/establishments/establishmentsDirection/EditEstablishment.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.etablishments.add.path,
    name: defines.etablishments.add.name,
    props: { roles: defines.etablishments.add.roles },
    component: () =>
      import(
        "../views/establishments/establishmentsDirection/AddEstablishment.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.etablishments.table.path,
    name: defines.etablishments.table.name,
    props: { roles: defines.etablishments.table.roles },
    component: () =>
      import(
        "../views/establishments/establishmentsDirection/TableEstablishments.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.etablishments.view.path,
    name: defines.etablishments.view.name,
    props: { roles: defines.etablishments.view.roles },
    component: () =>
      import(
        "../views/establishments/establishmentsDirection/DetailEstablishment.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// ETABLISSEMENTS.STATUS ///////////////////////////////
  {
    path: defines.establishmentsStatus.edit.path,
    name: defines.establishmentsStatus.edit.name,
    props: { roles: defines.establishmentsStatus.edit.roles },
    component: () =>
      import(
        "../views/establishments/establishmentsStatus/EditEstablishmentStatus.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.establishmentsStatus.add.path,
    name: defines.establishmentsStatus.add.name,
    props: { roles: defines.establishmentsStatus.add.roles },
    component: () =>
      import(
        "../views/establishments/establishmentsStatus/AddEstablishmentStatus.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.establishmentsStatus.table.path,
    name: defines.establishmentsStatus.table.name,
    props: { roles: defines.establishmentsStatus.table.roles },
    component: () =>
      import(
        "../views/establishments/establishmentsStatus/TableEstablishmentStatus.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.establishmentsStatus.view.path,
    name: defines.establishmentsStatus.view.name,
    props: { roles: defines.establishmentsStatus.view.roles },
    component: () =>
      import(
        "../views/establishments/establishmentsStatus/DetailEstablishmentStatus.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// ETABLISSEMENTS.JURIS ///////////////////////////////
  {
    path: defines.establishmentsJuris.edit.path,
    name: defines.establishmentsJuris.edit.name,
    props: { roles: defines.establishmentsJuris.edit.roles },
    component: () =>
      import(
        "../views/establishments/establishmentsJuris/EditEstablishmentJuris.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.establishmentsJuris.add.path,
    name: defines.establishmentsJuris.add.name,
    props: { roles: defines.establishmentsJuris.add.roles },
    component: () =>
      import(
        "../views/establishments/establishmentsJuris/AddEstablishmentJuris.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.establishmentsJuris.table.path,
    name: defines.establishmentsJuris.table.name,
    props: { roles: defines.establishmentsJuris.table.roles },
    component: () =>
      import(
        "../views/establishments/establishmentsJuris/TableEstablishmentJuris.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.establishmentsJuris.view.path,
    name: defines.establishmentsJuris.view.name,
    props: { roles: defines.establishmentsJuris.view.roles },
    component: () =>
      import(
        "../views/establishments/establishmentsJuris/DetailEstablishmentJuris.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// SUPER ETABLISSEMENTS ///////////////////////////////
  {
    path: defines.superEstablishments.edit.path,
    name: defines.superEstablishments.edit.name,
    props: { roles: defines.superEstablishments.edit.roles },
    component: () =>
      import(
        "../views/establishments/superEstablishment/EditSuperEstablishment.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.superEstablishments.add.path,
    name: defines.superEstablishments.add.name,
    props: { roles: defines.superEstablishments.add.roles },
    component: () =>
      import(
        "../views/establishments/superEstablishment/AddSuperEstablishment.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.superEstablishments.table.path,
    name: defines.superEstablishments.table.name,
    props: { roles: defines.superEstablishments.table.roles },
    component: () =>
      import(
        "../views/establishments/superEstablishment/TableSuperEstablishment.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.superEstablishments.view.path,
    name: defines.superEstablishments.view.name,
    props: { roles: defines.superEstablishments.view.roles },
    component: () =>
      import(
        "../views/establishments/superEstablishment/DetailSuperEstablishment.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },

  //////////////////////// FONCTIONS ///////////////////////////////
  {
    path: defines.functions.add.path,
    name: defines.functions.add.name,
    props: { roles: defines.functions.add.roles },
    component: () => import("../views/functions/AddFunction.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.functions.edit.path,
    name: defines.functions.edit.name,
    props: { roles: defines.functions.edit.roles },
    component: () => import("../views/functions/EditFunction.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.functions.view.path,
    name: defines.functions.view.name,
    props: { roles: defines.functions.view.roles },
    component: () => import("../views/functions/DetailFunction.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.functions.table.path,
    name: defines.functions.table.name,
    props: { roles: defines.functions.table.roles },
    component: () => import("../views/functions/TableFunctions.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// METIERS ///////////////////////////////
  {
    path: defines.metiers.table.path,
    name: defines.metiers.table.name,
    props: { roles: defines.metiers.table.roles },
    component: () => import("../views/metiers/TableMetiers.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.metiers.view.path,
    name: defines.metiers.view.name,
    props: { roles: defines.metiers.view.roles },
    component: () => import("../views/metiers/DetailMetier.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.metiers.edit.path,
    name: defines.metiers.edit.name,
    props: { roles: defines.metiers.edit.roles },
    component: () => import("../views/metiers/EditMetier.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.metiers.add.path,
    name: defines.metiers.add.name,
    props: { roles: defines.metiers.add.roles },
    component: () => import("../views/metiers/AddMetier.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// MOTIFS CDD ///////////////////////////////
  {
    path: defines.cddreasons.table.path,
    name: defines.cddreasons.table.name,
    props: { roles: defines.cddreasons.table.roles },
    component: () => import("../views/cddreasons/TableCddReasons.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.cddreasons.view.path,
    name: defines.cddreasons.view.name,
    props: { roles: defines.cddreasons.view.roles },
    component: () => import("../views/cddreasons/DetailCddReason.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.cddreasons.add.path,
    name: defines.cddreasons.add.name,
    props: { roles: defines.cddreasons.add.roles },
    component: () => import("../views/cddreasons/AddCddReason.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.cddreasons.edit.path,
    name: defines.cddreasons.edit.name,
    props: { roles: defines.cddreasons.edit.roles },
    component: () => import("../views/cddreasons/EditCddReason.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// MOTIFS CONGES ///////////////////////////////
  {
    path: defines.vacationreasons.table.path,
    name: defines.vacationreasons.table.name,
    props: { roles: defines.vacationreasons.table.roles },
    component: () =>
      import("../views/vacationreasons/TableVacationReasons.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.vacationreasons.view.path,
    name: defines.vacationreasons.view.name,
    props: { roles: defines.vacationreasons.view.roles },
    component: () =>
      import("../views/vacationreasons/DetailVacationReason.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.vacationreasons.add.path,
    name: defines.vacationreasons.add.name,
    props: { roles: defines.vacationreasons.add.roles },
    component: () => import("../views/vacationreasons/AddVacationReason.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.vacationreasons.edit.path,
    name: defines.vacationreasons.edit.name,
    props: { roles: defines.vacationreasons.edit.roles },
    component: () => import("../views/vacationreasons/EditVacationReason.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// MOTIFS MALADIE ///////////////////////////////
  {
    path: defines.diseasesReason.view.path,
    name: defines.diseasesReason.view.name,
    props: { roles: defines.diseasesReason.view.roles },
    component: () => import("../views/diseasesReason/DetailsDiseaseReason.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.diseasesReason.table.path,
    name: defines.diseasesReason.table.name,
    props: { roles: defines.diseasesReason.table.roles },
    component: () => import("../views/diseasesReason/TableDiseasesReason.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.diseasesReason.add.path,
    name: defines.diseasesReason.add.name,
    props: { roles: defines.diseasesReason.add.roles },
    component: () => import("../views/diseasesReason/AddDiseaseReason.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.diseasesReason.edit.path,
    name: defines.diseasesReason.edit.name,
    props: { roles: defines.diseasesReason.edit.roles },
    component: () => import("../views/diseasesReason/EditDiseaseReason.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// MOTIFS TEMPS DE TRAVAIL<24H ///////////////////////////////
  {
    path: defines.worksDurationReason.view.path,
    name: defines.worksDurationReason.view.name,
    props: { roles: defines.worksDurationReason.view.roles },
    component: () =>
      import("../views/workDurationReason/DetailWorkDurationReason.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.worksDurationReason.table.path,
    name: defines.worksDurationReason.table.name,
    props: { roles: defines.worksDurationReason.table.roles },
    component: () =>
      import("../views/workDurationReason/TableWorkDurationReason.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.worksDurationReason.add.path,
    name: defines.worksDurationReason.add.name,
    props: { roles: defines.worksDurationReason.add.roles },
    component: () =>
      import("../views/workDurationReason/AddWorkDurationReason.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.worksDurationReason.edit.path,
    name: defines.worksDurationReason.edit.name,
    props: { roles: defines.worksDurationReason.edit.roles },
    component: () =>
      import("../views/workDurationReason/EditWorkDurationReason.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// NATURE REMBOURSEMENTS ///////////////////////////////
  {
    path: defines.refundTypes.view.path,
    name: defines.refundTypes.view.name,
    props: { roles: defines.refundTypes.view.roles },
    component: () => import("../views/refundTypes/DetailsRefundType.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.refundTypes.table.path,
    name: defines.refundTypes.table.name,
    props: { roles: defines.refundTypes.table.roles },
    component: () => import("../views/refundTypes/TableRefundTypes.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.refundTypes.add.path,
    name: defines.refundTypes.add.name,
    props: { roles: defines.refundTypes.add.roles },
    component: () => import("../views/refundTypes/AddRefundType.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.refundTypes.edit.path,
    name: defines.refundTypes.edit.name,
    props: { roles: defines.refundTypes.edit.roles },
    component: () => import("../views/refundTypes/EditRefundType.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// ORGANISATION DU TEMPS DE TRAVAIL ///////////////////////////////
  {
    path: defines.workingTimeOrganization.view.path,
    name: defines.workingTimeOrganization.view.name,
    props: { roles: defines.workingTimeOrganization.view.roles },
    component: () =>
      import(
        "../views/workingTimeOrganization/DetailsWorkingTimeOrganisation.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.workingTimeOrganization.table.path,
    name: defines.workingTimeOrganization.table.name,
    props: { roles: defines.workingTimeOrganization.table.roles },
    component: () =>
      import(
        "../views/workingTimeOrganization/TableWorkingTimeOrganization.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.workingTimeOrganization.add.path,
    name: defines.workingTimeOrganization.add.name,
    props: { roles: defines.workingTimeOrganization.add.roles },
    component: () =>
      import("../views/workingTimeOrganization/AddWorkingTimeOrganisation.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.workingTimeOrganization.edit.path,
    name: defines.workingTimeOrganization.edit.name,
    props: { roles: defines.workingTimeOrganization.edit.roles },
    component: () =>
      import(
        "../views/workingTimeOrganization/EditWorkingTimeOrganisation.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// ORIGINES PROSPECT ///////////////////////////////
  {
    path: defines.prospectOrigins.view.path,
    name: defines.prospectOrigins.view.name,
    props: { roles: defines.prospectOrigins.view.roles },
    component: () =>
      import("../views/prospectOrigin/DetailsProspectOrigin.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.prospectOrigins.table.path,
    name: defines.prospectOrigins.table.name,
    props: { roles: defines.prospectOrigins.table.roles },
    component: () => import("../views/prospectOrigin/TableProspectOrigins.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.prospectOrigins.add.path,
    name: defines.prospectOrigins.add.name,
    props: { roles: defines.prospectOrigins.add.roles },
    component: () => import("../views/prospectOrigin/AddProspectOrigin.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.prospectOrigins.edit.path,
    name: defines.prospectOrigins.edit.name,
    props: { roles: defines.prospectOrigins.edit.roles },
    component: () => import("../views/prospectOrigin/EditProspectOrigin.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// RUBRIQUES ///////////////////////////////
  {
    path: defines.rubrics.view.path,
    name: defines.rubrics.view.name,
    props: { roles: defines.rubrics.view.roles },
    component: () => import("../views/rubrics/DetailsRubric.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.rubrics.table.path,
    name: defines.rubrics.table.name,
    props: { roles: defines.rubrics.table.roles },
    component: () => import("../views/rubrics/TableRubrics.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.rubrics.add.path,
    name: defines.rubrics.add.name,
    props: { roles: defines.rubrics.add.roles },
    component: () => import("../views/rubrics/AddRubric.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.rubrics.edit.path,
    name: defines.rubrics.edit.name,
    props: { roles: defines.rubrics.edit.roles },
    component: () => import("../views/rubrics/EditRubric.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// RUBRIQUES GLOBALES ///////////////////////////////
  {
    path: defines.globalRubrics.view.path,
    name: defines.globalRubrics.view.name,
    props: { roles: defines.globalRubrics.view.roles },
    component: () => import("../views/globalRubrics/DetailsGlobalRubric.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.globalRubrics.table.path,
    name: defines.globalRubrics.table.name,
    props: { roles: defines.globalRubrics.table.roles },
    component: () => import("../views/globalRubrics/TableGlobalRubrics.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.globalRubrics.add.path,
    name: defines.globalRubrics.add.name,
    props: { roles: defines.globalRubrics.add.roles },
    component: () => import("../views/globalRubrics/AddGlobalRubric.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.globalRubrics.edit.path,
    name: defines.globalRubrics.edit.name,
    props: { roles: defines.globalRubrics.edit.roles },
    component: () => import("../views/globalRubrics/EditGlobalRubric.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// SERVICES ///////////////////////////////
  {
    path: defines.services.table.path,
    name: defines.services.table.name,
    props: { roles: defines.services.table.roles },
    component: () => import("../views/services/TableServices.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.services.view.path,
    name: defines.services.view.name,
    props: { roles: defines.services.view.roles },
    component: () => import("../views/services/DetailService.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.services.add.path,
    name: defines.services.add.name,
    props: { roles: defines.services.add.roles },
    component: () => import("../views/services/AddService.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.services.edit.path,
    name: defines.services.edit.name,
    props: { roles: defines.services.edit.roles },
    component: () => import("../views/services/EditService.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// SEXES ///////////////////////////////
  {
    path: defines.genders.table.path,
    name: defines.genders.table.name,
    props: { roles: defines.genders.table.roles },
    component: () => import("../views/genders/TableGenders.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.genders.view.path,
    name: defines.genders.view.name,
    props: { roles: defines.genders.view.roles },
    component: () => import("../views/genders/DetailsGender.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.genders.add.path,
    name: defines.genders.add.name,
    props: { roles: defines.genders.add.roles },
    component: () => import("../views/genders/AddGender.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.genders.edit.path,
    name: defines.genders.edit.name,
    props: { roles: defines.genders.edit.roles },
    component: () => import("../views/genders/EditGender.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// SOUS ACTIVITEES ///////////////////////////////
  {
    path: defines.subActivities.table.path,
    name: defines.subActivities.table.name,
    props: { roles: defines.subActivities.table.roles },
    component: () => import("../views/subActivities/TableSubActivities.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.subActivities.view.path,
    name: defines.subActivities.view.name,
    props: { roles: defines.subActivities.view.roles },
    component: () => import("../views/subActivities/DetailsSubActivity.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.subActivities.add.path,
    name: defines.subActivities.add.name,
    props: { roles: defines.subActivities.add.roles },
    component: () => import("../views/subActivities/AddSubActivity.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.subActivities.edit.path,
    name: defines.subActivities.edit.name,
    props: { roles: defines.subActivities.edit.roles },
    component: () => import("../views/subActivities/EditSubActivity.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// STRUCTURES JURIDIQUE ///////////////////////////////
  {
    path: defines.legalStructure.table.path,
    name: defines.legalStructure.table.name,
    props: { roles: defines.legalStructure.table.roles },
    component: () =>
      import(
        "../views/legalStructures/legalStructures/TableLegalStructure.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.legalStructure.view.path,
    name: defines.legalStructure.view.name,
    props: { roles: defines.legalStructure.view.roles },
    component: () =>
      import(
        "../views/legalStructures/legalStructures/DetailLegalStructure.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.legalStructure.add.path,
    name: defines.legalStructure.add.name,
    props: { roles: defines.legalStructure.add.roles },
    component: () =>
      import("../views/legalStructures/legalStructures/AddLegalStructure.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.legalStructure.edit.path,
    name: defines.legalStructure.edit.name,
    props: { roles: defines.legalStructure.edit.roles },
    component: () =>
      import("../views/legalStructures/legalStructures/EditLegalStructure.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// STRUCTURES JURIDIQUE NATURE (TYPE) ///////////////////////////////
  {
    path: defines.legalStructureTypes.table.path,
    name: defines.legalStructureTypes.table.name,
    props: { roles: defines.legalStructureTypes.table.roles },
    component: () =>
      import("../views/legalStructureTypes/TableLegalStructureTypes.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.legalStructureTypes.view.path,
    name: defines.legalStructureTypes.view.name,
    props: { roles: defines.legalStructureTypes.view.roles },
    component: () =>
      import("../views/legalStructureTypes/DetailsLegalStructureType.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.legalStructureTypes.add.path,
    name: defines.legalStructureTypes.add.name,
    props: { roles: defines.legalStructureTypes.add.roles },
    component: () =>
      import("../views/legalStructureTypes/AddLegalStructureType.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.legalStructureTypes.edit.path,
    name: defines.legalStructureTypes.edit.name,
    props: { roles: defines.legalStructureTypes.edit.roles },
    component: () =>
      import("../views/legalStructureTypes/EditLegalStructureType.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// STRUCTURES JURIDIQUE (STATUS) ///////////////////////////////
  {
    path: defines.legalStructureStatus.table.path,
    name: defines.legalStructureStatus.table.name,
    props: { roles: defines.legalStructureStatus.table.roles },
    component: () =>
      import(
        "../views/legalStructures/legalStructureStatus/TableLegalStructureStatus.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.legalStructureStatus.view.path,
    name: defines.legalStructureStatus.view.name,
    props: { roles: defines.legalStructureStatus.view.roles },
    component: () =>
      import(
        "../views/legalStructures/legalStructureStatus/DetailLegalStructureStatus.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.legalStructureStatus.add.path,
    name: defines.legalStructureStatus.add.name,
    props: { roles: defines.legalStructureStatus.add.roles },
    component: () =>
      import(
        "../views/legalStructures/legalStructureStatus/AddLegalStructureStatus.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.legalStructureStatus.edit.path,
    name: defines.legalStructureStatus.edit.name,
    props: { roles: defines.legalStructureStatus.edit.roles },
    component: () =>
      import(
        "../views/legalStructures/legalStructureStatus/EditLegalStructureStatus.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// STRUCTURES JURIDIQUE (REPRESÉNTANT LÉGAL) ///////////////////////////////
  {
    path: defines.legalRepresentativeStatus.table.path,
    name: defines.legalRepresentativeStatus.table.name,
    props: { roles: defines.legalRepresentativeStatus.table.roles },
    component: () =>
      import(
        "../views/legalStructures/legalRepresentativeStatus/TableLegalRepresentativeStatus.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.legalRepresentativeStatus.view.path,
    name: defines.legalRepresentativeStatus.view.name,
    props: { roles: defines.legalRepresentativeStatus.view.roles },
    component: () =>
      import(
        "../views/legalStructures/legalRepresentativeStatus/DetailLegalRepresentativeStatus.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.legalRepresentativeStatus.add.path,
    name: defines.legalRepresentativeStatus.add.name,
    props: { roles: defines.legalRepresentativeStatus.add.roles },
    component: () =>
      import(
        "../views/legalStructures/legalRepresentativeStatus/AddLegalRepresentativeStatus.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.legalRepresentativeStatus.edit.path,
    name: defines.legalRepresentativeStatus.edit.name,
    props: { roles: defines.legalRepresentativeStatus.edit.roles },
    component: () =>
      import(
        "../views/legalStructures/legalRepresentativeStatus/EditLegalRepresentativeStatus.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// STRUCTURES JURIDIQUE (TYPES EVENEMENT) ///////////////////////////////
  {
    path: defines.legalStructureEventTypes.table.path,
    name: defines.legalStructureEventTypes.table.name,
    props: { roles: defines.legalStructureEventTypes.table.roles },
    component: () =>
      import(
        "../views/legalStructures/legalStructureEventTypes/TableLegalStructureEventType.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.legalStructureEventTypes.view.path,
    name: defines.legalStructureEventTypes.view.name,
    props: { roles: defines.legalStructureEventTypes.view.roles },
    component: () =>
      import(
        "../views/legalStructures/legalStructureEventTypes/DetailLegalStructureEventType.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.legalStructureEventTypes.add.path,
    name: defines.legalStructureEventTypes.add.name,
    props: { roles: defines.legalStructureEventTypes.add.roles },
    component: () =>
      import(
        "../views/legalStructures/legalStructureEventTypes/AddLegalStructureEventType.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.legalStructureEventTypes.edit.path,
    name: defines.legalStructureEventTypes.edit.name,
    props: { roles: defines.legalStructureEventTypes.edit.roles },
    component: () =>
      import(
        "../views/legalStructures/legalStructureEventTypes/EditLegalStructureEventType.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// SUPER STRUCTURES JURIDIQUE  ///////////////////////////////
  {
    path: defines.superLegalStructure.table.path,
    name: defines.superLegalStructure.table.name,
    props: { roles: defines.superLegalStructure.table.roles },
    component: () =>
      import(
        "../views/legalStructures/superLegalStructure/TableSuperLegalStructure.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.superLegalStructure.view.path,
    name: defines.superLegalStructure.view.name,
    props: { roles: defines.superLegalStructure.view.roles },
    component: () =>
      import(
        "../views/legalStructures/superLegalStructure/DetailSuperLegalStructure.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.superLegalStructure.add.path,
    name: defines.superLegalStructure.add.name,
    props: { roles: defines.superLegalStructure.add.roles },
    component: () =>
      import(
        "../views/legalStructures/superLegalStructure/AddSuperLegalStructure.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.superLegalStructure.edit.path,
    name: defines.superLegalStructure.edit.name,
    props: { roles: defines.superLegalStructure.edit.roles },
    component: () =>
      import(
        "../views/legalStructures/superLegalStructure/EditSuperLegalStructure.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// TEMPS DE TRAVAIL (Contrat) ///////////////////////////////
  {
    path: defines.worksDuration.table.path,
    name: defines.worksDuration.table.name,
    props: { roles: defines.worksDuration.table.roles },
    component: () => import("../views/worksDuration/TableWorksDuration.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.worksDuration.view.path,
    name: defines.worksDuration.view.name,
    props: { roles: defines.worksDuration.view.roles },
    component: () => import("../views/worksDuration/DetailsWorkDuration.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.worksDuration.add.path,
    name: defines.worksDuration.add.name,
    props: { roles: defines.worksDuration.add.roles },
    component: () => import("../views/worksDuration/AddWorkDuration.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.worksDuration.edit.path,
    name: defines.worksDuration.edit.name,
    props: { roles: defines.worksDuration.edit.roles },
    component: () => import("../views/worksDuration/EditWorkDuration.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// TITRES EMPLOYEES ///////////////////////////////
  {
    path: defines.employeetitles.table.path,
    name: defines.employeetitles.table.name,
    props: { roles: defines.employeetitles.table.roles },
    component: () => import("../views/employeetitles/TableEmployeeTitles.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.employeetitles.view.path,
    name: defines.employeetitles.view.name,
    props: { roles: defines.employeetitles.view.roles },
    component: () => import("../views/employeetitles/DetailEmployeeTitle.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.employeetitles.add.path,
    name: defines.employeetitles.add.name,
    props: { roles: defines.employeetitles.add.roles },
    component: () => import("../views/employeetitles/AddEmployeeTitle.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.employeetitles.edit.path,
    name: defines.employeetitles.edit.name,
    props: { roles: defines.employeetitles.edit.roles },
    component: () => import("../views/employeetitles/EditEmployeeTitle.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// TYPES CONTRAT EXTERNE ///////////////////////////////
  {
    path: defines.externalContractTypes.table.path,
    name: defines.externalContractTypes.table.name,
    props: { roles: defines.externalContractTypes.table.roles },
    component: () =>
      import("../views/externalContractTypes/TableExternalContractTypes.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.externalContractTypes.view.path,
    name: defines.externalContractTypes.view.name,
    props: { roles: defines.externalContractTypes.view.roles },
    component: () =>
      import("../views/externalContractTypes/DetailExternalContractType.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.externalContractTypes.add.path,
    name: defines.externalContractTypes.add.name,
    props: { roles: defines.externalContractTypes.add.roles },
    component: () =>
      import("../views/externalContractTypes/AddExternalContractType.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.externalContractTypes.edit.path,
    name: defines.externalContractTypes.edit.name,
    props: { roles: defines.externalContractTypes.edit.roles },
    component: () =>
      import("../views/externalContractTypes/EditExternalContractType.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// TYPES UTILISATEURS ///////////////////////////////
  {
    path: defines.usertypes.table.path,
    name: defines.usertypes.table.name,
    props: { roles: defines.usertypes.table.roles },
    component: () => import("../views/usertypes/TableUserTypes.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.usertypes.view.path,
    name: defines.usertypes.view.name,
    props: { roles: defines.usertypes.view.roles },
    component: () => import("../views/usertypes/DetailUserType.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.usertypes.add.path,
    name: defines.usertypes.add.name,
    props: { roles: defines.usertypes.add.roles },
    component: () => import("../views/usertypes/AddUserType.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.usertypes.edit.path,
    name: defines.usertypes.edit.name,
    props: { roles: defines.usertypes.edit.roles },
    component: () => import("../views/usertypes/EditUserType.vue"),
    beforeEnter: beforeEnterRoute(),
  },

  //////////////////////// DOAMINES / MARQUES ///////////////////////////////
  {
    path: defines.domainBrands.table.path,
    name: defines.domainBrands.table.name,
    props: { roles: defines.domainBrands.table.roles },
    component: () => import("../views/domainBrands/TableDomainBrand.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.domainBrands.view.path,
    name: defines.domainBrands.view.name,
    props: { roles: defines.domainBrands.view.roles },
    component: () => import("../views/domainBrands/DetailDomainBrand.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.domainBrands.add.path,
    name: defines.domainBrands.add.name,
    props: { roles: defines.domainBrands.add.roles },
    component: () => import("../views/domainBrands/AddDomainBrand.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.domainBrands.edit.path,
    name: defines.domainBrands.edit.name,
    props: { roles: defines.domainBrands.edit.roles },
    component: () => import("../views/domainBrands/EditDomainBrand.vue"),
    beforeEnter: beforeEnterRoute(),
  },

  //////////////////////// SPECIALITES MEDICALES ///////////////////////////////
  {
    path: defines.medicalSpecialties.table.path,
    name: defines.medicalSpecialties.table.name,
    props: { roles: defines.medicalSpecialties.table.roles },
    component: () =>
      import("../views/medicalSpecialties/TableMedicalSpecialties.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.medicalSpecialties.view.path,
    name: defines.medicalSpecialties.view.name,
    props: { roles: defines.medicalSpecialties.view.roles },
    component: () =>
      import("../views/medicalSpecialties/DetailMedicalSpecialty.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.medicalSpecialties.add.path,
    name: defines.medicalSpecialties.add.name,
    props: { roles: defines.medicalSpecialties.add.roles },
    component: () =>
      import("../views/medicalSpecialties/AddMedicalSpecialty.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.medicalSpecialties.edit.path,
    name: defines.medicalSpecialties.edit.name,
    props: { roles: defines.medicalSpecialties.edit.roles },
    component: () =>
      import("../views/medicalSpecialties/EditMedicalSpecialty.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  ////////////////// TICKETING NIVEAUX D'URGENCE /////////////////////////
  {
    path: defines.emergencyLevelTicketing.table.path,
    name: defines.emergencyLevelTicketing.table.name,
    props: { roles: defines.emergencyLevelTicketing.table.roles },
    component: () =>
      import(
        "../views/emergencyLevelTicketing/TableEmergencyLevelTicketing.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.emergencyLevelTicketing.view.path,
    name: defines.emergencyLevelTicketing.view.name,
    props: { roles: defines.emergencyLevelTicketing.view.roles },
    component: () =>
      import(
        "../views/emergencyLevelTicketing/DetailEmergencyLevelTicketing.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.emergencyLevelTicketing.add.path,
    name: defines.emergencyLevelTicketing.add.name,
    props: { roles: defines.emergencyLevelTicketing.add.roles },
    component: () =>
      import("../views/emergencyLevelTicketing/AddEmergencyLevelTicketing.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.emergencyLevelTicketing.edit.path,
    name: defines.emergencyLevelTicketing.edit.name,
    props: { roles: defines.emergencyLevelTicketing.edit.roles },
    component: () =>
      import(
        "../views/emergencyLevelTicketing/EditEmergencyLevelTicketing.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  ////////////////// TRANSMISSIONS NIVEAUX D'URGENCE /////////////////////////
  {
    path: defines.emergencyLevelTransmission.table.path,
    name: defines.emergencyLevelTransmission.table.name,
    props: { roles: defines.emergencyLevelTransmission.table.roles },
    component: () =>
      import(
        "../views/emergencyLevelTransmission/TableEmergencyLevelTransmission.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.emergencyLevelTransmission.view.path,
    name: defines.emergencyLevelTransmission.view.name,
    props: { roles: defines.emergencyLevelTransmission.view.roles },
    component: () =>
      import(
        "../views/emergencyLevelTransmission/DetailEmergencyLevelTransmission.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.emergencyLevelTransmission.add.path,
    name: defines.emergencyLevelTransmission.add.name,
    props: { roles: defines.emergencyLevelTransmission.add.roles },
    component: () =>
      import(
        "../views/emergencyLevelTransmission/AddEmergencyLevelTransmission.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.emergencyLevelTransmission.edit.path,
    name: defines.emergencyLevelTransmission.edit.name,
    props: { roles: defines.emergencyLevelTransmission.edit.roles },
    component: () =>
      import(
        "../views/emergencyLevelTransmission/EditEmergencyLevelTransmission.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  /////////////////////// TICKETING PERIMETRE ///////////////////////////////
  {
    path: defines.ticketingPerimeter.table.path,
    name: defines.ticketingPerimeter.table.name,
    props: { roles: defines.ticketingPerimeter.table.roles },
    component: () =>
      import("../views/ticketing/perimeter/TableTicketingPerimeter.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.ticketingPerimeter.view.path,
    name: defines.ticketingPerimeter.view.name,
    props: { roles: defines.ticketingPerimeter.view.roles },
    component: () =>
      import("../views/ticketing/perimeter/DetailTicketingPerimeter.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.ticketingPerimeter.add.path,
    name: defines.ticketingPerimeter.add.name,
    props: { roles: defines.ticketingPerimeter.add.roles },
    component: () =>
      import("../views/ticketing/perimeter/AddTicketingPerimeter.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.ticketingPerimeter.edit.path,
    name: defines.ticketingPerimeter.edit.name,
    props: { roles: defines.ticketingPerimeter.edit.roles },
    component: () =>
      import("../views/ticketing/perimeter/EditTicketingPerimeter.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////// TICKETING DESTINATAIRES (RECIPIENT TYPE) ///////////////////////
  {
    path: defines.ticketingRecipientType.table.path,
    name: defines.ticketingRecipientType.table.name,
    props: { roles: defines.ticketingRecipientType.table.roles },
    component: () =>
      import(
        "../views/ticketing/recipientType/TableTicketingRecipientType.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.ticketingRecipientType.view.path,
    name: defines.ticketingRecipientType.view.name,
    props: { roles: defines.ticketingRecipientType.view.roles },
    component: () =>
      import(
        "../views/ticketing/recipientType/DetailTicketingRecipientType.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.ticketingRecipientType.add.path,
    name: defines.ticketingRecipientType.add.name,
    props: { roles: defines.ticketingRecipientType.add.roles },
    component: () =>
      import("../views/ticketing/recipientType/AddTicketingRecipientType.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.ticketingRecipientType.edit.path,
    name: defines.ticketingRecipientType.edit.name,
    props: { roles: defines.ticketingRecipientType.edit.roles },
    component: () =>
      import("../views/ticketing/recipientType/EditTicketingRecipientType.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  ///////////////////////// TICKETING STATUS ///////////////////////////
  {
    path: defines.ticketingStatus.table.path,
    name: defines.ticketingStatus.table.name,
    props: { roles: defines.ticketingStatus.table.roles },
    component: () =>
      import("../views/ticketing/status/TableTicketingStatus.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.ticketingStatus.view.path,
    name: defines.ticketingStatus.view.name,
    props: { roles: defines.ticketingStatus.view.roles },
    component: () =>
      import("../views/ticketing/status/DetailTicketingStatus.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.ticketingStatus.add.path,
    name: defines.ticketingStatus.add.name,
    props: { roles: defines.ticketingStatus.add.roles },
    component: () => import("../views/ticketing/status/AddTicketingStatus.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.ticketingStatus.edit.path,
    name: defines.ticketingStatus.edit.name,
    props: { roles: defines.ticketingStatus.edit.roles },
    component: () =>
      import("../views/ticketing/status/EditTicketingStatus.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  ////////////////// TICKETING STATUS SPECIFIQUES ///////////////////////
  {
    path: defines.ticketingSpecificStatus.table.path,
    name: defines.ticketingSpecificStatus.table.name,
    props: { roles: defines.ticketingSpecificStatus.table.roles },
    component: () =>
      import(
        "../views/ticketing/specificStatus/TableTicketingSpecificStatus.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.ticketingSpecificStatus.view.path,
    name: defines.ticketingSpecificStatus.view.name,
    props: { roles: defines.ticketingSpecificStatus.view.roles },
    component: () =>
      import(
        "../views/ticketing/specificStatus/DetailTicketingSpecificStatus.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.ticketingSpecificStatus.add.path,
    name: defines.ticketingSpecificStatus.add.name,
    props: { roles: defines.ticketingSpecificStatus.add.roles },
    component: () =>
      import(
        "../views/ticketing/specificStatus/AddTicketingSpecificStatus.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.ticketingSpecificStatus.edit.path,
    name: defines.ticketingSpecificStatus.edit.name,
    props: { roles: defines.ticketingSpecificStatus.edit.roles },
    component: () =>
      import(
        "../views/ticketing/specificStatus/EditTicketingSpecificStatus.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  ///////////////////// TICKETING OBJET / SOUS-OBJETS ///////////////////////////
  {
    path: defines.ticketingObjectSubobjects.table.path,
    name: defines.ticketingObjectSubobjects.table.name,
    props: { roles: defines.ticketingObjectSubobjects.table.roles },
    component: () =>
      import("../views/ticketing/objectSubobject/TableObjectSubobject.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.ticketingObjectSubobjects.view.path,
    name: defines.ticketingObjectSubobjects.view.name,
    props: { roles: defines.ticketingObjectSubobjects.view.roles },
    component: () =>
      import("../views/ticketing/objectSubobject/DetailObjectSubobject.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.ticketingObjectSubobjects.add.path,
    name: defines.ticketingObjectSubobjects.add.name,
    props: { roles: defines.ticketingObjectSubobjects.add.roles },
    component: () =>
      import("../views/ticketing/objectSubobject/AddObjectSubobject.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.ticketingObjectSubobjects.edit.path,
    name: defines.ticketingObjectSubobjects.edit.name,
    props: { roles: defines.ticketingObjectSubobjects.edit.roles },
    component: () =>
      import("../views/ticketing/objectSubobject/EditObjectSubobject.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  /////////////////////////// APPLICATIONS /////////////////////////////////
  {
    path: defines.applications.table.path,
    name: defines.applications.table.name,
    props: { roles: defines.applications.table.roles },
    component: () => import("../views/applications/TableApplication.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.applications.view.path,
    name: defines.applications.view.name,
    props: { roles: defines.applications.view.roles },
    component: () => import("../views/applications/DetailApplication.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.applications.add.path,
    name: defines.applications.add.name,
    props: { roles: defines.applications.add.roles },
    component: () => import("../views/applications/AddApplication.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.applications.edit.path,
    name: defines.applications.edit.name,
    props: { roles: defines.applications.edit.roles },
    component: () => import("../views/applications/EditApplication.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// TRANSMISSION : ECOSYSTEMS ///////////////////////////////
  {
    path: defines.transmission.ecosystems.table.path,
    name: defines.transmission.ecosystems.table.name,
    props: { roles: defines.transmission.ecosystems.table.roles },
    component: () =>
      import("../views/transmissions/ecosystems/TableEcoSystems.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.transmission.ecosystems.view.path,
    name: defines.transmission.ecosystems.view.name,
    props: { roles: defines.transmission.ecosystems.view.roles },
    component: () =>
      import("../views/transmissions/ecosystems/DetailEcoSystem.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.transmission.ecosystems.add.path,
    name: defines.transmission.ecosystems.add.name,
    props: { roles: defines.transmission.ecosystems.add.roles },
    component: () =>
      import("../views/transmissions/ecosystems/AddEcoSystem.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.transmission.ecosystems.edit.path,
    name: defines.transmission.ecosystems.edit.name,
    props: { roles: defines.transmission.ecosystems.edit.roles },
    component: () =>
      import("../views/transmissions/ecosystems/EditEcoSystem.vue"),
    beforeEnter: beforeEnterRoute(),
  },

  //////////////////////// TRANSMISSION : MEDIUMS ///////////////////////////////
  {
    path: defines.transmission.mediums.table.path,
    name: defines.transmission.mediums.table.name,
    props: { roles: defines.transmission.mediums.table.roles },
    component: () => import("../views/transmissions/mediums/TableMediums.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.transmission.mediums.view.path,
    name: defines.transmission.mediums.view.name,
    props: { roles: defines.transmission.mediums.view.roles },
    component: () => import("../views/transmissions/mediums/DetailMedium.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.transmission.mediums.add.path,
    name: defines.transmission.mediums.add.name,
    props: { roles: defines.transmission.mediums.add.roles },
    component: () => import("../views/transmissions/mediums/AddMedium.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.transmission.mediums.edit.path,
    name: defines.transmission.mediums.edit.name,
    props: { roles: defines.transmission.mediums.edit.roles },
    component: () => import("../views/transmissions/mediums/EditMedium.vue"),
    beforeEnter: beforeEnterRoute(),
  },

  //////////////////////// TRANSMISSION : OTHER RECIPIENTS ///////////////////////////////
  {
    path: defines.transmission.otherrecipienttypes.table.path,
    name: defines.transmission.otherrecipienttypes.table.name,
    props: { roles: defines.transmission.otherrecipienttypes.table.roles },
    component: () =>
      import(
        "../views/transmissions/otherrecipienttypes/TableOtherRecipientTypes.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.transmission.otherrecipienttypes.view.path,
    name: defines.transmission.otherrecipienttypes.view.name,
    props: { roles: defines.transmission.otherrecipienttypes.view.roles },
    component: () =>
      import(
        "../views/transmissions/otherrecipienttypes/DetailOtherRecipientType.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.transmission.otherrecipienttypes.add.path,
    name: defines.transmission.otherrecipienttypes.add.name,
    props: { roles: defines.transmission.otherrecipienttypes.add.roles },
    component: () =>
      import(
        "../views/transmissions/otherrecipienttypes/AddOtherRecipientType.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.transmission.otherrecipienttypes.edit.path,
    name: defines.transmission.otherrecipienttypes.edit.name,
    props: { roles: defines.transmission.otherrecipienttypes.edit.roles },
    component: () =>
      import(
        "../views/transmissions/otherrecipienttypes/EditOtherRecipientType.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },

  //////////////////////// TRANSMISSION : STATUS ///////////////////////////////
  {
    path: defines.transmission.status.table.path,
    name: defines.transmission.status.table.name,
    props: { roles: defines.transmission.status.table.roles },
    component: () => import("../views/transmissions/status/TableStatus.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.transmission.status.view.path,
    name: defines.transmission.status.view.name,
    props: { roles: defines.transmission.status.view.roles },
    component: () => import("../views/transmissions/status/DetailStatus.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.transmission.status.add.path,
    name: defines.transmission.status.add.name,
    props: { roles: defines.transmission.status.add.roles },
    component: () => import("../views/transmissions/status/AddStatus.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.transmission.status.edit.path,
    name: defines.transmission.status.edit.name,
    props: { roles: defines.transmission.status.edit.roles },
    component: () => import("../views/transmissions/status/EditStatus.vue"),
    beforeEnter: beforeEnterRoute(),
  },

  //////////////////////// TRANSMISSION : TRANSMISSIONS ///////////////////////////////
  {
    path: defines.transmission.transmissions.table.path,
    name: defines.transmission.transmissions.table.name,
    props: { roles: defines.transmission.transmissions.table.roles },
    component: () =>
      import("../views/transmissions/transmissions/TableTransmissions.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.transmission.transmissions.view.path,
    name: defines.transmission.transmissions.view.name,
    props: { roles: defines.transmission.transmissions.view.roles },
    component: () =>
      import("../views/transmissions/transmissions/DetailTransmission.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.transmission.transmissions.add.path,
    name: defines.transmission.transmissions.add.name,
    props: { roles: defines.transmission.transmissions.add.roles },
    component: () =>
      import("../views/transmissions/transmissions/AddTransmission.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.transmission.transmissions.edit.path,
    name: defines.transmission.transmissions.edit.name,
    props: { roles: defines.transmission.transmissions.edit.roles },
    component: () =>
      import("../views/transmissions/transmissions/EditTransmission.vue"),
    beforeEnter: beforeEnterRoute(),
  },

  //////////////////////// TRANSMISSION : PRODUITS AVEC ///////////////////////////////
  {
    path: defines.avecProducts.table.path,
    name: defines.avecProducts.table.name,
    props: { roles: defines.avecProducts.table.roles },
    component: () => import("../views/avecProducts/TableAvecProducts.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.avecProducts.view.path,
    name: defines.avecProducts.view.name,
    props: { roles: defines.avecProducts.view.roles },
    component: () => import("../views/avecProducts/DetailsAvecProducts.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.avecProducts.add.path,
    name: defines.avecProducts.add.name,
    props: { roles: defines.avecProducts.add.roles },
    component: () => import("../views/avecProducts/AddAvecProducts.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.avecProducts.edit.path,
    name: defines.avecProducts.edit.name,
    props: { roles: defines.avecProducts.edit.roles },
    component: () => import("../views/avecProducts/EditAvecProducts.vue"),
    beforeEnter: beforeEnterRoute(),
  },

  //////////////////////// TRANSMISSION : PERIMETRES ///////////////////////////////
  {
    path: defines.transmission.perimeters.table.path,
    name: defines.transmission.perimeters.table.name,
    props: { roles: defines.transmission.perimeters.table.roles },
    component: () =>
      import("../views/transmissions/perimeters/TablePerimeter.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.transmission.perimeters.view.path,
    name: defines.transmission.perimeters.view.name,
    props: { roles: defines.transmission.perimeters.view.roles },
    component: () =>
      import("../views/transmissions/perimeters/DetailsPerimeter.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.transmission.perimeters.add.path,
    name: defines.transmission.perimeters.add.name,
    props: { roles: defines.transmission.perimeters.add.roles },
    component: () =>
      import("../views/transmissions/perimeters/AddPerimeter.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.transmission.perimeters.edit.path,
    name: defines.transmission.perimeters.edit.name,
    props: { roles: defines.transmission.perimeters.edit.roles },
    component: () =>
      import("../views/transmissions/perimeters/EditPerimeter.vue"),
    beforeEnter: beforeEnterRoute(),
  },

  //////////////////////// TRANSMISSION : CELLULES CLIENT ///////////////////////////////
  {
    path: defines.transmission.customerCells.table.path,
    name: defines.transmission.customerCells.table.name,
    props: { roles: defines.transmission.customerCells.table.roles },
    component: () =>
      import("../views/transmissions/customerCells/TableCustomerCells.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.transmission.customerCells.view.path,
    name: defines.transmission.customerCells.view.name,
    props: { roles: defines.transmission.customerCells.view.roles },
    component: () =>
      import("../views/transmissions/customerCells/DetailsCustomerCells.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.transmission.customerCells.add.path,
    name: defines.transmission.customerCells.add.name,
    props: { roles: defines.transmission.customerCells.add.roles },
    component: () =>
      import("../views/transmissions/customerCells/AddCustomerCells.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.transmission.customerCells.edit.path,
    name: defines.transmission.customerCells.edit.name,
    props: { roles: defines.transmission.customerCells.edit.roles },
    component: () =>
      import("../views/transmissions/customerCells/EditCustomerCells.vue"),
    beforeEnter: beforeEnterRoute(),
  },

  //////////////////////// NATURE RELATION DE TRAVAIL ///////////////////////////////
  {
    path: defines.workRelationshipTypes.table.path,
    name: defines.workRelationshipTypes.table.name,
    props: { roles: defines.workRelationshipTypes.table.roles },
    component: () =>
      import("../views/workRelationshipTypes/TableWorkRelationshipType.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.workRelationshipTypes.view.path,
    name: defines.workRelationshipTypes.view.name,
    props: { roles: defines.workRelationshipTypes.view.roles },
    component: () =>
      import("../views/workRelationshipTypes/DetailWorkRelationshipType.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.workRelationshipTypes.add.path,
    name: defines.workRelationshipTypes.add.name,
    props: { roles: defines.workRelationshipTypes.add.roles },
    component: () =>
      import("../views/workRelationshipTypes/AddWorkRelationshipType.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.workRelationshipTypes.edit.path,
    name: defines.workRelationshipTypes.edit.name,
    props: { roles: defines.workRelationshipTypes.edit.roles },
    component: () =>
      import("../views/workRelationshipTypes/EditWorkRelationshipType.vue"),
    beforeEnter: beforeEnterRoute(),
  },

  //////////////////////// TYPES DE REMUNERATION ///////////////////////////////
  {
    path: defines.remunerationTypes.table.path,
    name: defines.remunerationTypes.table.name,
    props: { roles: defines.remunerationTypes.table.roles },
    component: () =>
      import("../views/remunerationTypes/TableRemunerationType.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.remunerationTypes.view.path,
    name: defines.remunerationTypes.view.name,
    props: { roles: defines.remunerationTypes.view.roles },
    component: () =>
      import("../views/remunerationTypes/DetailRemunerationType.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.remunerationTypes.add.path,
    name: defines.remunerationTypes.add.name,
    props: { roles: defines.remunerationTypes.add.roles },
    component: () =>
      import("../views/remunerationTypes/AddRemunerationType.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.remunerationTypes.edit.path,
    name: defines.remunerationTypes.edit.name,
    props: { roles: defines.remunerationTypes.edit.roles },
    component: () =>
      import("../views/remunerationTypes/EditRemunerationType.vue"),
    beforeEnter: beforeEnterRoute(),
  },

  //////////////////////// MOTIFS DE RECRUTEMENT ///////////////////////////////
  {
    path: defines.recruitingReason.table.path,
    name: defines.recruitingReason.table.name,
    props: { roles: defines.recruitingReason.table.roles },
    component: () =>
      import("../views/recruitingReason/TableRecruitingReason.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.recruitingReason.view.path,
    name: defines.recruitingReason.view.name,
    props: { roles: defines.recruitingReason.view.roles },
    component: () =>
      import("../views/recruitingReason/DetailRecruitingReason.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.recruitingReason.add.path,
    name: defines.recruitingReason.add.name,
    props: { roles: defines.recruitingReason.add.roles },
    component: () =>
      import("../views/recruitingReason/AddRecruitingReason.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.recruitingReason.edit.path,
    name: defines.recruitingReason.edit.name,
    props: { roles: defines.recruitingReason.edit.roles },
    component: () =>
      import("../views/recruitingReason/EditRecruitingReason.vue"),
    beforeEnter: beforeEnterRoute(),
  },

  //////////// COMPLEMENT ACCES POSTE (POINT REQUIS ANNONCE) ////////////////
  {
    path: defines.jobAdRequiredPoint.table.path,
    name: defines.jobAdRequiredPoint.table.name,
    props: { roles: defines.jobAdRequiredPoint.table.roles },
    component: () =>
      import("../views/jobAdRequiredPoint/TableJobAdRequiredPoint.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.jobAdRequiredPoint.view.path,
    name: defines.jobAdRequiredPoint.view.name,
    props: { roles: defines.jobAdRequiredPoint.view.roles },
    component: () =>
      import("../views/jobAdRequiredPoint/DetailJobAdRequiredPoint.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.jobAdRequiredPoint.add.path,
    name: defines.jobAdRequiredPoint.add.name,
    props: { roles: defines.jobAdRequiredPoint.add.roles },
    component: () =>
      import("../views/jobAdRequiredPoint/AddJobAdRequiredPoint.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.jobAdRequiredPoint.edit.path,
    name: defines.jobAdRequiredPoint.edit.name,
    props: { roles: defines.jobAdRequiredPoint.edit.roles },
    component: () =>
      import("../views/jobAdRequiredPoint/EditJobAdRequiredPoint.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// INTITULE POSTE ///////////////////////////////
  {
    path: defines.jobTitle.table.path,
    name: defines.jobTitle.table.name,
    props: { roles: defines.jobTitle.table.roles },
    component: () => import("../views/jobTitle/TableJobTitle.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.jobTitle.view.path,
    name: defines.jobTitle.view.name,
    props: { roles: defines.jobTitle.view.roles },
    component: () => import("../views/jobTitle/DetailJobTitle.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.jobTitle.add.path,
    name: defines.jobTitle.add.name,
    props: { roles: defines.jobTitle.add.roles },
    component: () => import("../views/jobTitle/AddJobTitle.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.jobTitle.edit.path,
    name: defines.jobTitle.edit.name,
    props: { roles: defines.jobTitle.edit.roles },
    component: () => import("../views/jobTitle/EditJobTitle.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// GRAND DOMAINE ROME ///////////////////////////////
  {
    path: defines.largeDomainRome.table.path,
    name: defines.largeDomainRome.table.name,
    props: { roles: defines.largeDomainRome.table.roles },
    component: () =>
      import("../views/largeDomainRome/TableLargeDomainRome.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  // {
  //   path: defines.largeDomainRome.view.path,
  //   name: defines.largeDomainRome.view.name,
  //   component: () =>
  //     import("../views/largeDomainRome/DetailLargeDomainRome.vue"),
  //   beforeEnter: beforeEnterRoute(),
  // },
  // {
  //   path: defines.largeDomainRome.add.path,
  //   name: defines.largeDomainRome.add.name,
  //   component: () =>
  //     import("../views/largeDomainRome/AddJobAdRequiredPoint.vue"),
  //   beforeEnter: beforeEnterRoute(),
  // },
  // {
  //   path: defines.largeDomainRome.edit.path,
  //   name: defines.largeDomainRome.edit.name,
  //   component: () =>
  //     import("../views/largeDomainRome/EditLargeDomainRome.vue"),
  //   beforeEnter: beforeEnterRoute(),
  // },
  //////////////////////// ZONES DE MOBILITE ///////////////////////////////
  {
    path: defines.mobilityzones.table.path,
    name: defines.mobilityzones.table.name,
    props: { roles: defines.mobilityzones.table.roles },
    component: () => import("../views/mobilityZone/TableMobilityZones.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.mobilityzones.view.path,
    name: defines.mobilityzones.view.name,
    props: { roles: defines.mobilityzones.view.roles },
    component: () => import("../views/mobilityZone/DetailMobilityZone.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.mobilityzones.add.path,
    name: defines.mobilityzones.add.name,
    props: { roles: defines.mobilityzones.add.roles },
    component: () => import("../views/mobilityZone/AddMobilityZone.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.mobilityzones.edit.path,
    name: defines.mobilityzones.edit.name,
    props: { roles: defines.mobilityzones.edit.roles },
    component: () => import("../views/mobilityZone/EditMobilityZone.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// AVANTAGES GROUPE ///////////////////////////////a
  {
    path: defines.advantages.table.path,
    name: defines.advantages.table.name,
    props: { roles: defines.advantages.table.roles },
    component: () => import("../views/advantages/TableAdvantages.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.advantages.view.path,
    name: defines.advantages.view.name,
    props: { roles: defines.advantages.view.roles },
    component: () => import("../views/advantages/DetailAdvantage.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.advantages.add.path,
    name: defines.advantages.add.name,
    props: { roles: defines.advantages.add.roles },
    component: () => import("../views/advantages/AddAdvantage.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.advantages.edit.path,
    name: defines.advantages.edit.name,
    props: { roles: defines.advantages.edit.roles },
    component: () => import("../views/advantages/EditAdvantage.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// PRIMES COLLABORATEUR ///////////////////////////////a
  {
    path: defines.primes.table.path,
    name: defines.primes.table.name,
    props: { roles: defines.primes.table.roles },
    component: () => import("../views/primes/TablePrime.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.primes.view.path,
    name: defines.primes.view.name,
    props: { roles: defines.primes.view.roles },
    component: () => import("../views/primes/DetailPrime.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.primes.add.path,
    name: defines.primes.add.name,
    props: { roles: defines.primes.add.roles },
    component: () => import("../views/primes/AddPrime.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.primes.edit.path,
    name: defines.primes.edit.name,
    props: { roles: defines.primes.edit.roles },
    component: () => import("../views/primes/EditPrime.vue"),
    beforeEnter: beforeEnterRoute(),
  },

  //////////////////////// LES CONTEXTES DE RUBRIQUES ///////////////////////////////
  {
    path: defines.rubricsContext.table.path,
    name: defines.rubricsContext.table.name,
    props: { roles: defines.rubricsContext.table.roles },
    component: () => import("../views/rubricsContext/TableRubricsContext.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.rubricsContext.view.path,
    name: defines.rubricsContext.view.name,
    props: { roles: defines.rubricsContext.view.roles },
    component: () => import("../views/rubricsContext/DetailRubricsContext.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.rubricsContext.add.path,
    name: defines.rubricsContext.add.name,
    props: { roles: defines.rubricsContext.add.roles },
    component: () => import("../views/rubricsContext/AddRubricsContext.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.rubricsContext.edit.path,
    name: defines.rubricsContext.edit.name,
    props: { roles: defines.rubricsContext.edit.roles },
    component: () => import("../views/rubricsContext/EditRubricsContext.vue"),
    beforeEnter: beforeEnterRoute(),
  },

  //////////////////////// LES STATUS DISPONIBLE POUR LE TRAVAIL ///////////////////////////////
  {
    path: defines.fitForWorkStatus.table.path,
    name: defines.fitForWorkStatus.table.name,
    props: { roles: defines.fitForWorkStatus.table.roles },
    component: () =>
      import("../views/fitForWorkStatus/TableFitForWorkStatus.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.fitForWorkStatus.view.path,
    name: defines.fitForWorkStatus.view.name,
    props: { roles: defines.fitForWorkStatus.view.roles },
    component: () =>
      import("../views/fitForWorkStatus/DetailFitForWorkStatus.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.fitForWorkStatus.add.path,
    name: defines.fitForWorkStatus.add.name,
    props: { roles: defines.fitForWorkStatus.add.roles },
    component: () =>
      import("../views/fitForWorkStatus/AddFitForWorkStatus.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.fitForWorkStatus.edit.path,
    name: defines.fitForWorkStatus.edit.name,
    props: { roles: defines.fitForWorkStatus.edit.roles },
    component: () =>
      import("../views/fitForWorkStatus/EditFitForWorkStatus.vue"),
    beforeEnter: beforeEnterRoute(),
  },

  //////////////////////// LES CONSENTEMENTS ///////////////////////////////
  {
    path: defines.consents.table.path,
    name: defines.consents.table.name,
    props: { roles: defines.consents.table.roles },
    component: () => import("../views/consents/TableConsent.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.consents.view.path,
    name: defines.consents.view.name,
    props: { roles: defines.consents.view.roles },
    component: () => import("../views/consents/DetailConsent.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.consents.add.path,
    name: defines.consents.add.name,
    props: { roles: defines.consents.add.roles },
    component: () => import("../views/consents/AddConsent.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.consents.edit.path,
    name: defines.consents.edit.name,
    props: { roles: defines.consents.edit.roles },
    component: () => import("../views/consents/EditConsent.vue"),
  },

  //////////////////////// LES TYPES DE TARIFS ESTABLISHMENT ///////////////////////////////
  {
    path: defines.pricesTypes.table.path,
    name: defines.pricesTypes.table.name,
    props: { roles: defines.pricesTypes.table.roles },
    component: () => import("../views/priceTypes/TablePriceTypes.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.pricesTypes.view.path,
    name: defines.pricesTypes.view.name,
    props: { roles: defines.pricesTypes.view.roles },
    component: () => import("../views/priceTypes/DetailPriceTypes.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.pricesTypes.add.path,
    name: defines.pricesTypes.add.name,
    props: { roles: defines.pricesTypes.add.roles },
    component: () => import("../views/priceTypes/AddPriceTypes.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.pricesTypes.edit.path,
    name: defines.pricesTypes.edit.name,
    props: { roles: defines.pricesTypes.edit.roles },
    component: () => import("../views/priceTypes/EditPriceTypes.vue"),
    beforeEnter: beforeEnterRoute(),
  },

  //////////////////////// LES UNITES DE TARIFS ESTABLISHMENT ///////////////////////////////
  {
    path: defines.pricesUnits.table.path,
    name: defines.pricesUnits.table.name,
    props: { roles: defines.pricesUnits.table.roles },
    component: () => import("../views/priceUnits/TablePriceUnits.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.pricesUnits.view.path,
    name: defines.pricesUnits.view.name,
    props: { roles: defines.pricesUnits.view.roles },
    component: () => import("../views/priceUnits/DetailPriceUnits.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.pricesUnits.add.path,
    name: defines.pricesUnits.add.name,
    props: { roles: defines.pricesUnits.add.roles },
    component: () => import("../views/priceUnits/AddPriceUnits.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.pricesUnits.edit.path,
    name: defines.pricesUnits.edit.name,
    props: { roles: defines.pricesUnits.edit.roles },
    component: () => import("../views/priceUnits/EditPriceUnits.vue"),
    beforeEnter: beforeEnterRoute(),
  },

  //////////////////////// LES TARIFS ESTABLISHMENT ///////////////////////////////
  {
    path: defines.priceEstablishment.table.path,
    name: defines.priceEstablishment.table.name,
    props: { roles: defines.priceEstablishment.table.roles },
    component: () =>
      import("../views/priceEstablishment/TablePriceEstablishment.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.priceEstablishment.view.path,
    name: defines.priceEstablishment.view.name,
    props: { roles: defines.priceEstablishment.view.roles },
    component: () =>
      import("../views/priceEstablishment/DetailPriceEstablishment.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.priceEstablishment.add.path,
    name: defines.priceEstablishment.add.name,
    props: { roles: defines.priceEstablishment.add.roles },
    component: () =>
      import("../views/priceEstablishment/AddPriceEstablishment.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.priceEstablishment.edit.path,
    name: defines.priceEstablishment.edit.name,
    props: { roles: defines.priceEstablishment.edit.roles },
    component: () =>
      import("../views/priceEstablishment/EditPriceEstablishment.vue"),
    beforeEnter: beforeEnterRoute(),
  },

  //////////////////////// LES TARIFS GROUPE ///////////////////////////////
  {
    path: defines.priceGroup.table.path,
    name: defines.priceGroup.table.name,
    props: { roles: defines.priceGroup.table.roles },
    component: () => import("../views/pricesGroup/TablePricesGroup.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.priceGroup.view.path,
    name: defines.priceGroup.view.name,
    props: { roles: defines.priceGroup.view.roles },
    component: () => import("../views/pricesGroup/DetailsPricesGroup.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.priceGroup.add.path,
    name: defines.priceGroup.add.name,
    props: { roles: defines.priceGroup.add.roles },
    component: () => import("../views/pricesGroup/AddPricesGroup.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.priceGroup.edit.path,
    name: defines.priceGroup.edit.name,
    props: { roles: defines.priceGroup.edit.roles },
    component: () => import("../views/pricesGroup/EditPricesGroup.vue"),
    beforeEnter: beforeEnterRoute(),
  },

  //////////////////////// LES CATEGORIES DE PRODUIT ///////////////////////////////
  {
    path: defines.avecProductsCategory.table.path,
    name: defines.avecProductsCategory.table.name,
    props: { roles: defines.avecProductsCategory.table.roles },
    component: () =>
      import("../views/avecProductCategory/TableAvecProductCategory.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.avecProductsCategory.view.path,
    name: defines.avecProductsCategory.view.name,
    props: { roles: defines.avecProductsCategory.view.roles },
    component: () =>
      import("../views/avecProductCategory/DetailAvecProductCategory.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.avecProductsCategory.add.path,
    name: defines.avecProductsCategory.add.name,
    props: { roles: defines.avecProductsCategory.add.roles },
    component: () =>
      import("../views/avecProductCategory/AddAvecProductCategory.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.avecProductsCategory.edit.path,
    name: defines.avecProductsCategory.edit.name,
    props: { roles: defines.avecProductsCategory.edit.roles },
    component: () =>
      import("../views/avecProductCategory/EditAvecProductCategory.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// LES FAQS ///////////////////////////////
  {
    path: defines.faqs.table.path,
    name: defines.faqs.table.name,
    props: { roles: defines.faqs.table.roles },
    component: () => import("../views/faqs/TableFaqs.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.faqs.view.path,
    name: defines.faqs.view.name,
    props: { roles: defines.faqs.view.roles },
    component: () => import("../views/faqs/DetailFaqs.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.faqs.add.path,
    name: defines.faqs.add.name,
    props: { roles: defines.faqs.add.roles },
    component: () => import("../views/faqs/AddFaqs.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.faqs.edit.path,
    name: defines.faqs.edit.name,
    props: { roles: defines.faqs.edit.roles },
    component: () => import("../views/faqs/EditFaqs.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  ///////////////// LES TEMPLATE CAPACITAIRES ///////////////////////
  {
    path: defines.capacities.table.path,
    name: defines.capacities.table.name,
    props: { roles: defines.capacities.table.roles },
    component: () => import("../views/capacities/TableCapacities.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.capacities.view.path,
    name: defines.capacities.view.name,
    props: { roles: defines.capacities.view.roles },
    component: () => import("../views/capacities/DetailCapacities.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.capacities.edit.path,
    name: defines.capacities.edit.name,
    props: { roles: defines.capacities.edit.roles },
    component: () => import("../views/capacities/EditCapacities.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////////// LES CAPACITES ///////////////////////////////////
  {
    path: defines.establishmentCapacities.view.path,
    name: defines.establishmentCapacities.view.name,
    props: { roles: defines.establishmentCapacities.view.roles },
    component: () =>
      import("../views/capacities/EditEstablishmentCapacities.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// LES REFERENTS DE FONCTION ///////////////////////////////
  {
    path: defines.referentFunction.table.path,
    name: defines.referentFunction.table.name,
    props: { roles: defines.referentFunction.table.roles },
    component: () =>
      import(
        "../views/establishments/referentFunction/TableReferentFunctions.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.referentFunction.view.path,
    name: defines.referentFunction.view.name,
    props: { roles: defines.referentFunction.view.roles },
    component: () =>
      import(
        "../views/establishments/referentFunction/DetailReferentFunction.vue"
      ),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.referentFunction.edit.path,
    name: defines.referentFunction.edit.name,
    props: { roles: defines.referentFunction.edit.roles },
    component: () =>
      import(
        "../views/establishments/referentFunction/EditReferentFunction.vue"
      ),
  },

  //////////////////////// ACS ///////////////////////////////
  {
    path: defines.acsservices.view.path,
    name: defines.acsservices.view.name,
    props: { roles: defines.acsservices.view.roles },
    component: () => import("../views/acs/ServicesAcs.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.acsfunctions.view.path,
    name: defines.acsfunctions.view.name,
    props: { roles: defines.acsfunctions.view.roles },
    component: () => import("../views/acs/FunctionsAcs.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.acsusers.view.path,
    name: defines.acsusers.view.name,
    props: { roles: defines.acsusers.view.roles },
    component: () => import("../views/acs/UserAcs.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.acsusersroles.view.path,
    name: defines.acsusersroles.view.name,
    props: { roles: defines.acsusersroles.view.roles },
    component: () => import("../views/acs/UsersRoles.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.acscustomerroles.view.path,
    name: defines.acscustomerroles.view.name,
    props: { roles: defines.acscustomerroles.view.roles },
    component: () => import("../views/acs/CustomerAcs.vue"),
    beforeEnter: beforeEnterRoute(),
  },

  //////////////////////// FICHE PRATICIEN ///////////////////////////////
  {
    path: defines.practitionerfile.view.path,
    name: defines.practitionerfile.view.name,
    props: { roles: defines.practitionerfile.view.roles },
    component: () => import("../views/practitioners/filePractitioner.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// CRÉATION PRATICIEN ///////////////////////////////
  {
    path: defines.createpractitioner.view.path,
    name: defines.createpractitioner.view.name,
    props: { roles: defines.createpractitioner.view.roles },
    component: () => import("../views/practitioners/createPractitioner.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  //////////////////////// LES EXPERTISES MEDICALE ///////////////////////////////
  {
    path: defines.medicalexpertises.table.path,
    name: defines.medicalexpertises.table.name,
    props: { roles: defines.medicalexpertises.table.roles },
    component: () =>
      import("../views/medicalExpertises/TableMedicalExpertise.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.medicalexpertises.view.path,
    name: defines.medicalexpertises.view.name,
    props: { roles: defines.medicalexpertises.view.roles },
    component: () =>
      import("../views/medicalExpertises/DetailMedicalExpertise.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.medicalexpertises.add.path,
    name: defines.medicalexpertises.add.name,
    props: { roles: defines.medicalexpertises.add.roles },
    component: () =>
      import("../views/medicalExpertises/AddMedicalExpertise.vue"),
    beforeEnter: beforeEnterRoute(),
  },
  {
    path: defines.medicalexpertises.edit.path,
    name: defines.medicalexpertises.edit.name,
    props: { roles: defines.medicalexpertises.edit.roles },
    component: () =>
      import("../views/medicalExpertises/EditMedicalExpertise.vue"),
    beforeEnter: beforeEnterRoute(),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
