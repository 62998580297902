import EstablishmentsHttpClient from "@/api/establishments/establishments_http_client";
import EstablishmentsApi from "@/api/establishments/establishments_api";
import LegalStructuresApi from "@/api/establishments/legal_structures_api.js";
import LegalStructureTypeApi from "@/api/establishments/legal_structure_type_api.js";
import ExternalContractTypesApi from "@/api/establishments/external_contract_types_api";
import ExternalContractContactApi from "@/api/establishments/external_contract_contact_api.js";
import LegalStructureStatusApi from "@/api/establishments/legal_structure_status_api.js";
import LegalRepresentativeStatusApi from "@/api/establishments/legal_representative_status_api.js";
import StockholdersApi from "@/api/establishments/stockholders_api.js";
import LegalStructureEventTypesApi from "@/api/establishments/legal_structure_event_types_api.js";
import EstablishmentStatusApi from "@/api/establishments/establishments_status_api.js";
import EstablishmentJurisApi from "@/api/establishments/establishments_juris_api.js";
import LegalStructureStockholderApi from "@/api/establishments/legal_structure_stockholder_api.js";
import LegalStructureExternalContractsApi from "@/api/establishments/legal_structure_external_contracts_api.js";
import LegalStructureEventsApi from "@/api/establishments/legal_structure_events_api.js";
import SuperEstablishmentApi from "@/api/establishments/super_establishment_api.js";
import ReferentFunctionApi from "@/api/establishments/referent_function_api.js";
import SuperLegalStructureApi from "@/api/establishments/super_legal_structure_api.js";
import CapacitiesApi from "@/api/establishments/capacities_api.js";
import JobOfferRecipientApi from "@/api/establishments/job_offer_recipient_api.js";

/** Construction du client Http de l'api establishment */
export function initHttpClient(apiKey, tokenFunction, apmTokenFunction) {
  //on instancie les clients http
  let httpclient = new EstablishmentsHttpClient();
  httpclient.getToken = tokenFunction;
  httpclient.getApmToken = apmTokenFunction;
  httpclient.api_key = apiKey;

  return httpclient;
}

/** Instanciation des api du silo Establishment */
export function initApi(httpClient) {
  return {
    establishments: new EstablishmentsApi(httpClient),
    establishmentsStatus: new EstablishmentStatusApi(httpClient),
    establishmentsJuris: new EstablishmentJurisApi(httpClient),
    legalStructures: new LegalStructuresApi(httpClient),
    legalStructureType: new LegalStructureTypeApi(httpClient),
    externalContractTypes: new ExternalContractTypesApi(httpClient),
    legalStructureStatus: new LegalStructureStatusApi(httpClient),
    legalRepresentativeStatus: new LegalRepresentativeStatusApi(httpClient),
    externalContractContact: new ExternalContractContactApi(httpClient),
    stockholder: new StockholdersApi(httpClient),
    legalStructureEventType: new LegalStructureEventTypesApi(httpClient),
    legalStructureStockholder: new LegalStructureStockholderApi(httpClient),
    legalStructureExternalContracts: new LegalStructureExternalContractsApi(
      httpClient
    ),
    legalStructureEvents: new LegalStructureEventsApi(httpClient),
    superEstablishment: new SuperEstablishmentApi(httpClient),
    referentFunction: new ReferentFunctionApi(httpClient),
    superLegalStructure: new SuperLegalStructureApi(httpClient),
    capacities: new CapacitiesApi(httpClient),
    jobOfferRecipientApi: new JobOfferRecipientApi(httpClient),
  };
}
