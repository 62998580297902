<template>
  <div>
    <a :href="onClickNavButton('domicile')" style="text-decoration: none">
      <button class="nav-btn" elevation="0" text>
        <v-col class="pa-0 ma-0">
          <v-row no-gutters class="ml-5 rowTopNav">
            Rester
          </v-row>
          <v-row no-gutters class="rowNav" align="center">
            <v-icon class="mr-2" size="12">$vuetify.icons.iconHome</v-icon>à domicile
          </v-row>
        </v-col>
      </button>
    </a>
    <a :href="onClickNavButton('ehpad')" style="text-decoration: none">
      <button class="nav-btn" elevation="0" text>
        <v-col class="pa-0 ma-0">
          <v-row no-gutters class="ml-5 rowTopNav">
            Vivre
          </v-row>
          <v-row no-gutters class="rowNav" align="center">
            <v-icon class="mr-2" size="12">$vuetify.icons.iconEhpad</v-icon>en maison de retraite
          </v-row>
        </v-col>
      </button>
    </a>
    <a :href="onClickNavButton('hopital')" style="text-decoration: none">
      <button class="nav-btn" elevation="0" text>
        <v-col class="pa-0 ma-0">
          <v-row no-gutters class="ml-5 rowTopNav">
            Aller
          </v-row>
          <v-row no-gutters class="rowNav" align="center">
            <v-icon class="mr-2" size="12">$vuetify.icons.iconHospital</v-icon>à l'hôpital
          </v-row>
        </v-col>
      </button>
    </a>
    <a :href="onClickNavButton('ville')" style="text-decoration: none">
      <button class="nav-btn" elevation="0" text>
        <v-col class="pa-0 ma-0">
          <v-row no-gutters class="ml-5 rowTopNav">
            Se soigner
          </v-row>
          <v-row no-gutters class="rowNav" align="center">
            <v-icon class="mr-2" size="12">$vuetify.icons.iconVille</v-icon>en ville
          </v-row>
        </v-col>
      </button>
    </a>
  </div>
</template>

<script>
import * as logger from "@/tools/logger.js";

export default {
  name: "MenuNavButtonComponent",

  mixins: [],
  props: {},
  data: () => ({}),
  methods: {
    onClickNavButton(whitchOne) {
      let url;
      switch (whitchOne) {
        case "domicile":
          url = process.env.VUE_APP_BASE_SITE_AVEC_DOMICILE;
          break;
        case "ehpad":
          url = process.env.VUE_APP_BASE_SITE_AVEC_EHPAD;
          break;
        case "hopital":
          url = process.env.VUE_APP_BASE_SITE_AVEC_HOPITAL;
          break;
        case "ville":
          url = process.env.VUE_APP_BASE_SITE_AVEC_VILLE;
          break;
        default:
          url = process.env.VUE_APP_BASE_SITE_AVEC;
          break;
      }

      if (url && url != "") {
        logger.debug(url);
        return url;
      }
      logger.debug("url Avec is null undefined or empty");
    },
  },
  computed: {},
  mounted() {},
  updated() {},
  watch: {},
};
</script>

<style>
.nav-btn {
  vertical-align: middle;
  margin: 8px;  
  color: rgb(30, 29, 29);
  display: inline-flex;
  gap: 8px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: center;
  flex: 1 0 auto;
  padding: 0 16px;
  height: 36px;
  
}
.rowNav {
  font-style: normal;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
}
.rowTopNav {
  font-style: normal;
  font-weight: 500;
  text-align: center;
  font-size: 12px;
}
.nav-btn:hover {
  transition: color 0.4s ease-in 0s;
  color: rgb(226, 0, 128);
}
.nav-btn:hover span svg {
  transition: fill 0.4s ease-in 0s;
  fill: rgb(226, 0, 128);
}
</style>
