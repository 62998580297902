import * as logger from "@/tools/logger.js";

//import * as loginService from "@/service/login_service.js";
import * as loginStoreService from "@/service/login_store_service.js";

/** Constantes pour l'iframe */
export const _URL_AUTH_AVEC = process.env.VUE_APP_URL_IFRAME;
export const _URL_IFRAME = _URL_AUTH_AVEC + "/iFrameAuth.html";
export const _IFRAME_ID = "iframeAuth";

const _FIREBASE_KEY = process.env.VUE_APP_FIREBASE_API_KEY;

const _METHOD_GET = "getAuth";
const _METHOD_SET = "setAuth";
const _METHOD_DELETE = "deleteAuth";

const _DB_NAME = "firebaseLocalStorageDb";
const _DB_OBJECT_NAME = "firebaseLocalStorage";

// Permet de savoir si le sso à répondu.
export let getAuthLoading = false;

/**
 * Méthode de récupération de l'iframe se trouvant
 * dans la partie générale de l'application
 * Retourne l'iframe récupérée
 */
export function getIframe() {
  // récupération de l'iframe dans le document général
  let iframe = document.getElementById(_IFRAME_ID);

  return iframe;
}

/**
 * Recharge l'iframe pour palier à l'event listener codé dans l'iframe.
 * L'event listener en question n'accepte de répondre qu'une fois à un postMessage.
 */
export function reloadIframe() {
  // Récupération de l'iframe de la page
  let iframe = getIframe();

  // // Réaffectation de la source ce qui permet à l'iframe de la page de se recharger.
  // iframe.src = _URL_IFRAME;

  // Réaffectation de la source ce qui permet à l'iframe de la page de se recharger.
  iframe.contentWindow.location.replace(_URL_IFRAME);

  return iframe;
}

/**
 * Méthode pour voir si le service d'auth est disponible.
 * @returns  true si le service est disponible, false sinon.
 */
export async function checkAuthDispo() {
  // Vérification si navigateur est Ios
  if (navigator.userAgent.match("CriOS")) {
    return false;
  }

  // Requête simple pour vérifier si l'iframe est disponible
  let response = await fetch(_URL_IFRAME, {
    method: "HEAD",
    mode: "no-cors",
  });

  if (response) {
    return true;
  } else {
    return false;
  }
}

/**
 * Méthode de demande d'informations d'authentification de l'utilisateur.
 * Lance un message à l'iframe
 */
export async function getAuthSso() {
  try {
    getAuthLoading = true;

    // Demande de disponibilitée du service oauth
    let isDispo = await checkAuthDispo();

    // Service oauth disponible
    if (isDispo) {
      // Récupération de l'iframe
      let iframe = reloadIframe();

      // Envoie du message de récupération des infos d'auth à l'iframe.
      iframe.contentWindow.postMessage({ messageId: _METHOD_GET }, "*");
    } else {
      logger.debug("Auth is not dispo");
      getAuthLoading = false;
    }
  } catch (error) {
    logger.error("  Error on getAuth SSO", error);
    getAuthLoading = false;
  }
}

/**
 * Méthode de set d'informations d'authentification de l'utilisateur.
 * Lance un message à l'iframe.
 */
export async function setAuthSso(user) {
  try {
    // Demande de disponibilitée du service oauth
    let isDispo = await checkAuthDispo();

    // Service oauth disponible
    if (isDispo) {
      // Récupération de l'iframe
      let iframe = reloadIframe();

      // Get all from indexedDB
      let datas = await getAuthOnDB();

      // Formatte les datas du user pour l'iframe.
      let entity = JSON.stringify(datas);

      // Envoi les datas users à l'iframe
      iframe.contentWindow.postMessage(
        { messageId: _METHOD_SET, data: entity },
        "*"
      );

      // Sauvegarde les datas dans le local storage
      saveUserData(user);
    }
  } catch (error) {
    logger.error("  Error on setAUth SSO", error);
  }
}

/**
 * Méthode de demande de suppression des informations d'authentification de l'utilisateur
 */
export async function deleteAuth() {
  try {
    getAuthLoading = true;

    logger.debug("Before checkAuthIsDispo");
    // Demande de disponibilité du service oauth
    let isDispo = await checkAuthDispo();

    if (isDispo) {
      logger.debug("ssoService --> delete oauth --> Auth is dispo");

      // Récupération de l'iframe
      let iframe = reloadIframe();

      // Envoie du message de récupération des infos d'auth à l'iframe.
      iframe.contentWindow.postMessage({ messageId: _METHOD_DELETE }, "*");
    } else {
      logger.debug("Auth is not dispo");
      getAuthLoading = false;
    }
  } catch (error) {
    logger.error("  Error on getAUth SSO", error);
    getAuthLoading = false;
  }
}

/**
 * Méthode associées au listener de l'iframe.
 * Traite les évènements de retour des communications avec l'iframe.
 * @param {*} e Objet d'évènement contenant notamment la méthode postée à l'iframe
 */
export function receiveMessage(e) {
  // logger.debug("component iframeSsoAuth --> receiveMessage --> " + e.origin);
  // logger.debug("component iframeSsoAuth --> receiveMessage --> " + e.data.messageId);

  if (e.origin == _URL_AUTH_AVEC) {
    logger.debug("Origine iframe oauth", e.origin);
    var data = e.data;
    // let payload = null;

    // Switch case pour les traitements selon messageId de l'event
    switch (data.messageId) {
      // Méthode "getAuth"
      case _METHOD_GET:
        logger.debug("Méthod getAuth");
        // Récupération des datas de la réponse
        var payload = JSON.parse(data.response);

        // Si la réponse est vide, on désinscrit le user
        if (!payload) {
          logger.debug("payload is null --> SIGNOUT");
          signout();
          getAuthLoading = false;
          break;
        }
        // Vérifie si le token est encore valide
        // else if (tokenIsExpired(payload.firebaseLocalStorage[0].value.stsTokenManager.expirationTime)) {
        //   logger.debug("TOKEN IP EXPIRED");
        //   signout();
        //   getAuthLoading = false;
        //   break;
        // }
        // Vérifie entre l'utilisateur enregistré et l'utilisateur reçu
        else if (isSameUser(payload)) {
          logger.debug("SAME USER");
          getAuthLoading = false;
          break;
        } else {
          // Si tout est bon, on enregistre l'user en base locale
          saveAuthOnDb(payload);

          // Parcours le tableau des tuples de la bd
          let saveUser = false;
          for (let value of payload.firebaseLocalStorage) {
            // Utilisateur non sauvegardé --> on continue
            if (!saveUser) {
              // Analyse si le tuple en cours est issue de la base firebase
              if (isValueContainsFirebaseKey(value)) {
                // Sauvegarde les données du tuple dans le session storage
                saveUserData(value.value);
                saveUser = true;
              }
            }
          }
          getAuthLoading = false;
          break;
        }

      // Méthode deleteAuth
      case _METHOD_DELETE:
        logger.debug("Méthode delete auth");

        getAuthLoading = false;
        signout();
        break;

      case _METHOD_SET:
        logger.debug("Méthode setAuth");

        getAuthLoading = false;

        break;
    }
  }
}

/** Analyse si la valeur passée en paramètre,
 * qui est un tuple de la base utilisateur firebase,
 * contient la clé de la base firebase.
 */
export function isValueContainsFirebaseKey(value) {
  let isOk = false;

  if (value) {
    if (value.fbase_key) {
      if (value.fbase_key.includes(_FIREBASE_KEY)) {
        isOk = true;
      }
    }
  }

  return isOk;
}

/** Vérifie l'expiration du token par rapoort à la date heure du moment */
export function tokenIsExpired(exp) {
  // Obtention de la date heure courante
  let now = Date.now();

  // Retourne un booléen true si exp est atteinte, false sinon.
  return now >= exp;
}

/**
 * Méthode de comparaison entre l'utilisateur connu et celui reçu.
 * @param {Object} datas Réprésente le tableau de données utilisateur reçu
 * @returns Boolean à true si l'utilisateur dans **datas** est celui enregistré en base
 */
export function isSameUser(datas) {
  let result = false;

  // Récupération de l'utilisateur connu
  let user = loginStoreService.getEmail();

  if (user) {
    // Récupère dans l'objet reçu uniquement les tuples dont l'utilisateur est connu
    let currentAuth = datas.firebaseLocalStorage.filter(
      (d) => d.value.email == user
    );

    // Des tuples sont présents, l'utilisateur est celui connecté
    if (currentAuth.length > 0) {
      loginStoreService.setConnected(true);
      result = true;
    }
  }

  return result;
}

/**
 * Indique que l'utilisateur est non connecté
 */
export function signout() {
  // Indique que l'utilisateur n'est plus connecté
  loginStoreService.setConnected(false);
  // Supprime le mail stocké
  loginStoreService.clearEmail();
  // Supprime le lien de la photo de l'utilisateur
  loginStoreService.clearPhotoUrl();
  // Supprime le token Ip stocké
  loginStoreService.clearToken();
  // Supprime le nom complet stocké
  loginStoreService.clearGivenName();
  // Supprime l'expiration de token ip stocké
  loginStoreService.clearExpiresTokenIp();
  // Indique que l'utlisateur n'est pas connecté au sso
  loginStoreService.setUserConnectedSso(false);
}

/**
 * Sauvegarde des datas user dans la database locale IndexedDb
 */

export function saveAuthOnDb(data) {
  let indexdb = null;
  try {
    // Récupération d'un objet d'ouverture de bd
    indexdb = window.indexedDB.open(_DB_NAME, 1);

    // Traitement lors d'un upgrade de bd
    indexdb.onupgradeneeded = () => {
      const db = indexdb.result;
      var objectStore = db.createObjectStore(_DB_OBJECT_NAME, {
        autoIncrement: true,
      });
      objectStore.transaction.oncomplete = function () {};
    };

    // Traitement quand la transaction d'ouverture bd est réalisée
    indexdb.onsuccess = () => {
      const db = indexdb.result;
      const tx = db.transaction(_DB_OBJECT_NAME, "readwrite");
      const store = tx.objectStore(_DB_OBJECT_NAME);
      // Vide le magasin d'objet
      var objectStoreRequest = store.clear();
      objectStoreRequest.onsuccess = function () {
        if (data.firebaseLocalStorage) {
          data.firebaseLocalStorage.forEach((element) => {
            store.add(element);
          });
        }
      };
    };
  } catch (error) {
    logger.error("Error on saveAuthOnDb", error);
  }
}

/**
 * Récupération des datas user depuis la database locale IndexedDB
 */
export async function getAuthOnDB() {
  return new Promise((resolve) => {
    // Ouverture de la Database locale
    const dbRequest = window.indexedDB.open(_DB_NAME);

    // callback dès que la database est ouverte
    dbRequest.onsuccess = () => {
      const database = dbRequest.result;

      // Déclaration d'une transaction
      const transac = database.transaction(_DB_OBJECT_NAME);

      // Requète l'ensemble de la table
      const req = transac.objectStore(_DB_OBJECT_NAME).getAll();
      // Dès que l'on as
      req.onsuccess = () => {
        let data = {};
        data[_DB_OBJECT_NAME] = req.result;
        resolve(data);
      };
    };
  });
}

/**
 * Sauvegarde les données utilisateur dans le local storage
 */
export function saveUserData(values) {
  // sauvegarde de l'email
  loginStoreService.setEmail(values.email);
  // sauvegarde du nom complet
  loginStoreService.setGivenName(values.displayName);
  // sauvegarde du token ip
  loginStoreService.setToken(values.stsTokenManager.accessToken);
  //  sauvegarde de l'expiration du token
  loginStoreService.setExpiresTokenIp(values.stsTokenManager.expirationTime);
  // suvegarde de l'url de la photo utilisateur
  loginStoreService.setPhotoUrl(values.photoURL);
  // Indique que l'utlisateur est connecté au sso
  loginStoreService.setUserConnectedSso(true);
}
