import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/activities",
  SUBACTIVITIES: "/subactivities",
  DOPS: "/collaborator-managers",
};

export default class ActivitiesApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }

  /**Retourne la liste des sous-activité d'une activité */
  async getSubactivitiesOfActivity(idActivity) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
        DEFINES.SLACH +
        idActivity.toString() +
        DEFINES.SUBACTIVITIES
    );
    return this.httpClient.get(url);
  }

  /** Retourne un tableau d'activités avec ses sous-activités associées */
  async getActivitiesWithSubactivities() {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.SUBACTIVITIES
    );
    return this.httpClient.get(url);
  }

  /** Ajout d'une sous-activité à une l'activité */
  async addSubActivityToActivity(idActivity, idSubactivity) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
        DEFINES.SLACH +
        idActivity.toString() +
        DEFINES.SUBACTIVITIES +
        DEFINES.SLACH +
        idSubactivity.toString()
    );
    return this.httpClient.post(url);
  }

  /** Suppression d'une sous-activité à une l'activité */
  async deleteSubActivityToActivity(idActivity, idSubactivity) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
        DEFINES.SLACH +
        idActivity.toString() +
        DEFINES.SUBACTIVITIES +
        DEFINES.SLACH +
        idSubactivity.toString()
    );
    return this.httpClient.delete(url);
  }


  /**Retourne la liste des dops d'une activité */
  async getDopsOfActivity(idActivity) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH + DEFINES.SLACH + idActivity.toString() + DEFINES.DOPS
    );
    return this.httpClient.get(url);
  }

  /** Ajout d'undop à une activité */
  async addDopToActivity(idActivity, dop) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH + DEFINES.SLACH + idActivity.toString() + DEFINES.DOPS
    );
    return this.httpClient.post(url, dop);
  }

  /** Suppression d'undop  d'une activité */
  async deleteDopToActivity(idActivity, idDop) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH + DEFINES.SLACH + idActivity.toString() 
      + DEFINES.DOPS + DEFINES.SLACH + idDop.toString()
    );
    return this.httpClient.delete(url);
  }
}
