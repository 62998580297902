import AcsApi from "./acs_api";
import AcsHttpClient from "./acs_http_client";

/** Construction du client Http de l'api du backend */
export function initHttpClient(apiKey, tokenFunction, apmTokenFunction) {
  //on instancie les clients http
  let httpclient = new AcsHttpClient();
  httpclient.getToken = tokenFunction;
  httpclient.getApmToken = apmTokenFunction;
  httpclient.api_key = apiKey;

  return httpclient;
}

/** Instanciation des apis */
export function initApi(httpclient) {
  return {
    acs: new AcsApi(httpclient),
  };
}
