
import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/conf/2020-INFSI-028/ticket/applications",
  SERVICE: "/services",
  OBJECTS: "/objects",
  SUBOBJECTS: "/subobjects",
  STATUS: "/ticketstatus",
};


export default class TicketingObjectSubobjectApi extends TemplateApi {

  constructor(httpClient) {
    super(httpClient, DEFINES.PATH);

    this.httpclient = httpClient;
  }


  /////////////////////////// GET ///////////////////////////

  /** Récupère la liste des applications de la conf */
  async getAllApps() {
    return this.getAll();
  }

  /** Récupère la liste des services d'une app */
  async getAllServicesFromApp(appId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.SLACH
      + appId.toString()
      + DEFINES.SERVICE
    );
    return this.httpclient.get(url);
  }

  /** Récupère tous les objets à partir d'un service */
  async getAllObjectsOfService(appId, serviceId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.SLACH
      + appId.toString()
      + DEFINES.SERVICE
      + DEFINES.SLACH
      + serviceId.toString()
      + DEFINES.OBJECTS
    );
    return this.httpclient.get(url);
  }

  /** Récupère tous les sous-objets reliés à un objet */
  async getAllSubobjects(appId, serviceId, objectId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
      DEFINES.SLACH +
      appId.toString() +
      DEFINES.SERVICE + 
      DEFINES.SLACH +
      serviceId.toString() +
      DEFINES.OBJECTS + 
      DEFINES.SLACH +
      objectId.toString() +
      DEFINES.SUBOBJECTS
    );
    return this.httpclient.get(url);
  }

  /**Récupère tous les statuts pour une application */
  async getAllStatusOfApplication(appId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.SLACH
      + appId.toString()
      + DEFINES.STATUS
    );
    return this.httpclient.get(url);
  }

  /** Récupère un objet par son id */
  async getObjectById(appId, serviceId, objectId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
      DEFINES.SLACH +
      appId.toString() +
      DEFINES.SERVICE + 
      DEFINES.SLACH +
      serviceId.toString() +
      DEFINES.OBJECTS + 
      DEFINES.SLACH +
      objectId.toString()
    );
    return this.httpclient.get(url);
  }


  ////////////////////////// CREATE //////////////////////////

  /** Ajout d'un objet */
  async createObject(appId, serviceId, object) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
      DEFINES.SLACH +
      appId.toString() +
      DEFINES.SERVICE +
      DEFINES.SLACH +
      serviceId.toString() +
      DEFINES.OBJECTS
    );
    return this.httpClient.post(url, object);
  }


  /** Ajout d'un sous-objet pour un objet */
  async createSubObject(appId, serviceId, objectId, subobject) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
      DEFINES.SLACH +
      appId.toString() +
      DEFINES.SERVICE +
      DEFINES.SLACH +
      serviceId.toString() +
      DEFINES.OBJECTS +
      DEFINES.SLACH +
      objectId.toString() +
      DEFINES.SUBOBJECTS
    );
    return this.httpClient.post(url, subobject);
  }

  /** Ajout d'un statut spécifique à une application */
  async createSpecificStatutToApp(appId, entity) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
      DEFINES.SLACH +
      appId.toString() +
      DEFINES.STATUS
    );
    return this.httpClient.post(url, entity);
  }

  /** Associe un statut à une application */
  async associateStatutToApp(appId, statusId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
      DEFINES.SLACH +
      appId.toString() +
      DEFINES.STATUS +
      DEFINES.SLACH +
      statusId.toString()
    );
    return this.httpClient.post(url);
  }


  ////////////////////////// UPDATE //////////////////////////

  /** Met à jour un objet */
  async updateObject(appId, serviceId, object) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
      DEFINES.SLACH +
      appId.toString() +
      DEFINES.SERVICE +
      DEFINES.SLACH +
      serviceId.toString() +
      DEFINES.OBJECTS +
      DEFINES.SLACH +
      object.id.toString()
    );
    return this.httpClient.put(url, object);
  }
  
  
  
  /** Met à jour un sous-objet pour un objet */
  async updateSubObject(appId, serviceId, objectId, subobject) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
      DEFINES.SLACH +
      appId.toString() +
      DEFINES.SERVICE +
      DEFINES.SLACH +
      serviceId.toString() +
      DEFINES.OBJECTS +
      DEFINES.SLACH +
      objectId.toString() +
      DEFINES.SUBOBJECTS + 
      DEFINES.SLACH +
      subobject.id.toString()
    );
    return this.httpClient.put(url, subobject);
  }


  ////////////////////////// DELETE //////////////////////////

  /** Suppression d'un objet */
  async deleteObject(appId, serviceId, objectId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
      DEFINES.SLACH +
      appId.toString() +
      DEFINES.SERVICE +
      DEFINES.SLACH +
      serviceId.toString() +
      DEFINES.OBJECTS +
      DEFINES.SLACH +
      objectId.toString()
    );
    return this.httpClient.delete(url);
  }


  /** Suppression d'un sous-objet ç un objet */
  async deleteSubobject(appId, serviceId, objectId, subobjectId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
      DEFINES.SLACH +
      appId.toString() +
      DEFINES.SERVICE +
      DEFINES.SLACH +
      serviceId.toString() +
      DEFINES.OBJECTS +
      DEFINES.SLACH +
      objectId.toString() +
      DEFINES.SUBOBJECTS + 
      DEFINES.SLACH +
      subobjectId.toString()
    );
    return this.httpClient.delete(url);
  }

  /** Suppression de l'association entre une application et un statut */
  async disassociateStatusOfApp(appId, statusId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.SLACH
      + appId.toString()
      + DEFINES.STATUS
      + DEFINES.SLACH
      + statusId.toString()
    );
    return this.httpclient.delete(url);
  }

}

