var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "12px",
        height: "12px",
        viewBox: "0 0 16 15",
        fill: "#1e1d1d",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: { d: "M13.712 4.8L7.7818 0L1.85156 4.8V15H13.712V4.8Z" },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M7.3241 0.161342C7.59341 -0.0537806 7.97346 -0.0537806 8.24277 0.161342L15.2849 5.78634C15.6063 6.04298 15.6611 6.51465 15.4075 6.8397C15.1537 7.16483 14.6876 7.22033 14.3663 6.96368L7.78347 1.70556L1.20063 6.96368C0.879311 7.22033 0.413173 7.16483 0.159492 6.8397C-0.0941884 6.51465 -0.0393486 6.04298 0.281981 5.78634L7.3241 0.161342Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }