<template>
  <div>    
    <iframe
    :id="id"
    :src="src"
    :style="style"
    @load="load"
    sandbox="allow-same-origin allow-scripts"
    >    
    </iframe>    
  </div>
</template>

<script >

import * as ssoService from "@/service/sso_service.js";
import * as logger from "@/tools/logger.js";


export default {
  name: "iframeSsoAuth",

  data() {
    return {
      id: ssoService._IFRAME_ID,
      src: ssoService._URL_IFRAME,
      style: {
        display: "none",
        visibility: "hidden",
      },
    };
  },

  methods: {
    /** Méthode de chargement  */
    load() {
      logger.error("Iframe load");
    },
  },

  mounted() {
    logger.error("Iframe mounted");
  },
}
</script>