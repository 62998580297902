var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { tile: "", flat: "" } },
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: { "mini-variant": _vm.showBurgerMenu, permanent: "" },
          model: {
            value: _vm.showBurgerMenu,
            callback: function ($$v) {
              _vm.showBurgerMenu = $$v
            },
            expression: "showBurgerMenu",
          },
        },
        [
          _c(
            "v-list",
            { attrs: { nav: "", dense: "" } },
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-icon",
                    {
                      attrs: { color: "secondary", icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.showBurgerMenu = !_vm.showBurgerMenu
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-menu")])],
                    1
                  ),
                  _c(
                    "v-list-item-title",
                    { staticClass: "text-h6 primary--text" },
                    [_vm._v("Database Globale")]
                  ),
                ],
                1
              ),
              _vm.isConnected
                ? _c(
                    "v-list-item",
                    { attrs: { to: _vm.routes.home } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("$vuetify.icons.iconHomeUser")])],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Accueil")]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.canViewConf
                ? _c(
                    "v-list-item",
                    { attrs: { to: _vm.routes.confAppsHome } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("$vuetify.icons.iconConf")])],
                        1
                      ),
                      _c("v-list-item-title", [
                        _vm._v("Configuration des apps"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.canViewAcs
                ? _c(
                    "v-list-item",
                    { attrs: { to: _vm.routes.confAcs } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("$vuetify.icons.iconSecurity")])],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("ACS")]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.canViewPractitioners
                ? _c(
                    "v-list-item",
                    { attrs: { to: _vm.routes.confPractitioners } },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", [
                            _vm._v("$vuetify.icons.iconPractitioner"),
                          ]),
                        ],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Praticiens")]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }