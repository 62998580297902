import DictionariesHttpClient from "@/api/dictionaries/dictionaries_http_client";
import DiseaseReasonsApi from "@/api/dictionaries/disease_reasons_api.js";
import WorkDurationReasonApi from "@/api/dictionaries/work_duration_reason_api.js";
import RefundTypesApi from "@/api/dictionaries/refund_types_api.js";
import WorkingTimeOrganisationApi from "@/api/dictionaries/working_time_organisation_api.js";
import ProspectOriginApi from "@/api/dictionaries/prospect_origin_api.js";
import ExpenseReportContextsApi from "@/api/dictionaries/expense_report_contexts_api";
import CddReasonsApi from "@/api/dictionaries/cdd_reasons_api";
import VacationReasonsApi from "@/api/dictionaries/vacation_reasons_api";
import AcronymsApi from "@/api/dictionaries/acronyms_api.js";
import ApplicationsApi from "@/api/dictionaries/applications_api.js";
import RomeJobsApi from "@//api/dictionaries/rome_jobs_api.js";
import InternalJobsApi from "@//api/dictionaries/internal_jobs_api.js";
import RomeHigthDomainsApi from "@//api/dictionaries/rome_hight_domains_api.js";
import PrimesApi from "@//api/dictionaries/primes_api.js";

/** Construction du client Http de l'api dictionnaire */
export function initHttpClient(apiKey, tokenFunction, apmTokenFunction) {
  //on instancie les clients http
  let httpclient = new DictionariesHttpClient();
  httpclient.getToken = tokenFunction;
  httpclient.getApmToken = apmTokenFunction;
  httpclient.api_key = apiKey;

  return httpclient;
}

/** Instanciation des api du silo Establishment */
export function initApi(httpClient) {
  return {
    diseaseReasons: new DiseaseReasonsApi(httpClient),
    workDurationReason: new WorkDurationReasonApi(httpClient),
    refundTypes: new RefundTypesApi(httpClient),
    workingTimeOrganisation: new WorkingTimeOrganisationApi(httpClient),
    prospectOrigin: new ProspectOriginApi(httpClient),
    expenseReportContext: new ExpenseReportContextsApi(httpClient),
    cddReasons: new CddReasonsApi(httpClient),
    vacationReasons: new VacationReasonsApi(httpClient),
    acronyms: new AcronymsApi(httpClient),
    applications: new ApplicationsApi(httpClient),
    romeJobs: new RomeJobsApi(httpClient),
    internalJobs: new InternalJobsApi(httpClient),
    romeHightDomains: new RomeHigthDomainsApi(httpClient),
    primes: new PrimesApi(httpClient),
  };
}
