import * as logger from "@/tools/logger.js";

import AcsService from "@/service/acs/acs_service.js";
import * as loginStoreService from "@/service/login_store_service.js";
import * as roleService from "@/service/role_service.js";
import * as ssoService from "@/service/sso_service.js";

import { getAuth, getIdToken, signOut } from "firebase/auth";

const _FILE_PATH = "service/login_service.js";
const _M_SIGNOUT = "signOut";
const _APM_TOKEN_EXPIRE_AT = "apm-token-expire-at";

/**
 * Vérifie l'état de connection de l'utilisateur
 * retourne true si l'utilisateur est connecté
 *
 * (Est appelé à chaque changement de page)
 */
export async function getConnexion() {
  // Demande d'obtention de l'authentification.
  await ssoService.getAuthSso();

  if (loginStoreService.getUserConnectedSso()) {
    // Demande de reniew du token si expiré
    await getExpirationAndReniewTokenIp();
  }
}

/** Crée la connexion de l'utilisateur */
export async function setConnection(user) {
  logger.debug("Login_service --> setConnection()");

  // Ajoute les infos de l'utilisateur à l'iframe
  await ssoService.setAuthSso(user);
}

/**
 * Vérifie l'expiration du token et en redemande un si c'est le cas.
 */
export async function getExpirationAndReniewTokenIp() {
  //logger.debug("Get expiration of token IP and reniew it");

  let date = new Date(Date.now());
  // On ajoute 5 minutes pour régénérer le token avant son expiration
  date.setMinutes(date.getMinutes() + 5);
  // Récupération de l'expiration du token
  let expire = loginStoreService.getExpiresTokenIp();

  // La date du moment - 5 mins est plus grande que l'expiration --> le token va expiré
  if (date.valueOf() >= expire) {
    //logger.debug("Token is expire --> reniew it");
    const auth = getAuth();

    // Récupération du user firebase
    let user = await auth.currentUser;

    // Utilisateur chargé, on demande un nouveau token
    if (user) {
      // Obtention du token avec refresh forcé
      await getIdToken(user, true);

      // Récupération des infos du user
      user = auth.currentUser;

      // Met à jour le token et l'expiration dans le local storage
      loginStoreService.setToken(user.stsTokenManager.accessToken);
      loginStoreService.setExpiresTokenIp(user.stsTokenManager.expirationTime);
    }
  }
}

/** Demande de connexion à acs */
export async function connectAcs(api) {
  logger.debug("login_service --> connectAcs");
  // Instanciation du service acs
  let serviceAcs = new AcsService(api);
  // appel vers ac pour récupérer le token des droits du collaborateur
  let acsToken = await serviceAcs.getRolesForUser();
  // Dans le cas où il y a eu un problème de récupération, on génère une erreur.
  if (!acsToken) {
    throw new Error("Erreur lors de la récupération du token acs");
  }
  // Sauvegarde du token acs et son expiration
  roleService.saveAcs(acsToken);
}

/** Termine le workflow de connexion  */
export function endConnection() {
  // Enregistre le fait que l'utilisateur est connecté
  loginStoreService.setConnected(true);
  // Enregistre que la page de chargement de connexion est terminée
  loginStoreService.setConnectionLoaded(true);
}

/** Analyse la réponse sur l'état de connexion  */
export async function waitConnection() {
  return new Promise((resolve) => {
    let interval = setInterval(() => {
      if (!ssoService.getAuthLoading) {
        console.error("end of load interval");
        clearInterval(interval);

        // Récupère l'état de connexion de l'utilisateur
        let con = loginStoreService.getConnected();

        if (con == "true") {
          resolve(true);
        } else {
          resolve(false);
        }
      }
    }, 500);
  });
}

/**
 * Deconnexion de l'utilisateur
 */
export async function disconnectUser() {
  // Lancement de la suppression auprès du sso
  await ssoService.deleteAuth();

  // Suppression du reste de la connexion
  signOutUser();

  // Attente de la réponse du sso
  return new Promise((resolve) => {
    // Demande au sso quand la réponse est arrivée
    let interval = setInterval(() => {
      if (!ssoService.getAuthLoading) {
        clearInterval(interval);
        resolve();
      }
    }, 500);
  });
}

/**
 * Déconnecte le compte utilisateur du site
 */
export function signOutUser() {
  logger.debugFull(_FILE_PATH, _M_SIGNOUT, "Deconnexion user.");

  loginStoreService.clearTokenAcs();
  loginStoreService.clearExpiresTokenAcs();
  loginStoreService.clearAcsRoles();
  loginStoreService.clearAppRoles();
  loginStoreService.setCanEditData(false);
  loginStoreService.setConnected(false);

  const auth = getAuth();
  signOut(auth);
}

/** Recharge le token acs */
export async function sessionAcsHasExpired() {
  let now = Date.now();
  // Récupération de la validité du token acs
  let expireTokenAcs = sessionStorage.getItem(_APM_TOKEN_EXPIRE_AT);

  if (now >= expireTokenAcs) {
    await roleService.apmTokenReniew();
  }
}
