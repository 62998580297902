import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/consents",
}

export default class ConsentApi extends TemplateApi {
  constructor(httpClient) {
    super(httpClient, DEFINES.PATH);

    this.httpclient = httpClient;
  }


  /**
   * mettre à jour un consentement
   */
   async updateConsent(entity) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH 
      + DEFINES.SLACH 
      + entity._id.toString()
    );
    return this.httpclient.put(url, entity);
  }
}