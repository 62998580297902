import CustomersHttpClient from "@/api/customers/customers_http_client";
import ConsentApi from "@/api/customers/consents_api.js"


/** Construction du client Http de l'api customer */
export function initHttpClient(apiKey, tokenFunction, apmTokenFunction) {
  // On instancie les clients http
  let httpclient = new CustomersHttpClient();
  httpclient.getToken = tokenFunction;
  httpclient.getApmToken = apmTokenFunction;
  httpclient.api_key = apiKey;

  return httpclient;
}



/** Instanciation des apis du silo Customers */
export function initApi(httpClient) {
  return {
    consents: new ConsentApi(httpClient),
  }
}