const DEFINES = {
  SLACH: "/",
  PATH: "/legalstructures",
  EXTERNAL_CONTRACTS: "/externalcontracts",
  VIEW: "/view",
};

export default class LegalStructureExternalContractsApi {
  constructor(httpclient) {
    this.httpclient = httpclient;
  }

  /**retourne la liste des contracts externes de la structure juridique */
  async getView(legalStructureId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
        DEFINES.SLACH +
        legalStructureId +
        DEFINES.EXTERNAL_CONTRACTS +
        DEFINES.VIEW
    );
    return this.httpclient.get(url);
  }

  /** Ajouter un contrat à une structure juridique */
  async addContract(legalStructureId, contractTypeId, contractContactId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
        DEFINES.SLACH +
        legalStructureId +
        DEFINES.EXTERNAL_CONTRACTS
    );

    let payload = {
      legalStructureId,
      contractTypeId,
      contractContactId,
    };

    return this.httpclient.post(url, payload);
  }

  /**Supprimer un contrat sur la structure juridique. itemId est l'identifiant de la relation : structure -> contrat */
  async removeContract(legalStructureId, itemId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
        DEFINES.SLACH +
        legalStructureId +
        DEFINES.EXTERNAL_CONTRACTS +
        DEFINES.SLACH +
        itemId
    );

    return this.httpclient.delete(url, {});
  }

  async updateContract(
    legalStructureId,
    contractId,
    contractTypeId,
    contractContactId
  ) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
        DEFINES.SLACH +
        legalStructureId +
        DEFINES.EXTERNAL_CONTRACTS +
        DEFINES.SLACH +
        contractId
    );

    let payload = {
      id: contractId,
      legalStructureId,
      contractTypeId,
      contractContactId,
    };

    return this.httpclient.put(url, payload);
  }
}
