
import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/conf/2020-RH-009",
  USER_TYPES: "/usertypes",
  WORK_RELATIONSHIP_TYPE: "/workrelationshiptypes",
};


export default class ConfWorksContractApi extends TemplateApi {

  constructor(httpClient) {
    super(httpClient, DEFINES.PATH);

    this.httpClient = httpClient;
  }

  /** Récupère le tableau des types d'utilisateurs */
  async getListUserTypes() {
    let url = this.httpClient.buildUrl(
      DEFINES.PATH
      + DEFINES.USER_TYPES
    );
    return this.httpClient.get(url);
  }

  
  /** Met à jour le tableau des types d'utilisateurs */
  async updateListUserTypes(entities) {
    let url = this.httpClient.buildUrl(
      DEFINES.PATH
      + DEFINES.USER_TYPES
    );
    return this.httpClient.put(url, entities);
  }


  /** Récupère le tableau des types de relation de travail */
  async getListWorkRelationshipTypes() {
    let url = this.httpClient.buildUrl(
      DEFINES.PATH
      + DEFINES.WORK_RELATIONSHIP_TYPE
    );
    return this.httpClient.get(url);
  }

  
  /** Met à jour le tableau des types de relation de travail */
  async updateListWorkRelationshipTypes(entities) {
    let url = this.httpClient.buildUrl(
      DEFINES.PATH
      + DEFINES.WORK_RELATIONSHIP_TYPE
    );
    return this.httpClient.put(url, entities);
  }
}

