import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import moduleLogin from "./login_store.js";

var store = new Vuex.Store({
  modules: {
    login: moduleLogin,
  },
});

export default store;
