import axios from "axios";
import BaseApi from "../base_api";

/**
 * Client http des appels api concernant les praticiens
 */
export default class PractionersHttpClient extends BaseApi {
  constructor(
    host = process.env.VUE_APP_URL_API_PRACTITIONER,
    timeout = 10000
  ) {
    super();

    this.host = host;
    this.rootUrl = "";
    this.http = axios.create({
      timeout,
    });

    // Configuration des listeners
    this.setInterceptor();
  }
}