/**
 * Template de base pour les APIs. Expose les méthodes suivantes :
 * - getAll
 * - getById
 * - create
 * - update
 * - delete
 */
export default class TemplateApi {
  constructor(httpClient, path) {
    this.httpClient = httpClient;
    this.path = path;
  }

  /**retourne toutes les éléments */
  async getAll() {
    let url = this.httpclient.buildUrl(this.path);
    return this.httpclient.get(url);
  }

  /**retourne un élément*/
  async getById(entityId) {
    let url = this.httpclient.buildUrl(this.path) + "/" + entityId.toString();
    return this.httpclient.get(url);
  }

  /**
   * Ajouter
   * */
  async create(entity) {
    let url = this.httpclient.buildUrl(this.path);
    return this.httpclient.post(url, entity);
  }

  /**
   * Mettre à jour
   * */
  async update(entity) {
    let url = this.httpclient.buildUrl(this.path + "/" + entity.id.toString());
    return this.httpclient.put(url, entity);
  }

  /**
   * Supprimer
   * */
  async delete(entityId) {
    let url = this.httpclient.buildUrl(this.path + "/" + entityId.toString());
    return this.httpclient.delete(url, {});
  }
}
