export let RolesApmEnum = {
  ORG_ADMIN: "ORG_ADMIN",
  ORG_READ: "ORG_READ",

  CONF_ADMIN: "CONF_ADMIN",
  CONF_READ: "CONF_READ",

  ENUM_ADMIN: "ENUM_ADMIN",
  ENUM_READ: "ENUM_READ",

  ESTAB_ADMIN: "ESTAB_ADMIN",
  ESTAB_EDIT: "ESTAB_EDIT",
  ESTAB_READ: "ESTAB_READ",

  JURIS_ADMIN: "JURIS_ADMIN",
  JURIS_READ: "JURIS_READ",

  USER_ADMIN: "USER_ADMIN",
  USER_READ: "USER_READ",

  PRACT_ADMIN: "PRACT_ADMIN",
  PRACT_EDIT: "PRACT_EDIT",
  PRACT_READ: "PRACT_READ",

  PRODUCT_ADMIN: "PRODUCT_ADMIN",
  PRODUCT_EDIT: "PRODUCT_EDIT",
  PRODUCT_READ: "PRODUCT_READ",

  ACS_ADMIN_EDIT: "ACS_ADMIN_EDIT",
  ACS_ADMIN_READ: "ACS_ADMIN_READ",

  CRM_READ: "CRM_READ",
  CRM_ADMIN: "CRM_ADMIN",

  APP_CONF: "APP_CONF",
};

/** Charge tous l'héritage des rôles de la liste */
export function allRoles() {
  let buildHeritage = buildApmRolesHeritage();

  return buildHeritage;
}

/**
 * Description des rôles que contiennent chacuns des rôles APM
 * La description de chaque rôle se fait de manière hiérarchique descendante (parent contient ses enfants)
 */
export function buildApmRolesHeritage() {
  let roles = [];

  // ORG_ADMIN
  roles.push({
    name: RolesApmEnum.ORG_ADMIN,
    roles: [RolesApmEnum.ORG_ADMIN, RolesApmEnum.ORG_READ],
  });

  // ORG_READ
  roles.push({
    name: RolesApmEnum.ORG_READ,
    roles: [RolesApmEnum.ORG_READ],
  });

  // CONF_ADMIN
  roles.push({
    name: RolesApmEnum.CONF_ADMIN,
    roles: [RolesApmEnum.CONF_ADMIN, RolesApmEnum.CONF_READ],
  });

  // CONF_READ
  roles.push({
    name: RolesApmEnum.CONF_READ,
    roles: [RolesApmEnum.CONF_READ],
  });

  // ENUM_ADMIN
  roles.push({
    name: RolesApmEnum.ENUM_ADMIN,
    roles: [RolesApmEnum.ENUM_ADMIN, RolesApmEnum.ENUM_READ],
  });

  // ENUM_READ
  roles.push({
    name: RolesApmEnum.ENUM_READ,
    roles: [RolesApmEnum.ENUM_READ],
  });

  // ESTAB_ADMIN
  roles.push({
    name: RolesApmEnum.ESTAB_ADMIN,
    roles: [
      RolesApmEnum.ESTAB_ADMIN,
      RolesApmEnum.ESTAB_EDIT,
      RolesApmEnum.ESTAB_READ,
    ],
  });

  // ESTAB_EDIT
  roles.push({
    name: RolesApmEnum.ESTAB_EDIT,
    roles: [RolesApmEnum.ESTAB_EDIT, RolesApmEnum.ESTAB_READ],
  });

  // ESTAB_READ
  roles.push({
    name: RolesApmEnum.ESTAB_READ,
    roles: [RolesApmEnum.ESTAB_READ],
  });

  // JURIS_ADMIN
  roles.push({
    name: RolesApmEnum.JURIS_ADMIN,
    roles: [RolesApmEnum.JURIS_ADMIN, RolesApmEnum.JURIS_READ],
  });

  // JURIS_READ
  roles.push({
    name: RolesApmEnum.JURIS_READ,
    roles: [RolesApmEnum.JURIS_READ],
  });

  // USER_ADMIN
  roles.push({
    name: RolesApmEnum.USER_ADMIN,
    roles: [RolesApmEnum.USER_ADMIN, RolesApmEnum.USER_READ],
  });

  // USER_READ
  roles.push({
    name: RolesApmEnum.USER_READ,
    roles: [RolesApmEnum.USER_READ],
  });

  // PRACT_READ
  roles.push({
    name: RolesApmEnum.PRACT_READ,
    roles: [RolesApmEnum.PRACT_READ],
  });
  // PRACT_EDIT
  roles.push({
    name: RolesApmEnum.PRACT_EDIT,
    roles: [RolesApmEnum.PRACT_READ, RolesApmEnum.PRACT_EDIT],
  });
  // PRACT_ADMIN
  roles.push({
    name: RolesApmEnum.PRACT_ADMIN,
    roles: [
      RolesApmEnum.PRACT_READ,
      RolesApmEnum.PRACT_EDIT,
      RolesApmEnum.PRACT_ADMIN,
    ],
  });

  // PRODUCT_READ
  roles.push({
    name: RolesApmEnum.PRODUCT_READ,
    roles: [RolesApmEnum.PRODUCT_READ],
  });
  // PRODUCT_EDIT
  roles.push({
    name: RolesApmEnum.PRODUCT_EDIT,
    roles: [RolesApmEnum.PRODUCT_READ, RolesApmEnum.PRODUCT_EDIT],
  });
  // PRODUCT_ADMIN
  roles.push({
    name: RolesApmEnum.PRODUCT_ADMIN,
    roles: [
      RolesApmEnum.PRODUCT_READ,
      RolesApmEnum.PRODUCT_EDIT,
      RolesApmEnum.PRODUCT_ADMIN,
    ],
  });

  // ACS_READ
  roles.push({
    name: RolesApmEnum.ACS_ADMIN_READ,
    roles: [RolesApmEnum.ACS_ADMIN_READ],
  });

  // ACS_ADMIN
  roles.push({
    name: RolesApmEnum.ACS_ADMIN_EDIT,
    roles: [RolesApmEnum.ACS_ADMIN_EDIT, RolesApmEnum.ACS_ADMIN_READ],
  });

  // CRM_ADMIN
  roles.push({
    name: RolesApmEnum.CRM_ADMIN,
    roles: [RolesApmEnum.CRM_ADMIN, RolesApmEnum.CRM_READ],
  });

  // APP_CONF
  roles.push({
    name: RolesApmEnum.APP_CONF,
    roles: [RolesApmEnum.APP_CONF],
  });

  return roles;
}
