var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "29",
        height: "21",
        viewBox: "0 0 29 21",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M24.3856 0.873718L4.63462 3.39978C2.54614 3.65835 0.855469 5.56781 0.855469 7.65629V16.0699C0.855469 18.1584 2.54614 19.6302 4.63462 19.3717L24.3856 16.8456C26.4741 16.587 28.1648 14.6776 28.1648 12.5891V4.1755C28.1648 2.08702 26.4741 0.595254 24.3856 0.873718Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M4.13717 20.2469C3.10288 20.2469 2.14815 19.8888 1.39232 19.2325C0.497257 18.4368 0 17.323 0 16.0699V7.65632C0 5.17004 2.02881 2.88266 4.51509 2.56442L24.2661 0.0383539C25.5192 -0.120768 26.7126 0.217365 27.6077 1.01298C28.5027 1.80859 29 2.92244 29 4.17553V12.5891C29 15.0754 26.9712 17.3628 24.4849 17.681L4.73388 20.2071C4.53498 20.227 4.33608 20.2469 4.13717 20.2469ZM24.4849 1.70913L4.73388 4.2352C3.08299 4.45399 1.69067 6.00543 1.69067 7.65632V16.0699C1.69067 16.8257 1.96913 17.502 2.50617 17.9595C3.04321 18.4368 3.75926 18.6357 4.51509 18.5363L24.2661 16.0102C25.917 15.7914 27.3093 14.24 27.3093 12.5891V4.17553C27.3093 3.4197 27.0309 2.74343 26.4938 2.28595C25.9767 1.80858 25.2606 1.60968 24.4849 1.70913Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M24.3859 15.4534L4.63488 17.9794C3.32212 18.1386 2.24805 17.2236 2.24805 15.891V7.47737C2.24805 6.16461 3.32212 4.95131 4.63488 4.79218L24.3859 2.26613C25.6987 2.107 26.7727 3.02196 26.7727 4.3546V12.7682C26.7727 14.0809 25.6987 15.2942 24.3859 15.4534Z",
          fill: "#E20080",
        },
      }),
      _c("path", {
        attrs: {
          d: "M4.83398 7.47716L6.2263 7.29815L8.97116 11.4751L8.85181 6.96002L10.2441 6.78101L10.3834 14.2399L9.07061 14.399L6.2263 10.0828L6.34565 14.757L4.95332 14.936L4.83398 7.47716Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M11.7349 14.0608L11.5957 6.60196L16.1307 6.00525L16.1506 7.5368L13.0875 7.93461L13.1074 9.34681L15.2754 9.06835L15.2953 10.5601L13.1272 10.8386L13.1471 12.3303L16.25 11.9325L16.2699 13.4641L11.7349 14.0608Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M19.871 13.0066L18.3195 13.2055L16.8477 5.9058L18.3593 5.7069L19.1748 10.2419L20.1295 5.46822L21.502 5.28921L22.6357 9.78441L23.2722 5.05053L24.7441 4.85162L23.5507 12.4895L21.9992 12.6884L20.8456 8.01417L19.871 13.0066Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }