export default class AcsService {
  constructor(api) {
    this.api = api;
  }

  /** Retourne un token définissant les rôles d'un collaborateur. */
  async getRolesForUser() {
    const result = await this.api.getRolesForUser();
    return result.data.token;
  }

  /**
   * A partir de la liste des rôles de référence et d'une liste de noms de rôles : retourne la liste de
   * noms de rôles complétée avec l'héritage.
   */
  buildInheritance(inheritance, roleNames) {
    let roles = [];

    for (let roleName of roleNames) {
      let target = inheritance.find((r) => r.name === roleName);

      let role = {};

      role.name = roleName;
      role.herited = false;

      roles.push(role);

      //on rajoute les rôles hérités
      for (let granted of target.grant) {
        if (
          !roleNames.includes(granted) &&
          !roles.find((r) => r.name === granted)
        ) {
          role = {};

          role.name = granted;
          role.herited = true;

          roles.push(role);
        }
      }
    }

    return roles;
  }
}
