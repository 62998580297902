import axios from "axios";
import BaseApi, { ApiTypeEnum } from "../base_api";

/**
 * client http des appels api concernant les configurations.
 */
export default class AcsHttpClient extends BaseApi {
  constructor(host = process.env.VUE_APP_URL_API_ACS, timeout = 5 * 60 * 1000) {
    super(ApiTypeEnum.Acs);

    // process.env récupère les datas se trouvant dans le fichier .env."dev"
    this.host = host;
    this.rootUrl = "";
    this.http = axios.create({
      timeout,
    });

    // Configuration d'un écouteurs qui configure chaque requête avec le token dans le header
    this.setInterceptor();
  }
}
