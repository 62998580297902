
import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/conf/2020-INFSI-026/launcher/establishments",
  BUSINESS_APP_LINK: "/businessapplicationlink",
  BUSINESS_APP_LOCAL: "/businessapplicationlocal",
};


export default class ConfEstablishmentBusinessApplicationApi extends TemplateApi {

  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }


  //////////////////////// /!\ /////////////////////
  // Sauf pour le getAll, le template ne peut fonctionner.
  // Le template prend en paramètre un "id"
  // et l'objet qui transite ici a en paramètre "establishmentId".

  /** Obtention des webApps pour un établissement */
  async getAllBusinessAppLink(establishmentId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.SLACH
      + establishmentId.toString()
      + DEFINES.BUSINESS_APP_LINK
    );
    return this.httpclient.get(url);
  }


  /** Obtention des localApps pour un établissement */
  async getAllBusinessAppLocal(establishmentId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.SLACH
      + establishmentId.toString()
      + DEFINES.BUSINESS_APP_LOCAL
    );
    return this.httpclient.get(url);
  }


}

