var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
        fill: "#fff",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M11.108 3.843 7.835 6.959c-.178.17-.37.325-.575.462l-.182.123a.55.55 0 0 0 0 .912l.182.123a4.4 4.4 0 0 1 .575.462l3.273 3.116a.754.754 0 0 1 0 1.112.86.86 0 0 1-1.168 0L4.992 8.557a.753.753 0 0 1 0-1.112L9.94 2.73a.861.861 0 0 1 1.168 0 .754.754 0 0 1 0 1.112Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }