import TemplateApi from "../../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/conf/2020-INFSI-028/customercells",
  ITEMS: "/items",
};

export default class CustomerCellsApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }

  /** Création d'un périmètre à la cellule client */
  async addPerimeter(customerCellsId, entity) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.SLACH
      + customerCellsId.toString()
      + DEFINES.SLACH
      + DEFINES.ITEMS
    );
    return this.httpClient.post(url, entity);
  }

  /** Mise à jour d'un périmètre à la cellule client */
  async updatePerimeter(customerCellsId, perimeterId, entity) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.SLACH
      + customerCellsId.toString()
      + DEFINES.ITEMS
      + DEFINES.SLACH
      + perimeterId.toString()
    );
    return this.httpClient.put(url, entity);
  }

  /** Suppression d'un périmètre à la cellule client */
  async deletePerimeter(customerCellsId, perimeterId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.SLACH
      + customerCellsId.toString()
      + DEFINES.ITEMS
      + DEFINES.SLACH
      + perimeterId.toString()
    );
    return this.httpClient.delete(url);
  }
}
