import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/priceunits",
};

export default class PriceUnitsApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }
}
