import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/legalstructures",
  ESTABLISHMENTS: "/establishments"
};

export default class LegalStructuresApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }

  /** Retourne l'ensemble des établissement rattachée à la structure juridique */
  async getAllAttachedEstablishment(id) {
    let url = this.httpclient.buildUrl(DEFINES.ESTABLISHMENTS + DEFINES.PATH + DEFINES.SLACH + id);
    return this.httpclient.get(url);
  }
}
