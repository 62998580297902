/**
 * Classe fournissant les écritures de log.
 * Confirée par le mode dans lequel l'app est lancée.
 * Ex: en debug les log sont pris en comptent, en mode prod non.
 */

/**
 * Affiche du log de debug si actif
 * @param {String} text Le texte à afficher dans le log.
 */
export function debug(text, objectJson = null) {
  debugFull("", "", text, objectJson);
}

/**
 * Affiche un chemin de log si actif
 * @param {String} className Le nom de la classe dans lequel le log est appellé.
 * @param {String} methodName Le nom de la méthode dans lequel le log est appellé.
 * @param {String} text Le texte à afficher dans le log.
 */
export function debugFull(
  className = "",
  methodName = "",
  text = "",
  objectJson = null
) {
  if (getIsViewLogDebug()) {
    let texte = "";

    if (className != "") {
      texte = className + " --> ";
    }
    if (methodName != "") {
      texte = texte + methodName + "() --> ";
    }
    texte = texte + text;

    if (objectJson) {
      texte = texte + "   " + JSON.stringify(objectJson, null, 4);
    }

    console.log("DEBUG : " + obtainDate() + "   " + texte);
  }
}

/**
 * Affiche du log d'erreur si actif.
 * @param {String} text Le texte à afficher dans le log.
 */
export function error(text, objectJson = null) {
  errorFull("", "", text, objectJson);
}

/**
 * Affiche un chemin de log d'erreur si actif
 * @param {String} className Le nom de la classe dans lequel le log est appellé.
 * @param {String} methodName Le nom de la méthode dans lequel le log est appellé.
 * @param {String} text Le texte à afficher dans le log.
 */
export function errorFull(
  className = "",
  methodName = "",
  text = "",
  objectJson = null
) {
  if (getIsViewLogError()) {
    let texte = "";

    if (className != "") {
      texte = className + " --> ";
    }
    if (methodName != "") {
      texte = texte + methodName + "() --> ";
    }
    texte = texte + text;

    if (objectJson) {
      texte = texte + "   " + JSON.stringify(objectJson, null, 4);
    }

    console.error("ERROR : " + obtainDate() + "   " + texte);
  }
}

/** Obtient la valeur d'affichage du debug dans les variables de conf */
function getIsViewLogDebug(enableDebug = process.env.VUE_APP_LOGGER_DEBUG) {
  let enable = false;

  if (typeof enableDebug === "string") {
    enable = enableDebug.toLowerCase() === "true";
  } else if (typeof enableDebug === "boolean") {
    enable = enableDebug;
  }

  return enable;
  //   return process.env.VUE_APP_LOGGER_DEBUG.toLowerCase() === "true"
  //     ? true
  //     : false;
}

/** Obtient la valeur d'affichage du log d'erreur dans les variables de conf */
function getIsViewLogError(enableError = process.env.VUE_APP_LOGGER_ERROR) {
  let enable = false;

  if (typeof enableError === "string") {
    enable = enableError.toLowerCase() === "true";
  } else if (typeof enableError === "boolean") {
    enable = enableError;
  }

  return enable;
  //   return process.env.VUE_APP_LOGGER_ERROR.toLowerCase() === "true"
  //     ? true
  //     : false;
}

/** Obtient la date actuelle au format dd-mm-aaaa   hh:mm:ss */
function obtainDate() {
  var date = new Date();
  var fullDate =
    date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
  var fullTime =
    date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() + ":" + date.getMilliseconds();

  return fullDate + " " + fullTime;
}


export let logPile = [];
/** stocke les logs */
export function addLogPile(text, objectJson = null) {

  let date = obtainDate();

  logPile.push({
    text: date + " " + text,
    object: objectJson,
  });
}

export function writeLogPile() {
console.error("WRITE LOG PILE  :");
for (let t of logPile) {
  debug(t.text, t.object);
}
console.error("END WRITE LOG PILE");

  logPile = [];
}