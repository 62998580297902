var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M11.8667 6.10577C12.9966 6.10577 13.9155 6.99391 13.9155 8.08596C13.9155 8.95326 13.3363 9.69118 12.5327 9.95888V11.825C12.5327 13.5759 11.0594 15 9.2479 15C7.4366 15 5.96344 13.5759 5.96344 11.825V7.95595C4.27931 7.65268 3 6.22292 3 4.50811V1.64373C3 1.28823 3.2981 1 3.66599 1H5.14771C5.51547 1 5.81363 1.28823 5.81363 1.64373C5.81363 1.99924 5.51545 2.28745 5.14764 2.28745H4.33191V4.50811C4.33191 5.73232 5.36273 6.72859 6.62936 6.72859C7.89585 6.72859 8.9265 5.73235 8.9265 4.50811V2.28745H8.11114C7.74334 2.28745 7.44516 1.99924 7.44516 1.64373C7.44516 1.28821 7.74334 1 8.11114 1H9.59249C9.96029 1 10.2585 1.28821 10.2585 1.64373V4.50811C10.2585 6.22296 8.97937 7.65267 7.29535 7.95595V11.825C7.29535 12.8657 8.17145 13.7125 9.24784 13.7125C10.3245 13.7125 11.2007 12.8656 11.2007 11.825V9.95888C10.3971 9.69118 9.81784 8.95327 9.81784 8.08596C9.81784 6.99384 10.7368 6.10577 11.8667 6.10577Z",
          fill: "#1E1D1D",
        },
      }),
      _c("ellipse", {
        attrs: {
          cx: "11.8649",
          cy: "8.07778",
          rx: "1.00584",
          ry: "0.972222",
          fill: "#E20080",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }