import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/legalstructures",
  STOCKHOLDERS: "/stockholders",
  VIEW: "/view",
};

export default class LegalStructureStockholderApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }

  /**
   * retourne la liste des actionnaires de la structure juridique. Les actionnaires sont directement nommés. Pas
   * besoin de faire des jointures à la main avec les relations
   * @param {*} legalStructureId
   */
  async getViewByLegalStructure(legalStructureId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
        DEFINES.SLACH +
        legalStructureId +
        DEFINES.STOCKHOLDERS +
        DEFINES.VIEW
    );
    return this.httpclient.get(url);
  }

  /**Ajouter un actionnaire sur la structure juridique */
  async addStockholderToStructure(legalStructureId, stockholderId, percent) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH + DEFINES.SLACH + legalStructureId + DEFINES.STOCKHOLDERS
    );

    let payload = {
      legalStructureId: legalStructureId,
      stockholderId: stockholderId,
      percent: percent,
    };

    return this.httpclient.post(url, payload);
  }

  /**Supprimer un actionnaire sur la structure juridique. itemId est l'identifiant de la relation : structure -> actionnaire */
  async removeStockholderFromStructure(legalStructureId, itemId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
        DEFINES.SLACH +
        legalStructureId +
        DEFINES.STOCKHOLDERS +
        DEFINES.SLACH +
        itemId
    );

    return this.httpclient.delete(url, {});
  }

  /**Modifier une relation actionnaire sur la structure juridique */
  async updateStockholderForStructure(
    legalStructureId,
    itemId,
    stockholderId,
    percent
  ) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
        DEFINES.SLACH +
        legalStructureId +
        DEFINES.STOCKHOLDERS +
        DEFINES.SLACH +
        itemId
    );

    let payload = {
      id: itemId,
      legalStructureId: legalStructureId,
      stockholderId: stockholderId,
      percent: percent,
    };

    return this.httpclient.put(url, payload);
  }
}
