import TemplateApi from "../template_api";

const DEFINES = {
  PATH: "/establishments",
  JOB_OFFER_RECIPIENTS: "/jobofferrecipients",
};

export default class JobOfferRecipientApi extends TemplateApi {
    constructor(httpclient) {
        super(httpclient, DEFINES.PATH);

        this.httpclient = httpclient;
    }

  /** Renvoie la liste des destinataires d'offre d'emplois pour un établissement donné. */
  async getJobOfferRecipientsByEstablishment(establishmentId) {
    const url = this.httpclient.buildUrl(
      DEFINES.PATH +
      "/" +
      establishmentId.toString() +
      DEFINES.JOB_OFFER_RECIPIENTS
    );
    return this.httpclient.get(url);
  }

  /** Mettre à jour la liste des destinataires d'offre d'emplois pour un établissement donné. */
  async updateEntities(establishmentId, entities) {
    const url = this.httpclient.buildUrl(
      DEFINES.PATH +
      "/" +
      establishmentId.toString() +
      DEFINES.JOB_OFFER_RECIPIENTS
    );

    return this.httpclient.put(url, entities);
  }

}
