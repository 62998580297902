
import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/conf/2020-INFSI-026/subactivities",
};


export default class ConfAvecSubactivityApi extends TemplateApi {

  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }


  //////////////////////// /!\ /////////////////////
  // Sauf pour le getAll, le template ne peut fonctionner.
  // Le template prend en paramètre un "id"
  // et l'objet qui transite ici a en paramètre "subactivityId".


  /** Création d'un objet en base */
  async createConf(entity) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
    );
    return this.httpClient.post(url, entity);
  }


  /** Met à jour un objet */
  async updateConf(entity) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
      DEFINES.SLACH +
      entity.subactivityId.toString()
    );
    return this.httpClient.put(url, entity);
  }


  /** Supprime un objet */
  async deleteConf(entityId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
      DEFINES.SLACH +
      entityId.toString()
    );
    return this.httpclient.delete(url);
  }


}

