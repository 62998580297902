import ConfHttpClient from "@/api/conf/conf_http_client.js";
import ConfConsulterModifierApi from "@/api/conf/app_consulter_modifier_api.js";
import ConfAppWorksContractApi from "@/api/conf/app_works_contract_api.js";
import ConfAvecEstablishmentApi from "@/api/conf/app_avec_establishment_api.js";
import ConfAvecSuperEstablishmentApi from "@/api/conf/app_avec_super_establishment_api.js";
import ConfAvecSubactivityApi from "@/api/conf/app_avec_subactivity_api.js";
import ConfAvecIndicatorsApi from "@/api/conf/app_avec_indicators_api.js";
import ConfEstablishmentBusinessApplicationApi from "@/api/conf/conf_establishment_business_application_api.js"

import EmergencyLevelTicketingApi from "@/api/conf/emergency_level_ticketing_api.js";
import EmergencyLevelTransmissionApi from "@/api/conf/emergency_level_transmission_api.js";
import TicketingPerimeterApi from "@/api/conf/ticketing_perimeter_api.js";
import TicketingRecipientTypeApi from "@/api/conf/ticketing_recipient_type_api.js";
import TicketingStatusApi from "@/api/conf/ticketing_status_api.js";
import TicketingObjectSubobjectApi from "@/api/conf/ticketing_object_subobject_api.js";

import EcoSystemsApi from "@/api/conf/transmission/ecosystems_api.js";
import MediumsApi from "@/api/conf/transmission/mediums_api.js";
import OtherRecipientTypesApi from "@/api/conf/transmission/other_recipient_types_api.js";
import StatusApi from "@/api/conf/transmission/status_api.js";
import ObjectsApi from "@/api/conf/transmission/objects_api.js";
import PerimetersApi from "@/api/conf/transmission/perimeter_api.js";
import CustomerCellsApi from "@/api/conf/transmission/customer_cells_api.js";
import PriceTypesApi from "@/api/conf/price_types_api";
import PriceUnitsApi from "@/api/conf/price_units_api";
import FaqActivityApi from "@/api/conf/faq_activity_api";
import FaqSubActivityApi from "@/api/conf/faq_subactivity_api";

import AvecProductsApi from "@/api/conf/avec_products_api.js";
import ProductPricesEstablishmentApi from "@/api/conf/product_price_establishment_api.js"
import ProductPriceGroupeApi from "@/api/conf/product_price_groupe_api.js"
import AvecProductCategoryApi from "@/api/conf/avec_product_category_api.js";


/** Construction du client Http de l'api de conf */
export function initHttpClient(apiKey, tokenFunction, apmTokenFunction) {
  //on instancie les clients http
  let httpclient = new ConfHttpClient();
  httpclient.getToken = tokenFunction;
  httpclient.getApmToken = apmTokenFunction;
  httpclient.api_key = apiKey;

  return httpclient;
}

/** Instanciation des apis du silo configuration */
export function initApi(httpClient) {
  return {
    appConsulterModifier: new ConfConsulterModifierApi(httpClient),
    appConfWorksContract: new ConfAppWorksContractApi(httpClient),
    appConfAvecEstablishment: new ConfAvecEstablishmentApi(httpClient),
    appConfAvecSuperEstablishment: new ConfAvecSuperEstablishmentApi(httpClient),
    appConfAvecSubactivity: new ConfAvecSubactivityApi(httpClient),
    appConfAvecIndicators: new ConfAvecIndicatorsApi(httpClient),
    emergencyLevelTicketing: new EmergencyLevelTicketingApi(
      httpClient
    ),
    emergencyLevelTransmission: new EmergencyLevelTransmissionApi(
      httpClient
    ),
    ticketingPerimeter: new TicketingPerimeterApi(httpClient),
    ticketingRecipientType: new TicketingRecipientTypeApi(httpClient),
    ticketingStatus: new TicketingStatusApi(httpClient),
    ticketingObjectSubobject: new TicketingObjectSubobjectApi(httpClient),
    transmission: {
      ecosystems: new EcoSystemsApi(httpClient),
      mediums: new MediumsApi(httpClient),
      otherRecipientTypes: new OtherRecipientTypesApi(httpClient),
      status: new StatusApi(httpClient),
      objects: new ObjectsApi(httpClient),
      avecProducts: new AvecProductsApi(httpClient),
      perimeters: new PerimetersApi(httpClient),
      customerCells: new CustomerCellsApi(httpClient),
    },
    confEstablishmentBusinessApp: new ConfEstablishmentBusinessApplicationApi(httpClient),
    priceTypes: new PriceTypesApi(httpClient),
    priceUnits: new PriceUnitsApi(httpClient),
    faqActivity: new FaqActivityApi(httpClient),
    faqSubActivity: new FaqSubActivityApi(httpClient),
    productPricesEstablishment: new ProductPricesEstablishmentApi(httpClient),
    productPriceGroupe: new ProductPriceGroupeApi(httpClient),
    avecProductCategory: new AvecProductCategoryApi(httpClient),
  };
}
