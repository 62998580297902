import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/medicalexpertises",
};

export default class MedicalExpertisesApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }
}
