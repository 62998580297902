import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/avecproducts",
  DESCRIPTION: "/description",
  DESCRIPTIONS: "/descriptions",
  ESTABLISHMENT: "/establishments",
  PRODUCT: "/products",
};

export default class AvecProductsApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }

  /** Récupération des descriptions spécifiques */
  async getAllSpecificDescription(productId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.SLACH
      + productId.toString()
      + DEFINES.DESCRIPTIONS
    );
    return this.httpclient.get(url);
  }

  /** Création / Update d'une description spécifique */
  async createUpdateSpecificDescription(establishmentId, productId, datas) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.SLACH
      + productId.toString()
      + DEFINES.ESTABLISHMENT
      + DEFINES.SLACH
      + establishmentId.toString()
      + DEFINES.DESCRIPTION
    );
    return this.httpclient.put(url, datas);
  }
  
  /** Suppression d'une description spécifique */
  async deleteSpecificDescription(establishmentId, productId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.SLACH
      + productId.toString()
      + DEFINES.ESTABLISHMENT
      + DEFINES.SLACH
      + establishmentId.toString()
      + DEFINES.DESCRIPTION
      );
    return this.httpclient.delete(url);
  }
}
