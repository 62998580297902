
import TemplateApi from "../template_api";



const DEFINES = {
  SLACH: "/",
  PATH: "/legalstructuretypes",
};

export default class LegalStructureTypeApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }
}
