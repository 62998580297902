import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/activitycapacities/trees",
  PATH_ACTIVITY_CAPACITIES : "/activitycapacities",
  PATH_ACTIVITIES: "/activities",
  PATH_ESTABLISHMENT_ACTIVITY_CAPACITY: "/establishments"
};

export default class CapacitiesApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }

  /**
  * Retourne la liste des templates de capacités pour une activité donnée
  *
  * Path : /activities/{activityid}/activitycapacities
  * Modèle :
  * [
  *     {
  *         "id":"int",
  *         "label":"string",
  *         "subCapacities": [
  *           {
  *             "id":"int",
  *             "label":"subCapa1",
  *             "subCapacities": [
  *             ]
  *           }
  *         ]
  *     }
  *   ]
  *
  **/
  async getCapacityTemplatesByActivity(activityId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH_ACTIVITIES +
        "/" +
        activityId +
        DEFINES.PATH_ACTIVITY_CAPACITIES
    );
    return this.httpclient.get(url);
  }

  /**
  * Retourne la liste des quantités par établissement pour un template de capacité
  *
  * Path : activitycapacities/{id}/quantities
  * Modèle :
  * [
  *   {establishmentid, quantity},
  * ]
  **/
  async getCapacityQuantities(capacityId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH_ACTIVITY_CAPACITIES +
        "/" +
        capacityId +
        "/quantities"
    );
    return this.httpclient.get(url);
  }

  async getEstablishementActivityCapacities(establishmentId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH_ESTABLISHMENT_ACTIVITY_CAPACITY +
        "/" +
        establishmentId +
        "/activitycapacities"
    );
    return this.httpclient.get(url);
  }

  /**
  * MAJ des capacités pour un établissement
  * Path PUT /establishments/{establishmentid}/activitycapacities
  */
  async updateEstablishmentCapacities(establishmentId, entity) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH_ESTABLISHMENT_ACTIVITY_CAPACITY +
        "/" +
        establishmentId +
        "/activitycapacities"
    );
    return this.httpclient.put(url, entity);
  }
}
