var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": true, "content-class": "elevation-1" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g({ attrs: { icon: "" } }, on),
                [
                  _c(
                    "v-avatar",
                    [
                      _vm.photoUrl
                        ? _c("img", { attrs: { src: _vm.photoUrl } })
                        : _c("v-icon", [_vm._v("$vuetify.icons.iconUser")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm.isConnected
        ? _c(
            "v-list",
            { staticClass: "pa-6" },
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      staticStyle: { "border-width": "2px" },
                      attrs: {
                        link: "",
                        block: "",
                        outlined: "",
                        color: "primary",
                        href: _vm.onClickCollabBtn(),
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-3", attrs: { size: "12" } },
                        [_vm._v("$vuetify.icons.iconHomeUser")]
                      ),
                      _vm._v(" Mon espace collaborateur"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn justify-start",
                      staticStyle: { "border-width": "2px" },
                      attrs: {
                        link: "",
                        outlined: "",
                        block: "",
                        color: "primary",
                        href: _vm.onClickCompteBtn(),
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-3", attrs: { size: "12" } },
                        [_vm._v("$vuetify.icons.iconUser")]
                      ),
                      _vm._v(" Mon compte"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider", { staticClass: "mx-2 my-4" }),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn justify-start",
                      attrs: { block: "", depressed: "", color: "primary" },
                      on: { click: this.signOutFromMenu },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-3", attrs: { size: "12" } },
                        [_vm._v("$vuetify.icons.iconArrowBack")]
                      ),
                      _vm._v(" Déconnexion"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }