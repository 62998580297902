import CivilitiesApi from "@/api/users/civilities_api";
import CollaboratorApi from "@/api/users/collaborators_api.js";
import CollectiveAgreementApi from "@/api/users/collective_agreement_api.js";
import UsersApi from "@/api/users/dg_users_api";
import EmployeesTitleApi from "@/api/users/employees_title_api.js";
import FitForWorkStatusApi from "@/api/users/fit_for_work_status_api.js";
import GenderApi from "@/api/users/gender_api";
import JobAdRequiredPointApi from "@/api/users/job_ad_required_point_api.js";
import RecruitingReasonApi from "@/api/users/recruiting_reason_api.js";
import RemunerationTypesApi from "@/api/users/remuneration_type_api.js";
import UserTypeApi from "@/api/users/user_type_api.js";
import UsersHttpClient from "@/api/users/users_http_client";
import WorkRelationshipType from "@/api/users/work_relationship_type_api.js";
import WorksDurationApi from "@/api/users/works_duration_api.js";
import AdvantagesApi from "./advantages_api";
import MobilityZoneApi from "./mobility_zone_api";

/** Construction du client Http de l'api user */
export function initHttpClient(apiKey, tokenFunction, apmTokenFunction) {
  //on instancie les clients http
  let httpclient = new UsersHttpClient();
  httpclient.getToken = tokenFunction;
  httpclient.getApmToken = apmTokenFunction;
  httpclient.api_key = apiKey;

  return httpclient;
}

/** Instanciation des api du silo Users */
export function initApi(httpClient) {
  return {
    gender: new GenderApi(httpClient),
    users: new UsersApi(httpClient),
    collaborators: new CollaboratorApi(httpClient),
    civilities: new CivilitiesApi(httpClient),
    worksDuration: new WorksDurationApi(httpClient),
    userType: new UserTypeApi(httpClient),
    collectiveAgreement: new CollectiveAgreementApi(httpClient),
    workRelationshipType: new WorkRelationshipType(httpClient),
    remunerationType: new RemunerationTypesApi(httpClient),
    recruitingReason: new RecruitingReasonApi(httpClient),
    jobAdRequiredPoint: new JobAdRequiredPointApi(httpClient),
    mobilityZone: new MobilityZoneApi(httpClient),
    advantages: new AdvantagesApi(httpClient),
    employeesTitle: new EmployeesTitleApi(httpClient),
    fitForWorkStatus: new FitForWorkStatusApi(httpClient),
  };
}
