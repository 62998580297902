import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/users",
  FUNCTIONS: "/functions",
  INCREMENTAL_SEARCH: "/incrementalsearch",
  SEARCH:"/search",
  FULL: "/full",
  COLLABORATOR: "/collaborators",
};

export default class DgUsersApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }


  // TODO: méthode à déprécier car se trouve dans le service **collaborator**
    // Chaque référence à cett méthode doit être remplacer par son pendant sur l'api collaborator.
  /**retourne un élément*/
  async getByUuid(entityUuid) {
    let url = this.httpclient.buildUrl(
      DEFINES.COLLABORATOR +
      DEFINES.SLACH +
      entityUuid.toString());
    return this.httpclient.get(url);
  }

  /** Obtient la liste des utilisatuers qui possèdent la fonction dop */
  async getUsersFunctionDop(idFunctionDop) {
    let url = this.httpclient.buildUrl(
      DEFINES.COLLABORATOR +
        DEFINES.FUNCTIONS +
        DEFINES.SLACH +
        idFunctionDop.toString()
    );

    return this.httpclient.get(url);
  }

  /**Recherche d'utilisateurs en fonction de certains champs */
  async searchUserByField(field, value, max = 25) {
    let url = this.httpclient.buildUrl(
      DEFINES.COLLABORATOR +
        DEFINES.INCREMENTAL_SEARCH +
        "?field=" +
        field +
        "&value=" +
        value +
        "&maxrow=" +
        max
    );
    return this.httpclient.get(url);
  }

  /**Recherche d'utilisateurs en fonction de certains champs et retourne l'id */
  async searchUserByFieldWithId(field, value, max = 25) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
        DEFINES.INCREMENTAL_SEARCH +
        "?field=" +
        field +
        "&value=" +
        value +
        "&maxrow=" +
        max
    );
    return this.httpclient.get(url);
  }


  /**Retourne la liste des utilisateurs ayant cette fonction digitale */
  async findUsersByFunctionId(functionId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH + DEFINES.FUNCTIONS + DEFINES.SLACH + functionId.toString()
    );

    return this.httpclient.get(url);
  }




}
