import * as logger from "@/tools/logger.js";

const _USER_TOKEN = "usertoken";
const _EMAIL = "email";
const _PHOTO_URL = "photo_url";
const _ISCONNECTED = "connected";
const _ROUTE = "route";
const _CONNECTION_LOADED = "connectionloaded";
const _GIVEN_NAME = "given_name";
const _EXPIRES_TOKEN_IP = "expires_token_ip";
const _USER_TOKEN_ACS = "usertokenacs";
const _EXPIRES_TOKEN_ACS = "expires_token_acs";
const _ACS_ROLES = "acsroles";
const _APP_ROLES = "approles";
const _CAN_EDIT_DATA = "caneditdata";
const _USER_CONNECTED_SSO = "userconnectedsso";

//////////////  STORE ROUTE //////////////////////

/** Obtient la route stockée */
export function getExpectedRoute() {
  return sessionStorage.getItem(_ROUTE);
}
/**
 * Définir la route souhaitée à prendre en compte après l'étape de 'connexion'
 */
export function setExpectedRoute(route) {
  logger.debug("enregistrement de la route : " + route);
  return sessionStorage.setItem(_ROUTE, route);
}
/** Supprime le route demandée */
export function clearExpectedRoute() {
  sessionStorage.removeItem(_ROUTE);
}

//////////////  STORE ETAT CONNECTED //////////////////////

/** Obtient l'état de connection de l'utilisateur */
export function getConnected() {
  return sessionStorage.getItem(_ISCONNECTED);
}
/** Fixe l'état de connection de l'utilisateur */
export function setConnected(connected) {
  sessionStorage.setItem(_ISCONNECTED, connected);
}

//////////////  STORE ETAT LOADING CONNEXION //////////////////////

/** Obtient l'état de chargement de la connexion de l'utilisateur */
export function getConnectionLoaded() {
  return sessionStorage.getItem(_CONNECTION_LOADED);
}
/** Fixe l'état de chargement de la connexion de l'utilisateur */
export function setConnectionLoaded(value) {
  sessionStorage.setItem(_CONNECTION_LOADED, value);
}

//////////////  STORE EMAIL //////////////////////

/** Obtient l'email du compte utilisateur connecté */
export function getEmail() {
  let email = sessionStorage.getItem(_EMAIL);
  return email;
}
/** Fixe l'email du compte utilisateur connecté */
export function setEmail(email) {
  sessionStorage.setItem(_EMAIL, email);
}
/** Supprime le mail stocké */
export function clearEmail() {
  sessionStorage.removeItem(_EMAIL);
}

//////////////  STORE EMAIL //////////////////////

/** Obtient le lien de la photo de l'utilisateur connecté */
export function getPhotoUrl() {
  let photoUrl = sessionStorage.getItem(_PHOTO_URL);
  return photoUrl;
}
/** Fixe l'url de la photo de profil de l'utilisateur */
export function setPhotoUrl(photoUrl) {
  sessionStorage.setItem(_PHOTO_URL, photoUrl);
}
/** Supprime l'url de la photo de profil utilisateur */
export function clearPhotoUrl() {
  sessionStorage.removeItem(_PHOTO_URL);
}

//////////////  STORE TOKEN IP //////////////////////

/** Obtient le token utilisateur */
export function getToken() {
  return sessionStorage.getItem(_USER_TOKEN);
}
/** Fixe le token utilisateur */
export function setToken(token) {
  sessionStorage.setItem(_USER_TOKEN, token);
}
/** Supprime le tokenIp stocké */
export function clearToken() {
  sessionStorage.removeItem(_USER_TOKEN);
}

//////////////  STORE TOKEN ACS //////////////////////

/** Obtient le token acs de l'utilisateur */
export function getTokenAcs() {
  return sessionStorage.getItem(_USER_TOKEN_ACS);
}
/** Fixe le token acs de l'utilisateur */
export function setTokenAcs(token) {
  sessionStorage.setItem(_USER_TOKEN_ACS, token);
}
/** Supprime le token acs de l'utilisateur */
export function clearTokenAcs() {
  sessionStorage.removeItem(_USER_TOKEN_ACS);
}

//////////////  STORE FULL NAME USER //////////////////////

/** Obtient le prénom du compte utilisateur connecté */
export function getGivenName() {
  return sessionStorage.getItem(_GIVEN_NAME);
}
/** Fixe la nom et prénom de l'utilisateur */
export function setGivenName(givenName) {
  sessionStorage.setItem(_GIVEN_NAME, givenName);
}
/** Supprime le nom complet stocké */
export function clearGivenName() {
  sessionStorage.removeItem(_GIVEN_NAME);
}

//////////////  STORE EXPIRATION TOKEN IP //////////////////////

/** Obtient l'expiration du token ip */
export function getExpiresTokenIp() {
  return sessionStorage.getItem(_EXPIRES_TOKEN_IP);
}
/** Fixe l'expiration du token ip */
export function setExpiresTokenIp(value) {
  sessionStorage.setItem(_EXPIRES_TOKEN_IP, value);
}
/** Supprime l'expiration du token ip stocké */
export function clearExpiresTokenIp() {
  sessionStorage.removeItem(_EXPIRES_TOKEN_IP);
}

//////////////  STORE EXPIRATION TOKEN ACS //////////////////////

/** Obtient l'expiration du token acs */
export function getExpiresTokenAcs() {
  return sessionStorage.getItem(_EXPIRES_TOKEN_ACS);
}
/** Fixe l'expiration du token acs */
export function setExpiresTokenAcs(value) {
  sessionStorage.setItem(_EXPIRES_TOKEN_ACS, value);
}
// Supprime l'expiration du token acs
export function clearExpiresTokenAcs() {
  sessionStorage.removeItem(_EXPIRES_TOKEN_ACS);
}

//////////////  STORE ROLES ACS USER //////////////////////

/** Obtient la liste des rôles acs de l'utilisateur */
export function getAcsRoles() {
  return sessionStorage.getItem(_ACS_ROLES);
}
/** Fixe la liste des roles acs de l'utilisateur */
export function setAcsRoles(value) {
  sessionStorage.setItem(_ACS_ROLES, value);
}
/** Supprime la liste des roles acs de l'utilisateur */
export function clearAcsRoles() {
  sessionStorage.removeItem(_ACS_ROLES);
}

//////////////  STORE ROLES APPLICATIFS USER //////////////////////

/** Obtient la liste des rôles applicatifs de l'utilisateur */
export function getAppRoles() {
  return sessionStorage.getItem(_APP_ROLES);
}
/** Fixe la liste des roles applicatifs de l'utilisateur */
export function setAppRoles(value) {
  sessionStorage.setItem(_APP_ROLES, value);
}
/** Suppirme la liste des roles applicatif de l'utilisateur */
export function clearAppRoles() {
  sessionStorage.removeItem(_APP_ROLES);
}

//////////////  STORE CAN EDIT DATA //////////////////////

/** Obtient l'état qui indique si l'utilisateur as au moins un rôle d'édition */
export function getCanEditData() {
  return sessionStorage.getItem(_CAN_EDIT_DATA);
}
/** Fixe l'état qui indique si l'utilisateur as au moins un rôle d'édition */
export function setCanEditData(value) {
  sessionStorage.setItem(_CAN_EDIT_DATA, value);
}

//////////////  STORE USER SSO CONNECTED //////////////////////

/** Obtient l'état qui indique si l'utilisateur est connecté au sso */
export function getUserConnectedSso() {
  return sessionStorage.getItem(_USER_CONNECTED_SSO);
}
/** Fixe l'état qui indique si l'utilisateur est connecté au sso */
export function setUserConnectedSso(value) {
  sessionStorage.setItem(_USER_CONNECTED_SSO, value);
}
