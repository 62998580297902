import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: '/',
  PATH: '/collaborator-practitioners',
}

export default class CollaboratorsPractitionnersApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);
    this.httpclient = httpclient;
  }
}