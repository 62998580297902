import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/conf/2020-INFSI-030",
  DOP: "/dop",
  METIERS: "/metiers",
};

export default class ConfConsulterModifierApi extends TemplateApi {
  constructor(httpClient) {
    super(httpClient, DEFINES.PATH);

    this.httpClient = httpClient;
  }

  /** Récupère l'id de la fonction dop */
  async getIdDop() {
    let url = this.httpClient.buildUrl(DEFINES.PATH + DEFINES.DOP);
    return this.httpClient.get(url);
  }
}
