
import TemplateApi from "../template_api";


const DEFINES = {
  SLACH: "/",
  PATH: "/externalcontracttypes",
};

export default class ExternalContractTypesApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }
}
