


import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: "/",
  CDD_REASONS: "/workdurationlessthan24hreasons",
};

export default class WorkDurationReasonApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.CDD_REASONS);

    this.httpclient = httpclient;
  }
}

