

import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/rubrics",
};

export default class RubricsApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }
}

