

import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/workrelationshiptypes",
  DSN: "dsn",
};

export default class WorkRelationShipTypesApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }

  getDnsRadicalForWorkRelationshipTypeId(workRelationshipTypeId) {
    return this.httpclient.buildUrl(
      DEFINES.PATH +
      DEFINES.SLACH+
      workRelationshipTypeId.toString() + 
      DEFINES.SLACH+
      DEFINES.DSN
    );
  }

  /** Obtient le mapping DSN pour le WorkRelationShipType */
  async getDnsForWorkRelationshipTypeId(workRelationshipTypeId) {
    let url = this.getDnsRadicalForWorkRelationshipTypeId(workRelationshipTypeId);
    return this.httpclient.get(url);
  }

  async putDnsForWorkRelationshipTypeId(workRelationshipTypeId, entity) {
    let url = this.getDnsRadicalForWorkRelationshipTypeId(workRelationshipTypeId);
    return this.httpclient.put(url, entity);
  }

  async postDnsForWorkRelationshipTypeId(workRelationshipTypeId, entity) {
    let url = this.getDnsRadicalForWorkRelationshipTypeId(workRelationshipTypeId);
    return this.httpclient.post(url, entity);
  }

  async deleteDnsForWorkRelationshipTypeId(workRelationshipTypeId) {
    let url = this.getDnsRadicalForWorkRelationshipTypeId(workRelationshipTypeId);
    return this.httpclient.delete(url, {});
  }

  async getAllMappingsDnsForWorkRelationshipType() {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
      DEFINES.SLACH+
      "mappings" +
      DEFINES.SLACH+ 
      DEFINES.DSN 
    );
    return this.httpclient.get(url);
  }
}
