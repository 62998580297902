import BackendHttpClient from "@/api/backend/backend_http_client.js";
import TicketingApi from "@/api/backend/ticketing_api.js";
import AuthAdminApi from "@/api/backend/auth_admin.api.js";

/** Construction du client Http de l'api du backend */
export function initHttpClient(apiKey, tokenFunction, apmTokenFunction) {
  //on instancie les clients http
  let httpclient = new BackendHttpClient();
  httpclient.getToken = tokenFunction;
  httpclient.getApmToken = apmTokenFunction;
  httpclient.api_key = apiKey;

  return httpclient;
}

/** Instanciation des apis du backend */
export function initApi(httpClient) {
  return {
    ticketing: new TicketingApi(httpClient),
    authAdmin: new AuthAdminApi(httpClient),
  };
}
