var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M8.80159 1.9112C8.58134 1.71331 8.29569 1.60384 7.99959 1.60384C7.7035 1.60384 7.41785 1.71331 7.19759 1.9112L2.79759 5.864C2.67239 5.97659 2.57229 6.11427 2.50378 6.26809C2.43528 6.42191 2.39992 6.58842 2.39999 6.7568V12.4C2.39999 12.7183 2.52642 13.0235 2.75147 13.2485C2.97651 13.4736 3.28173 13.6 3.59999 13.6H5.19999C5.51825 13.6 5.82348 13.4736 6.04852 13.2485C6.27357 13.0235 6.39999 12.7183 6.39999 12.4V9.2C6.39999 9.09392 6.44214 8.99217 6.51715 8.91716C6.59217 8.84214 6.69391 8.8 6.79999 8.8H9.19999C9.33039 8.7984 9.47039 8.7984 9.59839 8.7984C9.59828 8.33487 9.73241 7.88122 9.98456 7.49227C10.2367 7.10332 10.5961 6.79571 11.0193 6.60661C11.4425 6.41751 11.9114 6.35502 12.3694 6.42667C12.8274 6.49833 13.2548 6.70107 13.6 7.0104V6.7568C13.6001 6.58842 13.5647 6.42191 13.4962 6.26809C13.4277 6.11427 13.3276 5.97659 13.2024 5.864L8.80159 1.9112Z",
          fill: "#1E1D1D",
        },
      }),
      _c("path", {
        attrs: {
          d: "M10.8186 7.46863C11.1187 7.16857 11.5257 7 11.95 7C12.3743 7 12.7813 7.16857 13.0814 7.46863C13.3814 7.76869 13.55 8.17565 13.55 8.6C13.55 9.02435 13.3814 9.43131 13.0814 9.73137C12.7813 10.0314 12.3743 10.2 11.95 10.2C11.5257 10.2 11.1187 10.0314 10.8186 9.73137C10.5186 9.43131 10.35 9.02435 10.35 8.6C10.35 8.17565 10.5186 7.76869 10.8186 7.46863Z",
          fill: "#E20080",
        },
      }),
      _c("path", {
        attrs: {
          d: "M9.75 11C9.198 11 8.75 11.448 8.75 12L8.7508 12.0008V12.0024L8.7516 12.2416C8.75276 12.2694 8.75462 12.2971 8.7572 12.3248C8.80131 12.7217 8.94405 13.1012 9.1724 13.4288C9.6092 14.0528 10.442 14.6 11.95 14.6C13.4588 14.6 14.2908 14.0536 14.7268 13.4296C14.9551 13.102 15.0978 12.7225 15.142 12.3256C15.1483 12.2407 15.151 12.1555 15.15 12.0704V12C15.15 11.448 14.702 11 14.15 11H9.75Z",
          fill: "#E20080",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }