import TemplateApi from "../../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/conf/2020-INFSI-028/transmission/otherrecipienttypes",
};

export default class OtherRecipientTypesApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }
}
