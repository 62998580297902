import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/acs/admin",
  CUSTOMER: "customer",
  COLLABORATOR: "collaborators",
  RULES: "rules",
  SERVICE: "services",
  FUNCTION: "functions",
};

export default class AuthAdminApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }

  /** Retourne la liste des rôles avec l'héritage. */
  async getRoles() {
    let url = this.httpclient.buildUrl(`${DEFINES.PATH}/${DEFINES.RULES}`);

    return this.httpclient.get(url);
  }

  /** Retourne les rôles pour l'entité "services". */
  async getRolesForServices() {
    let url = this.httpclient.buildUrl(`${DEFINES.PATH}/${DEFINES.SERVICE}`);

    return this.httpclient.get(url);
  }

  /** Met à jour la liste des rôles d'un service */
  async updateRolesForServices(entity) {
    let url = this.httpclient.buildUrl(
      `${DEFINES.PATH}/${DEFINES.SERVICE}/${entity.id.toString()}`
    );

    return this.httpclient.put(url, entity);
  }

  /**Retourne le détail des rôles de tous les collaborateurs. Ne génère pas le token. */
  async getDetailForAllCollaborater() {
    let url = this.httpclient.buildUrl(
      `${DEFINES.PATH}/${DEFINES.COLLABORATOR}`
    );
    return this.httpclient.get(url);
  }

  /** Retourne les rôles pour les fonctions. */
  async getRolesForFonctions() {
    let url = this.httpclient.buildUrl(`${DEFINES.PATH}/${DEFINES.FUNCTION}`);

    return this.httpclient.get(url);
  }

  /** Met à jour la liste des rôles d'une fonction */
  async updateRolesForFunction(entity) {
    let url = this.httpclient.buildUrl(
      `${DEFINES.PATH}/${DEFINES.FUNCTION}/${entity.id.toString()}`
    );
    return this.httpclient.put(url, entity);
  }

  /**Retourne le détail des rôles d'un collaborateur. Ne génère pas le token. */
  async getDetailForCollaborater(collaboratorUuid) {
    let url = this.httpclient.buildUrl(
      `${DEFINES.PATH}/${DEFINES.COLLABORATOR}/${collaboratorUuid}`
    );
    return this.httpclient.get(url);
  }

  /** Met à jour la liste des rôles d'un collaborateur */
  async updateRolesForCollaborater(entity, collaboratorUuid) {
    let url = this.httpclient.buildUrl(
      `${DEFINES.PATH}/${DEFINES.COLLABORATOR}/${collaboratorUuid}`
    );

    return this.httpclient.put(url, entity);
  }

  /** Retourne les rôles acs pour un client */
  async getRolesForCustomer() {
    let url = this.httpclient.buildUrl(`${DEFINES.PATH}/${DEFINES.CUSTOMER}`);

    return this.httpclient.get(url);
  }

  /** Met à jour les rôles acs pour un client */
  async updateRolesForCustomer(roles) {
    let url = this.httpclient.buildUrl(`${DEFINES.PATH}/${DEFINES.CUSTOMER}`);

    return this.httpclient.put(url, roles);
  }
}
