import TemplateApi from "../../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/relationship-types",
};

export default class EcoSystemsApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }

  async updateEcosystem (id, entity) {
    // Ce service fait lui même l'update car il faut l'id seulement dans l'URL 
    let url = this.httpclient.buildUrl(this.path + "/" + id.toString());
    return this.httpclient.put(url, entity);
  }
}
