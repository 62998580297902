import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/practitioners",
  INCREMENTAL: "/incrementalsearch",
  COLLABORATER: "/collaboraters",
  COLLABORATOR: "/collaborator",
  EXTERNAL:  "/externals",
  PROFESSIONS: "/professions",
  PRACTICEPLACE: "/practiceplaces",
  AVECESTABLISHMENT: "/avecestablishments",
  AGENDAS: "/agendas",
  BLACKLIST: "/blacklist",
  FIND_BY_ESTABLISHMENT_AND_ACTIVITY_ID: "/findByEstablishmentAndActivityId",
};


export default class PractitionerApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }

  /** Recherche de praticien en fonction de son nom */
  async searchPractitionerByName(search, max = 25) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.INCREMENTAL
      + "?type=NAME"
      + `&value=${search}`
      + `&size=${max}`
    );

    return this.httpclient.get(url);
  }

  /** Recherche d'un praticien par idpp */
  async searchPractitionerByIdpp(idpp) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.SLACH
      + idpp.toString()
    );
    return this.httpclient.get(url);
  }

  /** Recherche d'un collaborateur praticien par idpp */
  async searchPractitionerCollaboratorByIdpp(idpp) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.SLACH
      + idpp.toString()
      + DEFINES.COLLABORATOR
    );
    return this.httpclient.get(url);
  }

  /** Recherche d'un lieu d'exercice en fonction d'un établissement et d'une activité */
  async getPlacticePlaceFromEstablishmentAndActivity(establishmentId, activityId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.PRACTICEPLACE
      + DEFINES.AVECESTABLISHMENT
      + DEFINES.FIND_BY_ESTABLISHMENT_AND_ACTIVITY_ID
      + `?establishmentId=${establishmentId}`
      + `&activityId=${activityId}`
    );
    return this.httpclient.get(url);
  }


  /** Obtiens la liste des noms de prfession */
  async getAllProfessioNames() {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.PROFESSIONS
    );
    return this.httpclient.get(url);
  }

  /** Obtien la liste des lieux d'exercices */
  async getAllPracticePlace() {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.PRACTICEPLACE
      + DEFINES.AVECESTABLISHMENT
    );
    return this.httpclient.get(url);
  }


  /** Sauvegarde de l'identité d'un praticien */
  async saveIdentity(idpp, entity) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.SLACH
      + idpp.toString()
    );
    return this.httpclient.patch(url, entity);
  }


  /** Création d'un lieu d'exercice pourun praticien */
  async addPracticePlace(idpp, practicePlaceId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.SLACH
      + idpp.toString()
      + DEFINES.PRACTICEPLACE
      + DEFINES.SLACH
      + practicePlaceId.toString()
    );
    return this.httpclient.post(url, null);
  }

  /** Création d'un agenda pour un praticien */
  async addAgendaToPracticePlace(idpp, entity) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.SLACH
      + idpp.toString()
      + DEFINES.AGENDAS
    );
    return this.httpclient.post(url, entity);
  }

  /** Blacklistage d'un praticien externe */
  async blacklistPractitioner(idpp) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.EXTERNAL
      + DEFINES.BLACKLIST
      + DEFINES.SLACH
      + idpp.toString()
    );
    return this.httpclient.post(url);
  }

  /** dé-Blacklistage d'un praticien externe */
  async unBlacklistPractitioner(idpp) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.EXTERNAL
      + DEFINES.BLACKLIST
      + DEFINES.SLACH
      + idpp.toString()
    );
    return this.httpclient.delete(url);
  }


  /** Delete d'un lieu d'exercice pour un praticien */
  async deletePracticePlace(idpp, practicePlaceId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.SLACH
      + idpp.toString()
      + DEFINES.PRACTICEPLACE
      + DEFINES.SLACH
      + practicePlaceId.toString()
    );
    return this.httpclient.delete(url, null);
  }

  /** Delete un agenda yesdoc pour un praticien */
  async deleteYesdocAgenda(idpp, agendaId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.SLACH
      + idpp.toString()
      + DEFINES.AGENDAS
      + DEFINES.SLACH
      + agendaId.toString()
    );
    return this.httpclient.delete(url);
  }

}
