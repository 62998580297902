import SfrHttpClient from "@/api/sfr/sfr_http_client";
import ActivitiesApi from "@/api/sfr/activities_api";
import FunctionApi from "@/api/sfr/function_api";
import MetiersApi from "@/api/sfr/metiers_api";
import RubricsApi from "@/api/sfr/rubrics_api.js";
import GlobalRubricsApi from "@/api/sfr/global_rubrics_api.js";
import ServicesApi from "@/api/sfr/services_api.js";
import SubActivitiesApi from "@/api/sfr/sub_activities_api.js";
import RubricsContextApi from "./rubrics_context_api";
import DomainBrandApi from "@/api/sfr/domain_brand_api.js";
import SuperActivitiesApi from "@/api/sfr/super_activities_api.js";

/** Construction du client Http de l'api sfr */
export function initHttpClient(apiKey, tokenFunction, apmTokenFunction) {
  //on instancie les clients http
  let httpclient = new SfrHttpClient();
  httpclient.getToken = tokenFunction;
  httpclient.getApmToken = apmTokenFunction;
  httpclient.api_key = apiKey;

  return httpclient;
}

/** Instanciation des api du silo SFR */
export function initApi(httpClient) {
  return {
    activities: new ActivitiesApi(httpClient),
    functions: new FunctionApi(httpClient),
    metiers: new MetiersApi(httpClient),
    rubrics: new RubricsApi(httpClient),
    globalRubrics: new GlobalRubricsApi(httpClient),
    services: new ServicesApi(httpClient),
    subActivities: new SubActivitiesApi(httpClient),
    rubricsContextApi: new RubricsContextApi(httpClient),
    domainBrands: new DomainBrandApi(httpClient),
    superActivities: new SuperActivitiesApi(httpClient),
  };
}
