import TemplateApi from "../template_api";
const DEFINES = {
  SLACH: "/",
  PATH: "/metiers",
};

/**
 * Classe de manipulation des appels api concernant les métiers.
 */
export default class MetiersApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }
}
