<template>
  <v-menu :offset-y="true" content-class="elevation-1">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon>
        <v-avatar>
          <img :src="photoUrl" v-if="photoUrl" />
          <v-icon v-else>$vuetify.icons.iconUser</v-icon>
        </v-avatar>
      </v-btn>
    </template>
    <v-list v-if="isConnected" class="pa-6">
      <v-list-item>
        <v-btn
          class="btn"
          link
          block
          outlined
          color="primary"
          style="border-width: 2px"
          :href="onClickCollabBtn()"
        >
          <v-icon class="mr-3" size="12">$vuetify.icons.iconHomeUser</v-icon>
          Mon espace collaborateur</v-btn
        >
      </v-list-item>
      <v-list-item>
        <v-btn
          class="btn justify-start"
          link
          outlined
          block
          color="primary"
          style="border-width: 2px"
          :href="onClickCompteBtn()"
        >
          <v-icon class="mr-3" size="12">$vuetify.icons.iconUser</v-icon>
          Mon compte</v-btn
        >
      </v-list-item>

      <v-divider class="mx-2 my-4"></v-divider>

      <v-list-item>
        <v-btn
          class="btn justify-start"
          block
          depressed
          color="primary"
          @click="this.signOutFromMenu"
        >
          <v-icon class="mr-3" size="12">$vuetify.icons.iconArrowBack</v-icon>
          Déconnexion</v-btn
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import * as logger from "@/tools/logger.js";
import * as loginStoreService from "@/service/login_store_service.js";
import * as loginService from "@/service/login_service.js";
import { defines } from "@/router/defines.js";

export default {
  name: "MenuProfilComponent",

  mixins: [],
  props: {
    connected: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data: () => ({
    /**les actions du menu utilisateur */
    userMenuActions: [],
    routes: defines,
    photoUrl: null,
  }),

  methods: {
    onClickCollabBtn() {
      let url = process.env.VUE_APP_BASE_SITE_AVEC_COLLAB;

      console.log(url);
      if (url && url != "") {
        return url;
      }
      logger.debug("url Avec is null undefined or empty");
    },

    onClickCompteBtn() {
      let url = process.env.VUE_APP_BASE_SITE_AVEC_COMPTE;

      if (url && url != "") {
        return url;
      }
      logger.debug("url Avec is null undefined or empty");
    },

    getPhotoUrl() {
      let urlPic = loginStoreService.getPhotoUrl();
      if (urlPic && urlPic != "") {
        if (urlPic != this.photoUrl) {
          this.photoUrl = urlPic;
        }
      } else {
        this.photoUrl = null;
      }
    },

    /**
     * Demande de déconnexion de l'utilisateur
     * à partir du menu présent dans la barre applicative
     */
    async signOutFromMenu() {
      await loginService.disconnectUser();

      logger.debug("After loginservice disconnectUser()");
      this.$router.push(this.routes.connexion.path);
    },
  },
  computed: {
    isConnected() {
      return this.connected;
    },
  },
  mounted() {},
  updated() {
    this.getPhotoUrl();
  },
  watch: {},
};
</script>
