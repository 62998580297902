import TemplateApi from "../template_api";

const DEFINES = {
  PATH: "/authorize",
};

/**L'API ACS permet de contacter l'API de gestion des droits 'collaborateur'. Cette API se
 * base sur le token 'Authorisation' passé en paramètre pour déterminer les droits applicables
 * pour un collaborateur.
 */
export default class AcsApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }

  /** Retourne un token définissant les rôles d'un collaborateur. */
  async getRolesForUser() {
    let url = this.httpclient.buildUrl(this.path);

    return this.httpclient.get(url);
  }
}
