//
// Données relatives à l'utilisateur connecté
//

const state = {
  /**L'utilisateur est connecté à google */
  connected: false,

  /**email de l'utilisateur */
  email: undefined,
};

// Les variables de la classe
const mutations = {
  connected: (state, connected) => {
    state.connected = connected;
  },
  email: (state, email) => {
    state.email = email;
  },
  signOut: (state) => {
    state.connected = false;
    state.email = undefined;
  },
};

// Les actions sont les setteurs des variables(mutations) de la classe
const actions = {
  /**login de l'utilisateur */
  setConnected: ({ commit }, data) => {
    commit("connected", data);
  },
  /**login de l'utilisateur */
  setEmail: ({ commit }, data) => {
    commit("email", data);
  },
  signOut: ({ commit }, data) => {
    commit("signOut", data);
  },
};

//Les getteurs de la classe
const getters = {
  connected: (state) => {
    return state.connected;
  },
  email: (state) => {
    return state.email;
  },
};


// Le connecteur pour l'accès à la classe
let moduleLogin = {
  namespaced: true,
  state: state,
  mutations: mutations,
  getters: getters,
  actions: actions,
};

export default moduleLogin;
