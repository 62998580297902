var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M8 0.669983L2 3.33665V7.33665C2 11.0367 4.56 14.4967 8 15.3367C11.44 14.4967 14 11.0367 14 7.33665V3.33665L8 0.669983ZM8 7.99665H12.6667C12.3133 10.7433 10.48 13.19 8 13.9567V8.12332H3.33333V4.20332L8 2.12998V7.99665Z",
          fill: "#1E1D1D",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8 2.14996L12.74 4.06996L12.68 8.02996H8V2.14996Z",
          fill: "#E20080",
        },
      }),
      _c("path", {
        attrs: {
          d: "M3.32001 8.03003C3.92001 8.03003 8.00001 8.03003 8.00001 8.03003V13.97C5.72 13.13 4.7 11.57 4.7 11.57C4.7 11.57 3.62 10.43 3.32001 8.03003Z",
          fill: "#E20080",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }