
import TemplateApi from "../template_api";


const DEFINES = {
  SLACH: "/",
  PATH: "/superlegalstructures",
};

export default class SuperLegalStructureApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }
}
