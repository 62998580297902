import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/conf/2020-INFSI-028",
  TRANSMISSION: "/conf/2020-INFSI-028/transmission",
  TICKET: "/conf/2020-INFSI-028/ticket",
  EXPORT: "export",
  IMPORT: "import",
  URLGSHEET: "urlgsheet",
};

export default class TicketingApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }

  /** Exporter la matrice transmission de la database globale vers un gsheet. */
  async exportTransmissionMatrix() {
    let url = this.httpclient.buildUrl(
      DEFINES.TRANSMISSION + DEFINES.SLACH + DEFINES.EXPORT
    );
    return this.httpclient.post(url);
  }

  /** Importer la matrice transmission depuis un gsheet vers la database globale. */
  async importTransmissionMatrix() {
    let url = this.httpclient.buildUrl(
      DEFINES.TRANSMISSION + DEFINES.SLACH + DEFINES.IMPORT
    );
    return this.httpclient.post(url);
  }

  /** Exporter la matrice ticket de la database globale vers un gsheet. */
  async exportTicketMatrix() {
    let url = this.httpclient.buildUrl(
      DEFINES.TICKET + DEFINES.SLACH + DEFINES.EXPORT
    );
    return this.httpclient.post(url);
  }

  /** Importer la matrice ticket depuis un gsheet vers la database globale. */
  async importTicketMatrix() {
    let url = this.httpclient.buildUrl(
      DEFINES.TICKET + DEFINES.SLACH + DEFINES.IMPORT
    );
    return this.httpclient.post(url);
  }

  /** Obtenir l'url de l'onglet Ticket dans le gsheet matrice */
  async getUrlGsheetTicketing() {
    let url = this.httpclient.buildUrl(
      DEFINES.TICKET + DEFINES.SLACH + DEFINES.URLGSHEET
    );
    return this.httpclient.get(url);
  }

  /** Obtenir l'url de l'onglet Transmission dans le gsheet matrice */
  async getUrlGsheetTransmission() {
    let url = this.httpclient.buildUrl(
      DEFINES.TRANSMISSION + DEFINES.SLACH + DEFINES.URLGSHEET
    );
    return this.httpclient.get(url);
  }
}
