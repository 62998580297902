import * as logger from "@/tools/logger.js";

// import * as loginService from "@/service/login_service.js";

const _CLASS_NAME = "BaseApi";
const _METHOD_CONSTRUCTOR = "constructor";
const _METHOD_BUILD_URL = "build_url";

export const ApiTypeEnum = {
  DGApi: "dgapi",
  Acs: "acs",
};

/**
 * Classe de base pour la construction de l'url à l'api.
 */
export default class BaseApi {
  /**
   *
   * @param {axios} http le client http
   */
  constructor(apiType = ApiTypeEnum.DGApi) {
    logger.debugFull(
      _CLASS_NAME,
      _METHOD_CONSTRUCTOR,
      "Définition des variables de base pour la communication api."
    );
    this.http = null;
    this.host = null;
    this.rootUrl = null;
    this.apiType = apiType;

    /**la clé d'api */
    this.api_key = null;
  }

  /** construire l'url : racine + point d'accès. Ex : http://localhost:8081 +  /api + /etablishment */
  buildUrl(path) {
    logger.debugFull(
      _CLASS_NAME,
      _METHOD_BUILD_URL,
      "host: " + this.host + " rooturl: " + this.rootUrl + " path: " + path
    );
    return this.host + this.rootUrl + path;
  }

  /** Appel de méthode 'GET'. Encapsule l'appel axios et retourne directement la partie 'data'
   * en cas de réponse 20X */
  async get(url) {
    return this.http.get(url).then((result) => result.data);
  }

  /** Appel de méthode 'POST'. Encapsule l'appel axios et retourne directement la partie 'data'
   * en cas de réponse 20X */
  async post(url, data) {
    return this.http.post(url, data).then((result) => result.data);
  }

  /** Appel de méthode 'PUT'. Encapsule l'appel axios et retourne directement la partie 'data'
   * en cas de réponse 20X */
  async put(url, data) {
    return this.http.put(url, data).then((result) => result.data);
  }

  /** Appel de méthode 'PUT'. Encapsule l'appel axios et retourne directement la partie 'data'
   * en cas de réponse 20X */
  async delete(url, data) {
    return this.http.delete(url, data).then((result) => result.data);
  }

  /** Appel de méthode 'PATCH'. Encapsule l'appel axios et retourne directement la partie 'data' */
  async patch(url, data) {
    return this.http.patch(url, data).then((result) => result.data);
  }

  /**méthode de récupération du token. vide par défaut : à surcharger */
  getToken() {
    return "";
  }

  /**Hook de récupération du token APM */
  getApmToken() {
    return "";
  }

  /**définir l'intercepteur sur les requêtes pour y ajouter l'autorisation OAUTH ainsi que la clé d'API.*/
  async setInterceptor() {
    if (!this.http) {
      logger.error("le client HTTP n'est pas instancié.");
      return;
    }

    this.http.interceptors.request.use(
      (config) => {
        let tokenIP = this.getToken();

        // Ajoute le token IP dans Authorization pour les appels vers la DGApi
        if (tokenIP && this.apiType === ApiTypeEnum.DGApi) {
          config.headers["Authorization"] = "Bearer " + tokenIP;
        }
        // Ajoute le token IP dans identity_platform_token pour les appels vers l'acs
        if (tokenIP && this.apiType === ApiTypeEnum.Acs) {
          config.headers["identity_platform_token"] = "Bearer " + tokenIP;
        }

        // Ajoute l'api-key
        if (this.api_key) {
          config.headers["x-api-key"] = this.api_key;
        }

        // Ajoute le token ACS pour la gestion des droits
        let apmToken = this.getApmToken();
        if (apmToken) {
          config.headers["access_control_token"] = apmToken;
        }

        return config;
      },
      (error) => {
        logger.errorFull(
          _CLASS_NAME,
          "setInterceptor",
          "http interceptor request error : " + error
        );
        return Promise.reject(error);
      }
    );
  }
}
