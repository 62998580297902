var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { width: "12px", height: "12px", viewBox: "0 0 60 51" } },
    [
      _c("path", {
        attrs: {
          d: "M30.929 35.717a13.757 13.757 0 012.07-1.757V12.614c0-.837-.315-1.639-.878-2.23A2.928 2.928 0 0030 9.46h-3V3.153c0-.836-.316-1.638-.879-2.23A2.928 2.928 0 0024 0H9C8.204 0 7.44.332 6.879.924a3.237 3.237 0 00-.88 2.23V9.46H3c-.796 0-1.559.332-2.121.924A3.237 3.237 0 000 12.614v36.263c0 .419.158.82.44 1.115.28.296.662.462 1.06.462h27.029a8.004 8.004 0 01-1.53-4.652 14.73 14.73 0 011-5.458 14.168 14.168 0 012.93-4.627zm-17.43 4.095c0 .313-.118.614-.329.836a1.098 1.098 0 01-.795.346h-3.75c-.298 0-.585-.125-.796-.346a1.214 1.214 0 01-.33-.837V35.87c0-.314.12-.615.33-.836.211-.222.498-.347.796-.347h3.75c.298 0 .584.125.795.347.211.221.33.522.33.836v3.942zm0-12.614c0 .314-.118.614-.329.836a1.098 1.098 0 01-.795.347h-3.75c-.298 0-.585-.125-.796-.347a1.214 1.214 0 01-.33-.836v-3.942c0-.313.12-.614.33-.836.211-.222.498-.346.796-.346h3.75c.298 0 .584.124.795.346.211.222.33.523.33.836v3.942zm12 12.614c0 .313-.118.614-.329.836-.21.221-.497.346-.795.346h-3.75c-.299 0-.585-.125-.796-.346a1.214 1.214 0 01-.33-.837V35.87c0-.314.12-.615.33-.836.211-.222.497-.347.796-.347h3.75c.298 0 .584.125.795.347.211.221.33.522.33.836v3.942zm0-12.614c0 .314-.118.614-.329.836-.21.222-.497.347-.795.347h-3.75c-.299 0-.585-.125-.796-.347a1.214 1.214 0 01-.33-.836v-3.942c0-.313.12-.614.33-.836.211-.222.497-.346.796-.346h3.75c.298 0 .584.124.795.346.211.222.33.523.33.836v3.942z",
        },
      }),
      _c("ellipse", {
        attrs: { cx: "45", cy: "23.863", rx: "8.182", ry: "8.864" },
      }),
      _c("ellipse", { attrs: { cx: "45", cy: "42.954", rx: "15", ry: "7.5" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }