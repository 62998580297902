
import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/conf/2020-INFSI-026/indicators",
};


export default class ConfAvecIndicatorsApi extends TemplateApi {

  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }


}

