import CollaboratorsPractitionnersApi from '@/api/practitioners/collaborator_practitioners.js';
import MedicalExpertisesApi from "@/api/practitioners/medical_expertises_api.js";
import MedicalProfessionApi from "@/api/practitioners/medical_profession_api.js";
import MedicalSpecialtiesApi from "@/api/practitioners/medical_specialties_api.js";
import PractitionerApi from "@/api/practitioners/practitioner_api.js";
import PractionersHttpClient from "@/api/practitioners/practitioner_http_client.js";


/** Construction du client Http de l'api Practitioner */
export function initHttpClient(apiKey, tokenFunction, apmTokenFunction) {
  // on instancie les clients http
  let httpclient = new PractionersHttpClient();
  httpclient.getToken = tokenFunction;
  httpclient.getApmToken = apmTokenFunction;
  httpclient.api_key = apiKey;

  return httpclient;
}

/** Instantiation des apis du silo Practitioners */
export function initApi(httpClient) {
  return {
    practitioner: new PractitionerApi(httpClient),
    collaboratorPratitioners: new CollaboratorsPractitionnersApi(httpClient),
    medicalSpecialities: new MedicalSpecialtiesApi(httpClient),
    medicalExpertises: new MedicalExpertisesApi(httpClient),
    medicalProfession: new MedicalProfessionApi(httpClient),
  };
}