import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/avecproductestablishmentitems",
  PRODUCT_PRICES: "/productsprices",
};

export default class ProductPricesEstablishmentApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }

  /** Récupération de l'ensemble des tarifs pour les établisssment */
  async getAllProductPrices() {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.PRODUCT_PRICES
    )
    return this.httpclient.get(url);
  }

  /** Récupération de l'ensemble des tarif de produits pour un estab */
  async getAllProductPricesEstablishment(establishmentId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.SLACH
      + establishmentId.toString()
      + DEFINES.PRODUCT_PRICES
    )
    return this.httpclient.get(url);
  }

  /** Récupération d'un tarif de produits pour un estab */
  async getProductPricesEstablishment(establishmentId, productPriceId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.SLACH
      + establishmentId.toString()
      + DEFINES.PRODUCT_PRICES
      + DEFINES.SLACH
      + productPriceId.toString()
    )
    return this.httpclient.get(url);
  }

  /** Création d'un tarif de produit pour un établissement */
  async createProductPriceEstablishment(establishmentId, datas) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.SLACH
      + establishmentId.toString()
      + DEFINES.PRODUCT_PRICES
    )
    return this.httpclient.post(url, datas);
  }

  /** Mise à jour d'un tarif de produit pour un établissement */
  async updateProductPriceEstablishment(establishmentId, productPriceId, datas) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.SLACH
      + establishmentId.toString()
      + DEFINES.PRODUCT_PRICES
      + DEFINES.SLACH
      + productPriceId.toString()
    )
    return this.httpclient.put(url, datas);
  }

  /** Suppression d'un tarif de produit pour un établissement */
  async deleteProductPriceEstablishment(productPriceId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.SLACH
      + productPriceId.toString()
    )
    return this.httpclient.delete(url);
  }
}


