

import TemplateApi from "../template_api";



const DEFINES = {
  SLACH: "/",
  PATH: "/diseasereasons",
};

export default class DiseaseReasonsApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }
}
