import Vue from "vue";
import Vuetify from "vuetify/lib";
import IconNew from "@/assets/iconSvg/iconNew.vue";
import IconHome from "@/assets/iconSvg/iconHome.vue";
import IconEhpad from "@/assets/iconSvg/iconEhpad.vue";
import IconHospital from "@/assets/iconSvg/iconHospital.vue";
import IconVille from "@/assets/iconSvg/iconVille.vue";
import IconPractitioner from "@/assets/iconSvg/practitioners.vue";
import IconUser from "@/assets/iconSvg/iconUser.vue";
import IconArrowBack from "@/assets/iconSvg/iconArrowBack.vue";
import IconHomeUser from "@/assets/iconSvg/iconHomeUser.vue";
import IconConf from "@/assets/iconSvg/iconConf.vue";
import IconSecurity from "@/assets/iconSvg/iconSecurity.vue";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#E20080", // ROSE
        secondary: "#1E1D1D", // NOIR
        error: "#FF0000", // rouge
      },
      dark: false,
    },
  },
  icons: {
    values: {
      iconNew: {
        component: IconNew,
      },
      iconHome: {
        component: IconHome,
      },
      iconEhpad: {
        component: IconEhpad,
      },
      iconHospital: {
        component: IconHospital,
      },
      iconVille: {
        component: IconVille,
      },
      iconPractitioner: {
        component: IconPractitioner,
      },
      iconUser: {
        component: IconUser,
      },
      iconArrowBack: {
        component: IconArrowBack,
      },
      iconHomeUser: {
        component: IconHomeUser,
      },
      iconConf: {
        component: IconConf,
      },
      iconSecurity: {
        component: IconSecurity,
      },
    },
  },
});
