var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("iframe", {
      style: _vm.style,
      attrs: {
        id: _vm.id,
        src: _vm.src,
        sandbox: "allow-same-origin allow-scripts",
      },
      on: { load: _vm.load },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }