<template>
  <v-card tile flat>
    <v-navigation-drawer
      v-model="showBurgerMenu"
      :mini-variant="showBurgerMenu"
      permanent
    >
      <!-- v-if="isConnected" -->
      <v-list nav dense>
        <!-- Icon burger -->
        <v-list-item>
          <v-list-item-icon
            color="secondary"
            icon
            @click="showBurgerMenu = !showBurgerMenu"
          >
            <v-icon>mdi-menu</v-icon>
          </v-list-item-icon>

          <v-list-item-title class="text-h6 primary--text"
            >Database Globale</v-list-item-title
          >
        </v-list-item>

        <!-- Page d'accueil de l'ihm -->
        <v-list-item :to="routes.home" v-if="isConnected">
          <v-list-item-icon
            ><v-icon>$vuetify.icons.iconHomeUser</v-icon></v-list-item-icon
          >
          <v-list-item-title>Accueil</v-list-item-title>
        </v-list-item>

        <!-- Page de configuration des pour app utilisant la database globale -->
        <v-list-item :to="routes.confAppsHome" v-if="canViewConf">
          <v-list-item-icon
            ><v-icon>$vuetify.icons.iconConf</v-icon></v-list-item-icon
          >
          <v-list-item-title>Configuration des apps</v-list-item-title>
        </v-list-item>

        <!-- Page de configuration des rôles acs -->
        <v-list-item :to="routes.confAcs" v-if="canViewAcs">
          <v-list-item-icon
            ><v-icon>$vuetify.icons.iconSecurity</v-icon></v-list-item-icon
          >
          <v-list-item-title>ACS</v-list-item-title>
        </v-list-item>

        <!-- Page de configuration des praticiens -->
        <v-list-item :to="routes.confPractitioners" v-if="canViewPractitioners">
          <v-list-item-icon
            ><v-icon>$vuetify.icons.iconPractitioner</v-icon></v-list-item-icon
          >
          <v-list-item-title>Praticiens</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import { defines } from "@/router/defines.js";
import { hasOneOfRole } from "@/service/role_service.js";
import { RolesApplicationEnum } from "@/service/roles/roles_application.js";

export default {
  name: "BurgerMenuComponent",

  mixins: [],
  props: {
    connected: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      showBurgerMenu: false,
      // role de possibilité d'édition de la conf
      canViewConf: false,
      // role d'apparition du menu acs
      canViewAcs: false,
      // rôle d'apparition du menu des praticiens
      canViewPractitioners: false,
      routes: defines,
    };
  },
  methods: {
    /** Récupération des droits de l'utilisateur sur la possibilité de consulter la conf des apps */
    roleViewConf() {
      // est ce que l'utilisateur peut voir / modififer la conf
      this.canViewConf = hasOneOfRole([RolesApplicationEnum.ReadConfAppMenu]);
    },

    /** Détermine si l'utilisateur à le droit de consulter - modifier les droits acs */
    roleViewAcs() {
      this.canViewAcs = hasOneOfRole([
        RolesApplicationEnum.ReadAcs,
        RolesApplicationEnum.EditAcs,
      ]);
    },

    /** Détermine si l'utilisateur à le droit de consulter - modifier les données praticiens */
    roleViewPractitioners() {
      this.canViewPractitioners = hasOneOfRole([
        RolesApplicationEnum.ReadPracticioner,
        RolesApplicationEnum.EditPracticioner,
      ]);
    },
  },
  computed: {
    isConnected() {
      return this.connected;
    },
  },
  mounted() {},
  updated() {
    this.roleViewConf();
    this.roleViewAcs();
    this.roleViewPractitioners();
  },
  watch: {
    showBurgerMenu() {},
  },
};
</script>
