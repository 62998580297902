import axios from "axios";
import BaseApi from "../base_api";

/**
 * client http des appels api concernant les établissements.
 */
export default class EtablishmentHttpClient extends BaseApi {
  constructor(
    host = process.env.VUE_APP_URL_API_ETABLISHMENT,
    timeout = 10000
  ) {
    super();

    // process.env récupère les datas se trouvant dans le fichier .env."dev"
    this.host = host;
    this.rootUrl = "";
    this.http = axios.create({
      timeout,
    });

    // Configuration d'un écouteurs qui configure chaque requête avec le token dans le header
    this.setInterceptor();
  }
}
