var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "fontRedHat" },
    [
      _c("iframeSsoAuth"),
      _c(
        "v-app-bar",
        {
          staticStyle: {
            "border-bottom": "2px solid rgb(231, 236, 241) !important",
          },
          attrs: {
            app: "",
            color: "white",
            "scroll-threshold": "50000",
            "shrink-on-scroll": false,
            elevation: "0",
          },
        },
        [
          _c(
            "v-row",
            {
              staticStyle: { "flex-wrap": "nowrap" },
              attrs: { "no-gutters": "" },
            },
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "ml-5 mr-6 flex-grow-0 flex-shrink-0 justify-center",
                  attrs: { cols: "1" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex align-center",
                      staticStyle: { height: "100%" },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "d-flex align-center",
                          attrs: { href: _vm.onClickHomeButton() },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("./assets/login.png"),
                              height: "45",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass: "flex-grow-1 flex-shrink-1",
                  attrs: { cols: "9" },
                },
                [_c("MenuNavButtonComponent", { attrs: { app: "" } })],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "flex-grow-0 flex-shrink-0",
                  attrs: { cols: "2" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex align-center justify-end",
                      staticStyle: { height: "100%" },
                    },
                    [
                      _c("MenuProfilComponent", {
                        staticClass: "d-flex justify-self-end",
                        attrs: { connected: _vm.connected },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-main",
        [
          _c(
            "v-row",
            {
              staticClass: "d-flex flex-nowrap align-stretch fill-height",
              attrs: { "no-gutters": "" },
            },
            [
              _c("BurgerMenuComponent", {
                attrs: { connected: _vm.connected },
              }),
              _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    {
                      staticStyle: {
                        "border-bottom":
                          "2px solid rgb(231, 236, 241) !important",
                      },
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-start align-center" },
                        [
                          _c("div", { staticClass: "d-flex pa-2" }, [
                            _c(
                              "span",
                              {
                                staticClass: "text-h6",
                                staticStyle: {
                                  "font-family":
                                    "'Red hat text', 'sans-serif' !important",
                                },
                              },
                              [_vm._v(_vm._s(_vm.title))]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [_c("router-view")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-footer",
        { staticClass: "secondary", attrs: { padless: "" } },
        [
          _c(
            "v-row",
            {
              staticStyle: { "flex-wrap": "nowrap" },
              attrs: { "no-gutters": "" },
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "flex-grow-0 flex-shrink-0",
                  attrs: { cols: "2" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.mode,
                                            expression: "mode",
                                          },
                                        ],
                                        attrs: { text: "" },
                                      },
                                      on
                                    ),
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-lowercase white--text",
                                        },
                                        [_vm._v("apis")]
                                      ),
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "primary" } },
                                        [_vm._v("mdi-menu-up")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "v-list",
                            _vm._l(_vm.urlApis, function (url, index) {
                              return _c(
                                "v-list-item",
                                { key: index },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(url.label)),
                                  ]),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass: "flex-grow-1 flex-shrink-1",
                  attrs: { cols: "8" },
                },
                [
                  _vm.mode
                    ? _c(
                        "div",
                        {
                          staticClass: "d-flex justify-center align-center",
                          staticStyle: { height: "100%" },
                        },
                        [
                          _c("v-sheet", {
                            attrs: {
                              width: "18",
                              height: "18",
                              color: _vm.getColorMode(),
                            },
                          }),
                          _c(
                            "div",
                            {
                              class: _vm.getClassMode(),
                              staticStyle: { "font-size": "12px" },
                            },
                            [_vm._v(" " + _vm._s(_vm.getModeLabel()) + " ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-right flex-grow-0 flex-shrink-0",
                  attrs: { cols: "2" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mr-2 font-weight-light white--text d-flex align-center justify-end",
                      staticStyle: { height: "100%" },
                    },
                    [_vm._v(" version " + _vm._s(_vm.version) + " ")]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }