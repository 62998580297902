import TemplateApi from "../../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/conf/2020-INFSI-028/transmission/objects",
  SUBOBJECTS: "/subobjects",
  ACTIVITIES: "/activities",
};

export default class ObjectsApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }

  /**retourne une liste des sous-objets d'un objet en fonction de son identifiant. */
  async getSubobjects(objectId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH + DEFINES.SLACH + objectId.toString() + DEFINES.SUBOBJECTS
    );
    return this.httpclient.get(url);
  }

  /**retourne une liste des activités d'un objet en fonction de son identifiant. */
  async getActivities(objectId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH + DEFINES.SLACH + objectId.toString() + DEFINES.ACTIVITIES
    );
    return this.httpclient.get(url);
  }

  /**Ajouter une activité à un objet. */
  async addActivity(objectId, activityId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH + DEFINES.SLACH + objectId.toString() + DEFINES.ACTIVITIES
    );

    return this.httpclient.post(url, { activityId });
  }

  /**Retirer une activité d'un objet. */
  async removeActivity(objectId, activityId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
        DEFINES.SLACH +
        objectId.toString() +
        DEFINES.ACTIVITIES +
        DEFINES.SLACH +
        activityId.toString()
    );

    return this.httpclient.delete(url, {});
  }

  /**Ajouter un sous-objet à un objet. */
  async addSubobject(objectId, label) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH + DEFINES.SLACH + objectId.toString() + DEFINES.SUBOBJECTS
    );

    return this.httpclient.post(url, { label });
  }

  /**Retirer un sous-objet à un objet. */
  async removeSubobject(objectId, subobjectId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
        DEFINES.SLACH +
        objectId.toString() +
        DEFINES.SUBOBJECTS +
        DEFINES.SLACH +
        subobjectId.toString()
    );

    return this.httpclient.delete(url, {});
  }

  /**Mettre à jour un sous-objet (le label de ce dernier). */
  async updateSubobject(objectId, subobject) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
        DEFINES.SLACH +
        objectId.toString() +
        DEFINES.SUBOBJECTS +
        DEFINES.SLACH +
        subobject.id.toString()
    );

    return this.httpclient.put(url, subobject);
  }

  // ================================================================
  // ================================================================

  // async getActivitiesTest() {
  //   let url = this.httpclient.buildUrl(
  //     "/conf/2020-INFSI-028/transmission/activities"
  //   );
  //   return this.httpclient.get(url);
  // }

  // async getObjectsByActivitiesTest(activityId) {
  //   let url = this.httpclient.buildUrl(
  //     "/conf/2020-INFSI-028/transmission/activities/" +
  //       activityId.toString() +
  //       "/objects"
  //   );
  //   return this.httpclient.get(url);
  // }
  // async getSubObjectsByActivitiesTest(activityId, objectId) {
  //   let url = this.httpclient.buildUrl(
  //     "/conf/2020-INFSI-028/transmission/activities/" +
  //       activityId.toString() +
  //       "/objects/" +
  //       objectId.toString() +
  //       "/subobjects"
  //   );
  //   return this.httpclient.get(url);
  // }

  // async getMaxtrixForSubobjectTest(subobjectId, emergencylevelId) {
  //   let url = this.httpclient.buildUrl(
  //     "/conf/2020-INFSI-028/transmission/subobjects/" +
  //       subobjectId.toString() +
  //       "/emergencylevels/" +
  //       emergencylevelId.toString()
  //   );
  //   return this.httpclient.get(url);
  // }

  // /** récupération des feuilles */
  // async getTicketsApplicationsTest() {
  //   let url = this.httpclient.buildUrl(
  //     "/conf/2020-INFSI-028/ticket/applications"
  //   );
  //   return this.httpclient.get(url);
  // }

  // async getTicketsServicesForAppTest(applicationId) {
  //   let url = this.httpclient.buildUrl(
  //     "/conf/2020-INFSI-028/ticket/applications/" +
  //       applicationId.toString() +
  //       "/services"
  //   );
  //   return this.httpclient.get(url);
  // }

  // async getTicketsObjectsForAppTest(applicationId, serviceId) {
  //   let url = this.httpclient.buildUrl(
  //     "/conf/2020-INFSI-028/ticket/applications/" +
  //       applicationId.toString() +
  //       "/services/" +
  //       serviceId.toString() +
  //       "/objects"
  //   );
  //   return this.httpclient.get(url);
  // }

  // async getTicketsSubObjectsForAppTest(applicationId, serviceId, objectId) {
  //   let url = this.httpclient.buildUrl(
  //     "/conf/2020-INFSI-028/ticket/applications/" +
  //       applicationId.toString() +
  //       "/services/" +
  //       serviceId.toString() +
  //       "/objects/" +
  //       objectId.toString() +
  //       "/subobjects"
  //   );
  //   return this.httpclient.get(url);
  // }

  // async getTicketMaxtrixForSubobjectTest(subobjectId, emergencylevelId) {
  //   let url = this.httpclient.buildUrl(
  //     "/conf/2020-INFSI-028/ticket/subobjects/" +
  //       subobjectId.toString() +
  //       "/emergencylevels/" +
  //       emergencylevelId.toString()
  //   );
  //   return this.httpclient.get(url);
  // }
}
