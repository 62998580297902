var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "12px",
        height: "12px",
        viewBox: "0 0 15 15",
        fill: "#1e1d1d",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M14.4643 5.35714H9.64287V0.535714C9.64287 0.235714 9.40715 0 9.10715 0H5.89287C5.59287 0 5.35714 0.235714 5.35714 0.535714V5.35714H0.535714C0.235714 5.35714 0 5.59286 0 5.89286V9.10714C0 9.40714 0.235714 9.64286 0.535714 9.64286H5.35714V14.4643C5.35714 14.7643 5.59287 15 5.89287 15H9.10715C9.40715 15 9.64287 14.7643 9.64287 14.4643V9.64286H14.4643C14.7643 9.64286 15 9.40714 15 9.10714V5.89286C15 5.59286 14.7643 5.35714 14.4643 5.35714Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }