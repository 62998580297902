import axios from 'axios';
import BaseApi from "../base_api";


/**
 * Client http des appels api concernant les clients (patients)
 */
export default class CustomersHttpClient extends BaseApi {
  constructor(host = process.env.VUE_APP_URL_API_CUSTOMERS, timeout = 10000) {
    super();

    //process.env a récupéré l'url se trouvant dans le fichier env.'..'
    this.host = host;
    this.rootUrl = "";
    this.http = axios.create({
      timeout,
    });

    // Configuration d'un écouteurs qui ajoute à chaque requète les datas necessaires dans le header.
    this.setInterceptor();
  }
}