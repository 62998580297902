import { getUserRoles } from "../role_service";
import { RolesApmEnum, allRoles } from "./roles_apm.js";

// Liste des types de rôles
export let RoleTypes = {
  Reader: "Lecteur",
  Writter: "Editeur",
  Admin: "Administrateur",
};

// Liste des rôles apllications pris en compte
export let RolesApplicationEnum = {
  // Rôle pour la possibilité d'accès à la partie conf.
  AdminIhmTech: "Administrateur IHM",

  // Rôle pour l'accès au menu conf
  ReadConfAppMenu: "Lecteur menu configuration app",
  // Rôle pour l'accès au menu acs
  ReadAcsMenu: "Lecteur menu acs",

  // Rôles pour l'édition des entities de la conf
  EditConfEstablishmentAvec: "Editeur conf établissements Avec",
  EditConfSuperEstablishmentAvec: "Editeur conf super-établissements Avec",
  EditConfSubactivityAvec: "Editeur conf sous-activité Avec",
  EditConfAppContratTravail: "Editeur conf applications contrat de travail",
  ReadUserDroitApplicatif: "Lecteur des droits applicatif d'un utilisateur",

  EditServiceSFR: "Éditeur service SFR",
  ReadServiceSFR: "Lecteur service SFR",

  EditConf: "Éditeur service Configuration",
  ReadConf: "Lecteur service Configuration",

  EditDictionary: "Éditeur service Dictionnaire",
  ReadDictionary: "Lecteur service Dictionnaire",

  EditEstablishment: "Éditeur service Établissement",
  ReadEstablishment: "Lecteur service Établissement",

  EditJuridique: "Éditeur service juridique",
  ReadJuridique: "Lecteur service juridique",

  EditUser: "Éditeur service Collaborateur",
  ReadUser: "Lecteur service Collaborateur",

  // Rôles pour la table "Grands domaines ROME"
  EditRomeHightDomain: "Éditeur table Grands Domaines ROME",
  ReadRomeHightDomain: "Lecteur table Grands Domaines Rome",

  // Rôles pour la table "Ticketing > Objects sous-objects"
  EditTicketingObjectSubObject: "Éditeur table ticketing Object sous-object",
  ReadTicketingObjectSubObject: "Lecteur table ticketing Object sous-object",

  // Rôles pour la table "Ticketing > Statuts spécifiques"
  EditTicketingSpecificStatus: "Éditeur table ticketing statuts spécifique.",
  ReadTicketingSpecificStatus: "Lecteur table ticketing statuts spécifique.",

  // Rôles pour la table Etablissement.direction
  EditEstablishmentDirection: "Éditeur table établissement direction",
  ReadEstablishmentDirection: "Lecteur table établissement direction",
  AddDeleteEstablishmentDirection: "Ajout d'établissement service Direction",
  DetailEstablishmentDirection: "Lecteur du détails d'un établissement",

  // Rôles pour la table Etablissement.Juris
  EditEstablishmentJuris: "Éditeur table Etablissement Juris",
  ReadEstablishmentJuris: "Lecteur table Etablissement Juris",

  // Rôle pour la table Referent/Fonction
  EditEtablishmentReferentFunction:
    "Éditeur table Etablissement Référent-Fonction",
  ReadEtablishmentReferentFunction:
    "Lecteur table Etablissement Référent-Fonction",
  DetailEtablishmentReferentFunction:
    "Lecteur du détail d'un référent fonction",

  // Rôle pour la table tarifs des établissement - produit.
  EditPriceEstablishment: "Éditeur table tarifs des établissement",
  ReadPriceEstablishment: "Lecteur table tarifs des établissement",

  // Rôle pour le détails de la table Activités
  ReadActivities: "Lecteur Détails Activités",
  // Rôle pour l'édition d'une activité
  EditActivities: "Éditeur d'une activité",

  // Rôle pour l'ajout des applications
  EditApplications: "Éditeur d'application",
  // Rôle pour le détail d'une applications
  ReadApplications: "Lecteur d'une application",

  // Rôle pour le détails d'une structure juridique
  DetailLegalStructure: "Lecteur des détails d'une structure juridique",
  EditLegalStructure: "Éditeur d'une structure juridique",

  // Rôle pour la consultation des produits Avec
  ReadAvecProduct: "Lecteur table produits Avec",
  EditAvecProduct: "Éditeur table produits Avec",

  // Rôle pour la consultation des applications lanceur d'un établissement
  ReadConfEstablishmentBusinessApp:
    "Lecteur table de conf des applications par établissements",

  // Rôle pour le détails de données praticiens
  ReadPracticioner: "Lecteur tables pratitiens",
  EditPracticioner: "Editeur tables praticiens",

  // Rôle pour la gestion des produits
  ReadProduct: "Lecteur tables produits",
  EditProduct: "Editeur tables produits",
  AdminProduct: "Administrateur tables produits",

  // Rôle pour la gestion des faqs
  ReadFaqs: "Lecteur des faqs",
  EditFaqs: "Edition des faqs",

  // Rôle pour la gestion des templates des capacités
  ReadTemplateCapacities: "Lecteur des template de capacités",
  EditTemplateCapacities: "Edition des templates de capacités",

  // Rôle pour la gestion des capacités d'établissement
  ReadCapacitiesEstablishment: "Lecteur des capacités d'établissement",
  EditCapacitiesEstablishment: "Edition des capacités d'établissement",

  // Rôle pour l'accès à acs
  ReadAcs: "Lecteur des rôles acs",
  EditAcs: "Editeur des rôles acs",
  ReadUserAcs: "Lecteur des rôles acs des collaborateurs",
  EditUserAcs: "Editeur des rôles acs des collaborateurs",

  // Rôte pour la lecture des données consumers (relationship types/ecosystemes)
  ReadConsumer: "Lecteurs tables Consumers",
  AppConf: "Editeur configuration",

  EditTransmissionMatrix: "Editeur matrice de transmission",
};

/** Mapping entre roles acs et roles applicatifs */
export function build() {
  let roles = [];

  allRoles();

  //   Admin IHM
  roles.push({
    name: RolesApplicationEnum.AdminIhmTech,
    type: RoleTypes.Admin,
    expected: [],
  });

  // Lecteur rôle menu conf
  roles.push({
    name: RolesApplicationEnum.ReadConfAppMenu,
    type: RoleTypes.Reader,
    expected: [], // Au choix
    enabled: (rolesApmToken) => {
      if (
        rolesApmToken.includes(RolesApmEnum.USER_READ) ||
        rolesApmToken.includes(RolesApmEnum.CONF_ADMIN) ||
        rolesApmToken.includes(RolesApmEnum.ESTAB_READ) ||
        rolesApmToken.includes(RolesApmEnum.CONF_ADMIN) ||
        rolesApmToken.includes(RolesApmEnum.CONF_READ)
      ) {
        return true;
      } else {
        return false;
      }
    },
  });

  // Editeur des établissement visibles sur le site Avec
  roles.push({
    name: RolesApplicationEnum.EditConfEstablishmentAvec,
    type: RoleTypes.Writter,
    expected: [],
    enabled: (rolesApmToken) => {
      if (
        rolesApmToken.includes(RolesApmEnum.ESTAB_READ) &&
        rolesApmToken.includes(RolesApmEnum.CONF_ADMIN)
      ) {
        return true;
      } else {
        return false;
      }
    },
  });

  // Editeur des super établissement visibles sur le site Avec
  roles.push({
    name: RolesApplicationEnum.EditConfSuperEstablishmentAvec,
    type: RoleTypes.Writter,
    expected: [],
    enabled: (rolesApmToken) => {
      if (
        rolesApmToken.includes(RolesApmEnum.ESTAB_READ) &&
        rolesApmToken.includes(RolesApmEnum.CONF_ADMIN)
      ) {
        return true;
      } else {
        return false;
      }
    },
  });

  // Editeur des super établissement visibles sur le site Avec
  roles.push({
    name: RolesApplicationEnum.EditConfSuperEstablishmentAvec,
    type: RoleTypes.Writter,
    expected: [],
    enabled: (rolesApmToken) => {
      if (
        rolesApmToken.includes(RolesApmEnum.ESTAB_READ) &&
        rolesApmToken.includes(RolesApmEnum.CONF_ADMIN)
      ) {
        return true;
      } else {
        return false;
      }
    },
  });

  // Editeur des sous-activités visibles sur le site Avec
  roles.push({
    name: RolesApplicationEnum.EditConfSubactivityAvec,
    type: RoleTypes.Writter,
    expected: [],
    enabled: (rolesApmToken) => {
      if (
        rolesApmToken.includes(RolesApmEnum.ORG_READ) &&
        rolesApmToken.includes(RolesApmEnum.CONF_ADMIN)
      ) {
        return true;
      } else {
        return false;
      }
    },
  });

  // Editeur de conf pour l'application contrat de travail
  roles.push({
    name: RolesApplicationEnum.EditConfAppContratTravail,
    type: RoleTypes.Writter,
    expected: [],
    enabled: (rolesApmToken) => {
      if (
        rolesApmToken.includes(RolesApmEnum.USER_READ) &&
        rolesApmToken.includes(RolesApmEnum.CONF_ADMIN)
      ) {
        return true;
      } else {
        return false;
      }
    },
  });

  // Lecteur pour les droits applicatifs d'un collaborateur
  roles.push({
    name: RolesApplicationEnum.ReadUserDroitApplicatif,
    type: RoleTypes.Writter,
    expected: [],
    enabled: (rolesApmToken) => {
      if (
        rolesApmToken.includes(RolesApmEnum.USER_READ) &&
        rolesApmToken.includes(RolesApmEnum.ACS_ADMIN_READ)
      ) {
        return true;
      } else {
        return false;
      }
    },
  });

  //   Éditeur SFR
  roles.push({
    name: RolesApplicationEnum.EditServiceSFR,
    type: RoleTypes.Writter,
    expected: [RolesApmEnum.ORG_ADMIN],
  });
  //   Lecteur SFR
  roles.push({
    name: RolesApplicationEnum.ReadServiceSFR,
    type: RoleTypes.Reader,
    expected: [RolesApmEnum.ORG_READ],
  });

  //   Éditeur Établissement
  roles.push({
    name: RolesApplicationEnum.EditEstablishment,
    type: RoleTypes.Writter,
    expected: [RolesApmEnum.ESTAB_ADMIN],
  });

  //   Lecteur Établissement
  roles.push({
    name: RolesApplicationEnum.ReadEstablishment,
    type: RoleTypes.Reader,
    expected: [RolesApmEnum.ESTAB_READ],
  });

  //   Éditeur Configuration
  roles.push({
    name: RolesApplicationEnum.EditConf,
    type: RoleTypes.Writter,
    expected: [RolesApmEnum.CONF_ADMIN],
  });
  //   Lecteur Configuration
  roles.push({
    name: RolesApplicationEnum.ReadConf,
    type: RoleTypes.Reader,
    expected: [RolesApmEnum.CONF_READ],
  });

  //   Éditeur Dictionnaire
  roles.push({
    name: RolesApplicationEnum.EditDictionary,
    type: RoleTypes.Writter,
    expected: [RolesApmEnum.ENUM_ADMIN],
  });
  //   Lecteur Dictionnaire
  roles.push({
    name: RolesApplicationEnum.ReadDictionary,
    type: RoleTypes.Reader,
    expected: [RolesApmEnum.ENUM_READ],
  });

  // Editeur User
  roles.push({
    name: RolesApplicationEnum.EditUser,
    type: RoleTypes.Writter,
    expected: [RolesApmEnum.USER_ADMIN],
  });
  // Lecteur User
  roles.push({
    name: RolesApplicationEnum.ReadUser,
    type: RoleTypes.Reader,
    expected: [RolesApmEnum.USER_READ],
  });

  //  Editeur Grands domaine rome
  roles.push({
    name: RolesApplicationEnum.EditRomeHightDomain,
    type: RoleTypes.Writter,
    expected: [RolesApmEnum.ENUM_ADMIN, RolesApmEnum.ORG_ADMIN],
  });
  //  Lecteur Grand Domaine Rome
  roles.push({
    name: RolesApplicationEnum.ReadRomeHightDomain,
    type: RoleTypes.Reader,
    expected: [RolesApmEnum.ENUM_READ, RolesApmEnum.ORG_READ],
  });

  //  Editeur Ticketing Object sousobject
  roles.push({
    name: RolesApplicationEnum.EditTicketingObjectSubObject,
    type: RoleTypes.Writter,
    expected: [
      RolesApmEnum.CONF_ADMIN,
      RolesApmEnum.ENUM_READ,
      RolesApmEnum.ORG_READ,
    ],
  });
  //  Lecteur Ticketing Object sousObject
  roles.push({
    name: RolesApplicationEnum.ReadTicketingObjectSubObject,
    type: RoleTypes.Reader,
    expected: [
      RolesApmEnum.CONF_READ,
      RolesApmEnum.ENUM_READ,
      RolesApmEnum.ORG_READ,
    ],
  });

  // Editeur ticketing statuts spécifiques
  roles.push({
    name: RolesApplicationEnum.EditTicketingSpecificStatus,
    type: RoleTypes.Writter,
    expected: [RolesApmEnum.CONF_ADMIN, RolesApmEnum.ORG_READ],
  });
  // Lecteur ticketing statuts spécifiques
  roles.push({
    name: RolesApplicationEnum.ReadTicketingSpecificStatus,
    type: RoleTypes.Reader,
    expected: [RolesApmEnum.CONF_READ, RolesApmEnum.ORG_READ],
  });

  //  Editeur Etablissement direction
  roles.push({
    name: RolesApplicationEnum.EditEstablishmentDirection,
    type: RoleTypes.Writter,
    expected: [
      RolesApmEnum.ESTAB_EDIT,
      RolesApmEnum.ORG_READ,
      RolesApmEnum.JURIS_READ,
    ],
  });
  //  Lecteur Etablissement
  roles.push({
    name: RolesApplicationEnum.ReadEstablishmentDirection,
    type: RoleTypes.Reader,
    expected: [RolesApmEnum.ESTAB_READ, RolesApmEnum.ORG_READ],
  });
  // Ajout d'établissement Direction
  roles.push({
    name: RolesApplicationEnum.AddDeleteEstablishmentDirection,
    type: RoleTypes.Writter,
    expected: [RolesApmEnum.ESTAB_ADMIN, RolesApmEnum.JURIS_READ],
  });
  // Lecteur du détail établissement
  roles.push({
    name: RolesApplicationEnum.DetailEstablishmentDirection,
    type: RoleTypes.Reader,
    expected: [
      RolesApmEnum.ESTAB_READ,
      RolesApmEnum.ORG_READ,
      RolesApmEnum.JURIS_READ,
    ],
  });

  // Editeur Etablissement Juris
  // Contient la fonction enabled qui as pour rôle d'implémenter la définition spécifique du rôle
  roles.push({
    name: RolesApplicationEnum.EditEstablishmentJuris,
    type: RoleTypes.Writter,
    expected: [], // Au choix
    enabled: (rolesApmToken) => {
      if (
        (rolesApmToken.includes(RolesApmEnum.ESTAB_ADMIN) ||
          rolesApmToken.includes(RolesApmEnum.ESTAB_EDIT) ||
          rolesApmToken.includes(RolesApmEnum.JURIS_ADMIN)) &&
        rolesApmToken.includes(RolesApmEnum.ESTAB_READ) &&
        rolesApmToken.includes(RolesApmEnum.JURIS_READ)
      ) {
        return true;
      } else {
        return false;
      }
    },
  });
  // Lecteur Etablissement Juris
  roles.push({
    name: RolesApplicationEnum.ReadEstablishmentJuris,
    type: RoleTypes.Reader,
    expected: [RolesApmEnum.ESTAB_READ, RolesApmEnum.JURIS_READ],
  });

  // Editeur Etablissement référent fonction
  roles.push({
    name: RolesApplicationEnum.EditEtablishmentReferentFunction,
    type: RoleTypes.Writter,
    expected: [
      RolesApmEnum.ESTAB_EDIT,
      RolesApmEnum.ORG_READ,
      RolesApmEnum.USER_READ,
    ],
  });
  // Lecteur Etablissement référent fonction
  roles.push({
    name: RolesApplicationEnum.ReadEtablishmentReferentFunction,
    type: RoleTypes.Reader,
    expected: [RolesApmEnum.ESTAB_READ, RolesApmEnum.ORG_READ],
  });
  // Lecteur détail référent fonction
  roles.push({
    name: RolesApplicationEnum.DetailEtablishmentReferentFunction,
    type: RoleTypes.Reader,
    expected: [
      RolesApmEnum.ESTAB_READ,
      RolesApmEnum.ORG_READ,
      RolesApmEnum.USER_READ,
    ],
  });

  // Editeur tarifs établissement
  roles.push({
    name: RolesApplicationEnum.EditPriceEstablishment,
    type: RoleTypes.Writter,
    expected: [
      RolesApmEnum.ESTAB_EDIT,
      RolesApmEnum.CONF_READ,
      RolesApmEnum.PRODUCT_ADMIN,
    ],
  });
  // Lecteur tarifs établissement
  roles.push({
    name: RolesApplicationEnum.ReadPriceEstablishment,
    type: RoleTypes.Reader,
    expected: [
      RolesApmEnum.ESTAB_READ,
      RolesApmEnum.CONF_READ,
      RolesApmEnum.PRODUCT_READ,
    ],
  });

  // Editeur structure juridique
  roles.push({
    name: RolesApplicationEnum.EditJuridique,
    type: RoleTypes.Writter,
    expected: [RolesApmEnum.JURIS_ADMIN],
  });
  // Lecteur Structure juridique
  roles.push({
    name: RolesApplicationEnum.ReadJuridique,
    type: RoleTypes.Reader,
    expected: [RolesApmEnum.JURIS_READ],
  });

  // Lecteur Détails Activités
  roles.push({
    name: RolesApplicationEnum.ReadActivities,
    type: RoleTypes.Reader,
    expected: [
      RolesApmEnum.ORG_READ,
      RolesApmEnum.USER_READ,
      RolesApmEnum.CONF_READ,
    ],
  });
  // Editeur d'une activité
  roles.push({
    name: RolesApplicationEnum.EditActivities,
    type: RoleTypes.Writter,
    expected: [
      RolesApmEnum.ORG_ADMIN,
      RolesApmEnum.CONF_READ,
      RolesApmEnum.USER_READ,
      RolesApmEnum.ESTAB_READ,
    ],
  });

  // Ajouter une application
  roles.push({
    name: RolesApplicationEnum.EditApplications,
    type: RoleTypes.Writter,
    expected: [RolesApmEnum.ENUM_ADMIN, RolesApmEnum.ORG_READ],
  });
  // Lecteur d'une application
  roles.push({
    name: RolesApplicationEnum.ReadApplications,
    type: RoleTypes.Reader,
    expected: [RolesApmEnum.ENUM_READ, RolesApmEnum.ORG_READ],
  });

  // Détail structure juridique
  roles.push({
    name: RolesApplicationEnum.DetailLegalStructure,
    type: RoleTypes.Reader,
    expected: [
      RolesApmEnum.JURIS_READ,
      RolesApmEnum.USER_READ,
      RolesApmEnum.ESTAB_READ,
    ],
  });
  // Editeur d'une structure juridique
  roles.push({
    name: RolesApplicationEnum.EditLegalStructure,
    type: RoleTypes.Writter,
    expected: [
      RolesApmEnum.JURIS_ADMIN,
      RolesApmEnum.USER_READ,
      RolesApmEnum.ESTAB_READ,
    ],
  });

  // Lecteur table produits avec
  roles.push({
    name: RolesApplicationEnum.ReadAvecProduct,
    type: RoleTypes.Reader,
    expected: [
      RolesApmEnum.ESTAB_READ,
      RolesApmEnum.PRODUCT_READ,
      RolesApmEnum.CONF_READ,
      RolesApmEnum.ORG_READ,
      RolesApmEnum.PRACT_READ,
    ],
  });
  // Editeur table produits avec
  roles.push({
    name: RolesApplicationEnum.EditAvecProduct,
    type: RoleTypes.Writter,
    expected: [
      RolesApmEnum.ESTAB_ADMIN,
      RolesApmEnum.PRODUCT_ADMIN,
      RolesApmEnum.CONF_ADMIN,
      RolesApmEnum.ORG_READ,
      RolesApmEnum.PRACT_READ,
    ],
  });

  // Rôle pour la consultation des applications lanceur d'un établissement
  roles.push({
    name: RolesApplicationEnum.ReadConfEstablishmentBusinessApp,
    type: RoleTypes.Reader,
    expected: [RolesApmEnum.ESTAB_READ, RolesApmEnum.CONF_READ],
  });

  // Rôle pour la consultation de données praticiens
  roles.push({
    name: RolesApplicationEnum.ReadPracticioner,
    type: RoleTypes.Reader,
    expected: [RolesApmEnum.PRACT_READ],
  });
  // Rôle pour la consultation de données praticiens
  roles.push({
    name: RolesApplicationEnum.EditPracticioner,
    type: RoleTypes.Writter,
    expected: [RolesApmEnum.PRACT_ADMIN],
  });

  // Rôle pour la consultation des catégorie de produits
  roles.push({
    name: RolesApplicationEnum.ReadProduct,
    type: RoleTypes.Reader,
    expected: [RolesApmEnum.PRODUCT_READ],
  });
  // Rôle pour l'édition des catégories de produits
  roles.push({
    name: RolesApplicationEnum.EditProduct,
    type: RoleTypes.Writter,
    expected: [RolesApmEnum.PRODUCT_EDIT],
  });
  // Rôle pour l'administration des catégorie de produits
  roles.push({
    name: RolesApplicationEnum.AdminProduct,
    type: RoleTypes.Writter,
    expected: [RolesApmEnum.PRODUCT_ADMIN],
  });

  // Rôle pour la lecture des éléments des faqs
  roles.push({
    name: RolesApplicationEnum.ReadFaqs,
    type: RoleTypes.Reader,
    expected: [RolesApmEnum.CONF_READ, RolesApmEnum.ORG_READ],
  });
  // Rôle pour l'édition des éléments des faqs
  roles.push({
    name: RolesApplicationEnum.EditFaqs,
    type: RoleTypes.Writter,
    expected: [RolesApmEnum.CONF_ADMIN, RolesApmEnum.ORG_READ],
  });

  // Rôle pour la lecture des templates des capacités
  roles.push({
    name: RolesApplicationEnum.ReadTemplateCapacities,
    type: RoleTypes.Reader,
    expected: [RolesApmEnum.CONF_READ, RolesApmEnum.ORG_READ],
  });
  // Rôle pour l'édition des templates de capacités
  roles.push({
    name: RolesApplicationEnum.EditTemplateCapacities,
    type: RoleTypes.Writter,
    expected: [RolesApmEnum.CONF_ADMIN, RolesApmEnum.ORG_READ],
  });

  // Rôle pour la lecture des capacités d'établissement
  roles.push({
    name: RolesApplicationEnum.ReadCapacitiesEstablishment,
    type: RoleTypes.Reader,
    expected: [RolesApmEnum.CONF_READ, RolesApmEnum.ESTAB_READ],
  });
  // Rôle pour l'édition des capacités d'établissement
  roles.push({
    name: RolesApplicationEnum.EditCapacitiesEstablishment,
    type: RoleTypes.Writter,
    expected: [RolesApmEnum.CONF_READ, RolesApmEnum.ESTAB_EDIT],
  });

  // Lecteur des rôles ACS
  roles.push({
    name: RolesApplicationEnum.ReadAcs,
    type: RoleTypes.Reader,
    expected: [RolesApmEnum.ACS_ADMIN_READ],
  });
  // Editeur des rôles ACS
  roles.push({
    name: RolesApplicationEnum.EditAcs,
    type: RoleTypes.Writter,
    expected: [RolesApmEnum.ACS_ADMIN_EDIT],
  });
  // Lecteur rôle ACS collaborateur
  roles.push({
    name: RolesApplicationEnum.ReadUserAcs,
    type: RoleTypes.Reader,
    expected: [RolesApmEnum.ACS_ADMIN_READ, RolesApmEnum.USER_READ],
  });
  // Editeur rôle ACS collaborateur
  roles.push({
    name: RolesApplicationEnum.EditUserAcs,
    type: RoleTypes.Writter,
    expected: [RolesApmEnum.ACS_ADMIN_EDIT, RolesApmEnum.USER_READ],
  });
  // Lecteur données consumers
  roles.push({
    name: RolesApplicationEnum.ReadConsumer,
    type: RoleTypes.Reader,
    expected: [RolesApmEnum.CRM_READ],
  });
  // Editeur données consumers
  roles.push({
    name: RolesApplicationEnum.AppConf,
    type: RoleTypes.Writter,
    expected: [RolesApmEnum.APP_CONF],
  });

  // Editeur matrice de transmission
  roles.push({
    name: RolesApplicationEnum.EditTransmissionMatrix,
    type: RoleTypes.Writter,
    expected: [RolesApmEnum.CONF_ADMIN, RolesApmEnum.CRM_READ],
  });

  return roles;
}

/**
 * on passe une liste de rôles applicatifs et la méthode nous retourne 'oui/non'
 * @param {*} role le rôle applicatif à évaluer
 * @param {*} roles les définition des rôles applicatifs
 * @returns
 */
export function haveThisRole(role, roles) {
  //du genre on passe 'Éditeur service SFR'
  //recherche dans les rôles APM pour déterminer si  oui/non
  let target = roles.find((r) => r.name === role);
  if (!target) return false;

  let apm = getUserRoles();

  if (apm) console.log("TODO");
}
