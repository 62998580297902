import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/conf/2020-INFSI-028/ticket/ticketstatus",
  DEFAULTSTATUS: "/default",
};

export default class TicketingStatusApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }

  // GetAll --> 
  // GetByID -->
  // Create -->
  // Update -->
  // Delete -->


  /**
   * Récupère l'ensemble des status par défaut
   * @returns 
   */
  async getAllDefaultStatus() {
    let url =  this.httpclient.buildUrl(
      DEFINES.PATH
      + DEFINES.DEFAULTSTATUS
    );
    return this.httpclient.get(url);
  }


  
}
