var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "12px",
        height: "12px",
        viewBox: "0 0 47 52",
        fill: "#1e1d1d",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M35.983 24.493h-9.546c-1.322 0-2.204.715-2.204 2.144v24.85h14.688V27.668c0-1.747-1.322-3.176-2.938-3.176z",
        },
      }),
      _c("path", {
        attrs: {
          d: "M26.584 28.463h-.293c-.294 0-.588.317-.588.794v22.23h1.469v-22.23c0-.477-.294-.794-.588-.794zM30.256 28.463h-.294c-.293 0-.587.317-.587.794v22.23h1.469v-22.23c0-.477-.294-.794-.588-.794z",
          fill: "#fff",
        },
      }),
      _c("path", {
        attrs: {
          d: "M9.547 27.67h-6.61C1.322 27.67 0 29.097 0 30.844v20.642h9.547V27.669z",
        },
      }),
      _c("path", {
        attrs: {
          d: "M6.977 34.02c0 .397-.294.794-.735.794H4.04c-.44 0-.734-.397-.734-.794v-1.588c0-.476.293-.794.734-.794h2.203c.44 0 .735.318.735.794v1.588zM6.977 40.372c0 .397-.294.794-.735.794H4.04c-.44 0-.734-.397-.734-.794v-1.588c0-.477.293-.794.734-.794h2.203c.44 0 .735.317.735.794v1.588zM6.977 46.723c0 .397-.294.794-.735.794H4.04c-.44 0-.734-.397-.734-.794v-1.588c0-.476.293-.794.734-.794h2.203c.44 0 .735.318.735.794v1.588z",
          fill: "#fff",
        },
      }),
      _c("path", {
        attrs: {
          d: "M23.206 14.887L17.92 9.091c-.588-.635-1.543-.635-2.056 0l-5.288 5.796c-.587.635-1.028 1.826-1.028 2.779v33.82h14.687v-33.9c0-.873-.44-2.143-1.028-2.699z",
        },
      }),
      _c("path", {
        attrs: {
          d: "M22.031 27.669c0 .397-.367.794-.734.794h-8.813c-.367 0-.734-.397-.734-.794V26.08c0-.476.367-.794.734-.794h8.813c.367 0 .734.318.734.794v1.588zM22.031 34.02c0 .397-.367.794-.734.794h-8.813c-.367 0-.734-.397-.734-.794v-1.588c0-.476.367-.794.734-.794h8.813c.367 0 .734.318.734.794v1.588zM22.031 40.371c0 .397-.367.794-.734.794h-8.813c-.367 0-.734-.397-.734-.794v-1.587c0-.477.367-.794.734-.794h8.813c.367 0 .734.317.734.794v1.587zM22.031 46.723c0 .397-.367.794-.734.794h-8.813c-.367 0-.734-.397-.734-.794v-1.588c0-.476.367-.794.734-.794h8.813c.367 0 .734.318.734.794v1.588zM22.032 21.317c0 .398-.367.794-.734.794h-8.813c-.367 0-.734-.396-.734-.794V19.73c0-.477.367-.794.734-.794h8.813c.367 0 .734.317.734.794v1.587z",
          fill: "#fff",
        },
      }),
      _c("path", {
        attrs: {
          d: "M44.062 15.76v-3.97c0-.873-.66-1.587-1.468-1.587H40.39V1.47c0-.477-.294-.794-.735-.794-.44 0-.734.317-.734.794v8.733h-2.203c-.808 0-1.469.714-1.469 1.588v3.97c-1.616 0-2.938 1.428-2.938 3.175v32.55H47v-32.55c0-1.747-1.322-3.176-2.938-3.176z",
        },
      }),
      _c("path", {
        attrs: {
          d: "M38.555 20.524c0 .397-.367.794-.735.794h-2.203c-.367 0-.734-.397-.734-.794v-1.588c0-.476.367-.794.734-.794h2.203c.367 0 .735.318.735.794v1.588zM44.43 20.524c0 .397-.367.794-.734.794h-2.203c-.367 0-.734-.397-.734-.794v-1.588c0-.476.367-.794.734-.794h2.203c.367 0 .735.318.735.794v1.588zM38.555 26.081c0 .397-.367.794-.735.794h-2.203c-.367 0-.734-.397-.734-.794v-1.587c0-.477.367-.794.734-.794h2.203c.367 0 .735.317.735.794v1.587zM44.43 26.081c0 .397-.367.794-.734.794h-2.203c-.367 0-.734-.397-.734-.794v-1.587c0-.477.367-.794.734-.794h2.203c.367 0 .735.317.735.794v1.587zM38.555 31.639c0 .397-.367.794-.735.794h-2.203c-.367 0-.734-.397-.734-.794V30.05c0-.476.367-.794.734-.794h2.203c.367 0 .735.318.735.794v1.588zM44.43 31.639c0 .397-.367.794-.734.794h-2.203c-.367 0-.734-.397-.734-.794V30.05c0-.476.367-.794.734-.794h2.203c.367 0 .735.318.735.794v1.588zM38.555 37.196c0 .397-.367.794-.735.794h-2.203c-.367 0-.734-.397-.734-.794v-1.588c0-.476.367-.794.734-.794h2.203c.367 0 .735.318.735.794v1.588zM44.43 37.196c0 .397-.367.794-.734.794h-2.203c-.367 0-.734-.397-.734-.794v-1.588c0-.476.367-.794.734-.794h2.203c.367 0 .735.318.735.794v1.588zM38.555 42.754c0 .397-.367.794-.735.794h-2.203c-.367 0-.734-.398-.734-.794v-1.588c0-.477.367-.794.734-.794h2.203c.367 0 .735.317.735.794v1.588zM44.43 42.754c0 .397-.367.794-.734.794h-2.203c-.367 0-.734-.398-.734-.794v-1.588c0-.477.367-.794.734-.794h2.203c.367 0 .735.317.735.794v1.588zM38.555 48.311c0 .397-.367.794-.735.794h-2.203c-.367 0-.734-.397-.734-.794v-1.588c0-.476.367-.794.734-.794h2.203c.367 0 .735.318.735.794v1.588zM44.43 48.311c0 .397-.367.794-.734.794h-2.203c-.367 0-.734-.397-.734-.794v-1.588c0-.476.367-.794.734-.794h2.203c.367 0 .735.318.735.794v1.588z",
          fill: "#fff",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }