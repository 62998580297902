import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/establishments",
  FUNCTION_REFERENTS: "functionreferents",
};

export default class ReferentFunctionApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }

  /**Renvoie la liste des référents fonctions pour un établissement donné. Retourne un
   * tableau avec une entrée : une fonction + la liste des référents.  */
  async getReferentFunctionByEstablishment(establishmentId) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
        DEFINES.SLACH +
        establishmentId.toString() +
        DEFINES.SLACH +
        DEFINES.FUNCTION_REFERENTS
    );
    return this.httpclient.get(url);
  }

  /**Ajoute un référent sur une fonction digitale existante pour l'établissement.*/
  /**Mettre à jour l'entité */
  async updateEntity(establishmentId, entity) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
        DEFINES.SLACH +
        establishmentId.toString() +
        DEFINES.SLACH +
        DEFINES.FUNCTION_REFERENTS +
        DEFINES.SLACH +
        entity.id
    );

    return this.httpclient.put(url, entity);
  }

  async deleteEntity(establishmentId, entity) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
        DEFINES.SLACH +
        establishmentId.toString() +
        DEFINES.SLACH +
        DEFINES.FUNCTION_REFERENTS +
        DEFINES.SLACH +
        entity.id
    );

    return this.httpclient.delete(url);
  }

  async createEntity(establishmentId, entity) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
        DEFINES.SLACH +
        establishmentId.toString() +
        DEFINES.SLACH +
        DEFINES.FUNCTION_REFERENTS
    );

    return this.httpclient.post(url, entity);
  }
}
