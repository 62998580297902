const DEFINES = {
  SLACH: "/",
  CIVILITIES: "/civilities",
};

export default class CivilitiesApi {
  constructor(httpclient) {
    this.httpclient = httpclient;
  }

  /**retourne toutes les civilités */
  async getAll() {
    let url = this.httpclient.buildUrl(DEFINES.CIVILITIES);
    return this.httpclient.get(url);
  }

  /**retourne un élement */
  async getById(civilityId) {
    let url =
      this.httpclient.buildUrl(DEFINES.CIVILITIES) +
      "/" +
      civilityId.toString();
    return this.httpclient.get(url);
  }

  /**
   * Ajouter
   * */
  async create(civility) {
    let url = this.httpclient.buildUrl(DEFINES.CIVILITIES);
    return this.httpclient.post(url, civility);
  }

  /**
   * Mettre à jour
   * */
  async update(civility) {
    let url = this.httpclient.buildUrl(
      DEFINES.CIVILITIES + "/" + civility.id.toString()
    );
    return this.httpclient.put(url, civility);
  }

  /**
   * Supprimer
   * */
  async delete(civilityId) {
    let url = this.httpclient.buildUrl(
      DEFINES.CIVILITIES + "/" + civilityId.toString()
    );
    return this.httpclient.delete(url, {});
  }
}
