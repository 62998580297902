import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import Api from "@/api/api.js";

import * as VueGoogleMaps from 'vue2-google-maps';

import  { initializeApp } from 'firebase/app';



Vue.config.productionTip = false;

// Configuration de l'utilisation de la classe Api avec "$api" comme namespace.
Object.defineProperty(Vue.prototype, "$api", { value: new Api() });


// Configuration pour le sdk firebase
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,        
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
};
// Initialisation du sdk firebase
const firebase = initializeApp(firebaseConfig);
Vue.use(firebase);

// Configuration pour l'utilisation de la carte google
const ggMapOptions = {
  load: {
    key: process.env.VUE_APP_API_KEY_GOOGLE_MAP,
  },
};
Vue.use(VueGoogleMaps, ggMapOptions);



new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");




