import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: '/',
  PATH: '/collaborators',
  INCREMENTAL_SEARCH: "/incrementalsearch",
}

export default class CollaboratorApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);
    this.httpclient = httpclient;
  }

  /**Recherche d'utilisateurs en fonction de certains champs */
  async incrementalSearchCollaboratorByField(field, value, max = 25) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
        DEFINES.INCREMENTAL_SEARCH +
        "?field=" +
        field +
        "&value=" +
        value +
        "&maxrow=" +
        max
    );
    return this.httpclient.get(url);
  }

  /** Retourne un collaborateur demandé via son uuidAvec*/
  async getByUuid(uuid) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH +
      DEFINES.SLACH +
      uuid.toString());
    return this.httpclient.get(url);
  }
}