
import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/functions",
  RUBRICS: "/rubrics",
  ALLRUBRICS: "/allrubrics",
  GLOBALRUBRICS: "/globalrubrics",
};

export default class FunctionApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }

///////////////////////// GET //////////////////////

  /**Retourne la liste de toutes les rubriques spécifiques ET globales d'une fonction */
  async getAllRubricsOfFunction(idFunction) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH 
      + DEFINES.SLACH
      + idFunction.toString() 
      + DEFINES.ALLRUBRICS
    );
    return this.httpClient.get(url);
  }

  /**Retourne la liste des rubriques spécifiques d'une fonction */
  async getSpecificsRubricsOfFunction(idFunction) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH 
      + DEFINES.SLACH
      + idFunction.toString() 
      + DEFINES.RUBRICS
    );
    return this.httpClient.get(url);
  }

  /**Retourne la liste des rubriques globales d'une fonction */
  async getGlobalRubricsOfFunction(idFunction) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH 
      + DEFINES.SLACH
      + idFunction.toString() 
      + DEFINES.GLOBALRUBRICS
    );
    return this.httpClient.get(url);
  }

/////////////////////////////////////////////////////
///////////////////////// POST //////////////////////

  /** Ajout d'une rubrique spécifique à une fonction */
  async addSpecificRubricToFunction(idFunction, idRubric, entity) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH 
      + DEFINES.SLACH 
      + idFunction.toString() 
      + DEFINES.RUBRICS 
      + DEFINES.SLACH 
      + idRubric.toString()
    );
    return this.httpClient.post(url, entity);
  }

  /** Spécialisation d'une rubrique globale à une fonction */
  async addGlobalRubricToFunction(idFunction, idRubric, entity) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH 
      + DEFINES.SLACH 
      + idFunction.toString() 
      + DEFINES.GLOBALRUBRICS 
      + DEFINES.SLACH 
      + idRubric.toString()
    );
    return this.httpClient.post(url, entity);
  }

////////////////////////////////////////////////////
///////////////////////// PUT //////////////////////

  /** Mise à jour d'une rubrique spécifique à une fonction */
  async updateSpecificRubricToFunction(idFunction, idRubric, entity) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH 
      + DEFINES.SLACH 
      + idFunction.toString() 
      + DEFINES.RUBRICS 
      + DEFINES.SLACH 
      + idRubric.toString()
    );
    return this.httpClient.put(url, entity);
  }

  /** Mise à jour de la spécialisation d'une rubrique globale à une fonction */
  async updateGlobalRubricToFunction(idFunction, idRubric, entity) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH 
      + DEFINES.SLACH 
      + idFunction.toString() 
      + DEFINES.GLOBALRUBRICS 
      + DEFINES.SLACH 
      + idRubric.toString()
    );
    return this.httpClient.put(url, entity);
  }

/////////////////////////////////////////////////////
////////////////////// DELETE //////////////////////

  /** Suppression d'une rubriques spécifique à une fonction */
  async deleteSpecificRubricToFunction(idFunction, idRubric) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH 
      + DEFINES.SLACH 
      + idFunction.toString() 
      + DEFINES.RUBRICS 
      + DEFINES.SLACH 
      + idRubric.toString()
    );
    return this.httpClient.delete(url);
  }

  /** Suppression de la spécialisation d'une rubriques globale à une fonction */
  async deleteGlobalRubricToFunction(idFunction, idRubric) {
    let url = this.httpclient.buildUrl(
      DEFINES.PATH 
      + DEFINES.SLACH 
      + idFunction.toString() 
      + DEFINES.GLOBALRUBRICS 
      + DEFINES.SLACH 
      + idRubric.toString()
    );
    return this.httpClient.delete(url);
  }

}


